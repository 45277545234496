import React, { useState, useCallback } from 'react';
import "./sidebar.css";
import {
  BarChart
} from "@material-ui/icons";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import { Link  } from 'react-router-dom';

export default function Sidebar({ handleBoard }) {
  const [isOpen, setIsOpen] = useState(false); 
  const handleToggleSidebar = useCallback(() => {
    setIsOpen(prevState => !prevState); // toggle the state of the sidebar
    const sidebarWrapper = document.querySelector('.sidebarWrapper');
    sidebarWrapper.classList.toggle('collapsed', !isOpen); 
  }, [isOpen]);
  
  
  return (
    
    <div className={`sidebar ${isOpen ? '' : 'closed'}`}>
      <div className="sidebarWrapper">
        <div className="sidebarToggleBtn" onClick={handleToggleSidebar}>
            <div style={{display:'inline-flex'}}>{isOpen ? <ArrowForwardIosIcon /> : < ArrowBackIosIcon/>} 
             {isOpen ? <Typography>Open</Typography> : <Typography>Collapse</Typography>}
            </div>
          </div>
      {!isOpen && 
        <div className="sidebarMenu">
        
          <h3 className="sidebarTitle">MENU</h3>
          
          <ul className="sidebarList">
              <Link to="/equipment" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Equipment
              </Link>
              <Link to="/part" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Parts
              </Link>
              <Link to="/status" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Status
              </Link>
              <Link to="/stations" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Stations
              </Link>
              <Link to="/queue" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Queue
              </Link>
              <Link to="/yield" className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Yield
              </Link>
          </ul>
        
        </div>
      }
      </div>
    </div>
    
    
  );
}