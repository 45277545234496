import { createRoot } from "react-dom/client";

import ReplayIcon from "@mui/icons-material/Replay";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import CheckIcon from "@mui/icons-material/Check";
import ColumnOrder from "./ColumnOrder";
import ClickOutListener from "../clickOutListener/ClickOutListener";
import Button from "@mui/material/Button";
import { IconButton, Slider, TextField } from "@mui/material";
import { Refresh } from "@material-ui/icons";
import { GridCloseIcon } from "@mui/x-data-grid";

import "./CustomButtons.css";

// The class name for the div surrounding the header buttons
const buttonDivClassName =
	"MuiDataGrid-toolbarContainer";

// the class name for the buttons in the header
const buttonClassName =
	"MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-colorPrimary";

// the class name for the footer
const footerClassName =
	"MuiDataGrid-footerContainer";

/**
 * Adds the close button
 * @param {Function} close - the close function
 * @param {HTMLDivElement} tableDiv - the table div
 */
const addCloseButton = (close, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//Creates the new button
	const b = document.createElement("div");
	b.id = "closeButton" + tableDiv.id;
	b.type = "button";
	b.tabIndex = "0";

	//Creates the React Root and renders the button
	const root = createRoot(b);
	root.render(
		<IconButton
			aria-label="close"
			onClick={close}
			sx={{
				color: (theme) => "#ff0000",
			}}
		>
			<GridCloseIcon />
		</IconButton>
	);

	//puts the button at the end of the div
	div.appendChild(b);
};

/**
 * adds the reset button
 * @param {Function} reset - the reset function
 * @param {HTMLDivElement} tableDiv - the table div
 */
const addResetButton = (reset, tableDiv) => {
	//tale div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//creates the new button
	const b = document.createElement("div");
	b.id = "resetButton" + tableDiv.id;
	b.type = "button";
	b.tabIndex = "0";

	//Creates the React Root and renders the button
	const root = createRoot(b);
	root.render(
		<Button
			onClick={() => {
				reset();
				removeDataButtons(tableDiv);
			}}
		>
			<ReplayIcon style={{ marginRight: "5px" }} />
			<span>Restore</span>
		</Button>
	);

	//puts the button at the end of the div
	div.appendChild(b);
};

/**
 * adds the ordering button
 * @param {Array[Object]} cols - the columns
 * @param {Function} setCols - the function in the useState for columns
 * @param {Function} doUpdateGrid - the function to update the grid
 * @param {HTMLDivElement} tableDiv - the table div
 */
const addOrderButton = (cols, setCols, doUpdateGrid, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//creates the new button
	const b = document.createElement("div");
	b.id = "editColumnButton" + tableDiv.id;
	b.style.position = "relative";
	b.type = "button";
	b.tabIndex = "0";

	let isEditing = false;

	//Creates the React Root and renders the button
	const root = createRoot(b);

	/**
	 * a function to render the button, so it can be called within itself to update itself
	 */
	const renderButton = () => {
		root.render(
			<>
				<ClickOutListener
					onClickOut={() => {
						isEditing = false;
						renderButton();
					}}
				>
					<Button
						onClick={(e) => {
							isEditing = !isEditing;
							renderButton();
						}}
					>
						<ViewWeekIcon style={{ marginRight: "5px" }} />
						<span>Edit Order</span>
					</Button>
					{isEditing && (
						<ColumnOrder
							columns={cols}
							updateColumns={(newCols) => {
								setCols(newCols);
								doUpdateGrid(newCols);
							}}
							doUpdateGrid={doUpdateGrid}
						/>
					)}
				</ClickOutListener>
			</>
		);
	};

	renderButton();

	//Adds the button to the end of the button elements
	const divChildren = Array.from(div.children);
	divChildren.forEach((child) => div.removeChild(child));

	divChildren
		.filter((child) => child.tagName.toLowerCase() === "button")
		.forEach((child) => div.appendChild(child));

	div.appendChild(b);

	divChildren
		.filter((child) => child.tagName.toLowerCase() !== "button")
		.forEach((child) => div.appendChild(child));
};

/**
 * adds the save button
 * @param {Array[Object]} cols - the columns
 * @param {Function} writeSizeData - a function to write the size data
 * @param {HTMLDivElement} tableDiv - the table div
 */
const addSaveButton = (cols, writeSizeData, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//creates the new button
	const b = document.createElement("div");
	b.id = "saveButton" + tableDiv.id;
	b.type = "button";
	b.tabIndex = "0";

	//Creates the React Root and renders the button
	const root = createRoot(b);
	root.render(
		<Button
			onClick={() => {
				if (writeSizeData && typeof writeSizeData === "function") {
					writeSizeData(cols);
					removeDataButtons(tableDiv);
				}
			}}
		>
			<CheckIcon style={{ marginRight: "5px" }} />
			<span>Save</span>
		</Button>
	);

	//adds the button to the end of the div
	div.appendChild(b);
};

/**
 * adds the show copy icon switch
 * @param {Boolean} showLinks - whether to show the links or not
 * @param {Function} setShowLinks - the function to set the show links useState
 * @param {HTMLDivElement} tableDiv - the table div
 * @returns
 */
const addLinkToggleButton = (showLinks, setShowLinks, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//Creates the toggle button div
	const toggleDiv = document.createElement("label");
	toggleDiv.htmlFor = "linkToggleSlider" + tableDiv.id;
	toggleDiv.className = buttonClassName;
	toggleDiv.id = "linkToggle" + tableDiv.id;

	//creates the elements for the toggle button
	const label = document.createElement("label");
	const input = document.createElement("input");
	const span = document.createElement("span");

	//sets the class names for the toggle button items
	label.className = "showCopyIcon-switch";
	input.type = "checkbox";
	input.checked = showLinks ? true : false;
	input.className = "showCopyIcon-input";
	input.id = "linkToggleSlider" + tableDiv.id;
	span.className = "showCopyIcon-slider";

	// changes the switch styling based on if it's checked or not
	const checkChecked = () => {
		if (input.checked) {
			span.style.transform = "translateX(100%)";
			span.style.borderColor = "#2d5ca9";
			label.style.backgroundColor = "#2d5ca9";
			setShowLinks(true);
		} else {
			span.style.transform = "translateX(0)";
			span.style.borderColor = "rgba(100, 116, 139, 0.527)";
			label.style.backgroundColor = "rgba(100, 116, 139, 0.377)";
			setShowLinks(false);
		}
	};
	checkChecked();
	input.addEventListener("change", checkChecked);

	//appends the children to the label
	label.appendChild(input);
	label.appendChild(span);

	//creates the text for the show copy icon
	const textLabel = document.createElement("label");
	textLabel.textContent = "Show Copy Icon";
	textLabel.className = "showCopyIcon-text-label";
	textLabel.htmlFor = "linkToggleSlider" + tableDiv.id;

	//appends the label and text label to the toggle div
	toggleDiv.appendChild(label);
	toggleDiv.appendChild(textLabel);

	//adds the button to the end of the buttons in the div
	const divChildren = Array.from(div.children);
	divChildren.forEach((child) => div.removeChild(child));

	divChildren
		.filter((child) => child.tagName.toLowerCase() === "button")
		.forEach((child) => div.appendChild(child));

	div.appendChild(toggleDiv);

	divChildren
		.filter((child) => child.tagName.toLowerCase() !== "button")
		.forEach((child) => div.appendChild(child));
};

/**
 * adds the font size slider
 * @param {Number} fontSize
 * @param {Function} setFontSize
 * @param {HTMLDivElement} tableDiv
 */
const addSizingButtons = (fontSize, setFontSize, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(buttonDivClassName)[0];

	//stores the font size here so it doesn't cause the grid to infinite refresh
	let size = fontSize;

	//the limits of the font size
	const limits = [5, 25];

	//creates the outer div for the slider
	const fontSizeDiv = document.createElement("div");
	fontSizeDiv.id = "fontSizes" + tableDiv.id;
	fontSizeDiv.className = "fontSizeSlider-div";

	//creates the sub divs for each component
	const increase = document.createElement("div");
	const slider = document.createElement("div");
	const decrease = document.createElement("div");
	const textInput = document.createElement("div");

	/**
	 * the handle change for the slider and text input to update the font size
	 * @param {Event} event
	 */
	const handleChange = (event) => {
		let val = parseInt(event.target.value);

		size = Math.max(limits[0], Math.min(val, limits[1]));
		setFontSize(size);
		renderText();
		renderSlider();
	};

	//creates and renders the root for the increase icon
	const increaseRoot = createRoot(increase);
	increaseRoot.render(<TextIncreaseIcon style={{ scale: "90%" }} />);

	//creates and renders the root for the slider. uses a function so it can update itself
	const sliderRoot = createRoot(slider);
	const renderSlider = () => {
		sliderRoot.render(
			<Slider
				value={size}
				style={{ width: "150px" }}
				aria-label="Default"
				valueLabelDisplay="auto"
				min={limits[0]}
				max={limits[1]}
				onChange={(change, val) => {
					handleChange(change);
				}}
			/>
		);
	};

	renderSlider();

	//creates and renders the root for the decrease icon
	const decreaseRoot = createRoot(decrease);
	decreaseRoot.render(<TextDecreaseIcon style={{ scale: "90%" }} />);

	//creates and renders the root for the text input
	const textInputRoot = createRoot(textInput);
	const renderText = () => {
		textInputRoot.render(
			<TextField
				label="Size"
				variant="outlined"
				onChange={handleChange}
				value={size}
				size="small"
				inputProps={{ min: limits[0], max: limits[1], type: "number" }}
			/>
		);
	};

	renderText();

	//appends the separate components to the fontSizeDiv
	fontSizeDiv.appendChild(decrease);
	fontSizeDiv.appendChild(slider);
	fontSizeDiv.appendChild(increase);
	fontSizeDiv.appendChild(textInput);

	//adds the fontSizeDiv to the end of the buttons in the header div
	const divChildren = Array.from(div.children);
	divChildren.forEach((child) => div.removeChild(child));

	divChildren
		.filter((child) => child.tagName.toLowerCase() === "button")
		.forEach((child) => div.appendChild(child));

	div.appendChild(fontSizeDiv);

	divChildren
		.filter((child) => child.tagName.toLowerCase() !== "button")
		.forEach((child) => div.appendChild(child));
};

/**
 * adds a button to refresh data
 * @param {Function} fetchData - the fetch data function
 * @param {HTMLDivElement} tableDiv - the table div
 */
const addFetchDataButton = (fetchData, tableDiv) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//Gets the table header div
	const div = tableDiv.getElementsByClassName(footerClassName)[0];

	//creates the button div
	const button = document.createElement("div");
	button.id = "fetchDataButton" + tableDiv.id;
	button.style.marginLeft = "8px";

	//creates and renders the root for the button
	const root = createRoot(button);
	root.render(
		<Button
			startIcon={<Refresh />}
			onClick={fetchData}
		>
			Refresh Data
		</Button>
	);

	//adds the button to the footer
	const divChildren = Array.from(div.children);
	divChildren.forEach((child) => div.removeChild(child));

	div.appendChild(button);

	divChildren.forEach((child) => div.appendChild(child));
};

/**
 * adds the custom buttons to the table
 * @param {Array[Object]} cols - the columns
 * @param {Function} setCols - the columns
 * @param {Function} doUpdateGrid - the function to update the grid
 * @param {Boolean} showLinks - whether to show the copy button
 * @param {Function} setShowLinks - the function corresponding to showLinks in useState
 * @param {Number} fontSize - the font size
 * @param {Function} setFontSize - the function corresponding to fontSize in useState
 * @param {String} id - the id for the table
 * @param {Boolean} showRefreshDataButton - whether to show the refresh data button
 * @param {Function} close - a function for closing the table. if provided, the button will appear
 */
const addButtons = ({
	cols,
	setCols,
	doUpdateGrid,
	showLinks,
	setShowLinks,
	fontSize,
	setFontSize,
	fetchData,
	id,
	showRefreshDataButton,
	close,
}) => {
	//gets tableDiv
	const tableDiv = document.getElementById(id);

	//table div must exist
	if (!tableDiv) {
		return;
	}

	//removes the buttons to make room for the new ones (updating the grid doesn't remove the buttons, but the buttons need to be refreshed)
	removeButtonsFromID(tableDiv, [
		"saveButton" + id,
		"resetButton" + id,
		"editColumnButton" + id,
		"linkToggle" + id,
		"fontSizes" + id,
		"fetchDataButton" + id,
		"closeButton" + id,
	]);
	//adds the sizing buttons
	addSizingButtons(fontSize, setFontSize, tableDiv);

	//if the table could show the copy icon, create the button
	if (cols.some((col) => col.canShowLink))
		addLinkToggleButton(showLinks, setShowLinks, tableDiv);

	//add the order button
	addOrderButton(cols, setCols, doUpdateGrid, tableDiv);

	//adds the refresh data button if showRefreshDataButton
	if (showRefreshDataButton) addFetchDataButton(fetchData, tableDiv);

	//if the close function exists, add the close button
	if (close) addCloseButton(close, tableDiv);
};

/**
 * adds the data buttons and the close button
 * @param {Function} reset - the reset function
 * @param {Array[Object]} cols - the columns
 * @param {Function} writeSizeData - the function to write the size data
 * @param {String} id - the id
 * @param {Function} close - the close function
 */
const addDataButtons = ({ reset, cols, writeSizeData, id, close }) => {
	const tableDiv = document.getElementById(id);

	//table div must exist
	if (!tableDiv) {
		return;
	}

	//removes the data buttons to make room
	removeDataButtons(tableDiv);

	//adds the data buttons back
	addResetButton(reset, tableDiv);
	addSaveButton(cols, writeSizeData, tableDiv);

	//if the close function exists, create the close button
	if (close) addCloseButton(close, tableDiv);
};

/**
 * removes the data buttons from the table
 * @param {HTMLDivElement} tableDiv - the table div
 */
const removeDataButtons = (tableDiv) => {
	removeButtonsFromID(tableDiv, [
		"saveButton" + tableDiv.id,
		"resetButton" + tableDiv.id,
		"closeButton" + tableDiv.id,
	]);
};

/**
 * removes buttons from the tableDiv given their IDs
 * @param {HTMLDivElement} tableDiv - the table div
 * @param {Array[String]} ids - an array of IDs
 */
const removeButtonsFromID = (tableDiv, ids) => {
	//table div must exist
	if (!tableDiv) {
		return;
	}

	//loops through the ids and removes the button from it's parent
	for (let id of ids) {
		const button = tableDiv.querySelector(`#${id}`);
		if (button) {
			button.parentElement.removeChild(button);
		}
	}
};

const exp = {
	addOrderButton,
	addResetButton,
	addSaveButton,
	addButtons,
	addDataButtons,
};

export default exp;
