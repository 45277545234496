import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import { setComboOption } from "../../store/combobox/comboBoxSlice";
import { setComboOptionStatus } from "../../store/combobox/comboBoxStatusSlice";

import { useLocation, useNavigate } from 'react-router-dom';

export default function ComboBox({ label, data }) {

    const navigate = useNavigate()

    const useQuery = () => {
        return React.useMemo(() => {
            const params = new URLSearchParams(window.location.search);
            const queryObject = {};

            params.forEach((value, key) => {
                queryObject[key] = value;
            });

            return queryObject;
        }, [window.location.search]);
    };

    const updateQueryString = (key, value) => {
        const searchParams = new URLSearchParams(window.location.search);

        if (value === null) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }

        navigate({
            search: searchParams.toString(),
        }, { replace: true });
    };

    const [l, updateList] = useState([])
    const dispatch = useDispatch()
    const location = useLocation();

    const queryObject = useQuery();

    const lowerCaseLabel = label.toLowerCase()
    if (queryObject.hasOwnProperty(lowerCaseLabel)) {
        if (location.pathname === "/yield" && !queryObject[lowerCaseLabel].includes("svg")) {
            dispatch(setComboOption(queryObject[lowerCaseLabel]))
        } else if (location.pathname === "/status") {
            console.log(queryObject[lowerCaseLabel])
            dispatch(setComboOptionStatus(queryObject[lowerCaseLabel]))
        }
    }

    useEffect(() => {
        const queryString = window.location.search;
        if (queryString.includes("?part")) {
            console.log(queryObject)
            // setOption(result[1])
            dispatch(setComboOption(queryObject.part))

            // console.log('ssss' , result[1])
        }
    }, [])
    useEffect(() => {
        let tempList = data.payload
        if (label === "Station") {

            let result = tempList.map(obj => ({ label: obj.Name }));
            updateList(result)

        }
        else if (label === "Part") {
            // let tempList = data.payload
            // console.log('here' , tempList)

            let result = tempList.map(obj => ({ label: obj.Name }));
            updateList(result)
        }
        else if (label === "Part Number") {

            console.log("uuuu")

        }

        else {
            let result = tempList.map(obj => ({ label: obj.Name }));

            updateList(result)

        }
    }, [data.payload])

    return (
        <div>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={l}
                value={data.option}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={label} />}
                isOptionEqualToValue={(option, value) =>
                    option.label === value

                }
                onChange={(e) => {
                    if (e.target.dataset.testid === "CloseIcon") {
                        // setOption('')
                        console.log("location pathname", location.pathname)
                        if (location.pathname === "/yield") {
                            dispatch(setComboOption(''))

                        }
                        if (location.pathname === "/status") {
                            dispatch(setComboOptionStatus(''))

                        }
                    }
                    else {
                        console.log(e.target.innerHTML)
                        // setOption(e.target.innerHTML)
                        //    dispatch(setComboOption(e.target.innerHTML))
                        if (location.pathname === "/yield" && !e.target.innerHTML.includes("svg")) {
                            dispatch(setComboOption(e.target.innerHTML))
                            updateQueryString('part', e.target.innerHTML)
                        }
                        if (location.pathname === "/status") {
                            dispatch(setComboOptionStatus(e.target.innerHTML))
                            updateQueryString('part', e.target.innerHTML)

                        }
                    }
                }}
            />
        </div>
    )
}

