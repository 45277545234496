// src/SteppedLineChart.js
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { IconButton, LinearProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SteppedLineChart = ({ labels, datasets, useFractions, refreshData }) => {

  const [isHovering, setIsHovering] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const data = {
    labels: labels || [],
    datasets: datasets || []
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0, // Set the minimum value of the y-axis to 0
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            return `Date: ${tooltipItems[0].label}`;
          },
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw === -1 ? 0 : tooltipItem.raw;
            const total = tooltipItem.chart.data.datasets.filter((dataset) => dataset.label !== 'Stale').reduce((acc, dataset) => {
              return acc + ((dataset.data[tooltipItem.dataIndex] == -1 ? 0 : dataset.data[tooltipItem.dataIndex]) || 0);
            }, 0);
            return `${datasetLabel}: ${useFractions ? value : `${Math.round((value / total) * 100)}%`}`;
          }
        },
        position: 'average',
      },
      legend: {
        display: false,
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: false,
        text: 'Stepped Line Chart',
      },
    },

  };

  useEffect(() => {
    console.log(isRefreshing)
  }, [isRefreshing])

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <Line data={data} options={options} />
      <button onClick={async (e) => {
        e.stopPropagation();
        setIsRefreshing(true)
        try { await refreshData() } catch { } finally { setIsRefreshing(false) }
      }} style={{ top: 0, left: 0, position: 'absolute', background: 'white', border: 'none', padding: '4px', borderRadius: '999px', display: 'flex', cursor: 'pointer', opacity: isHovering ? '100%' : '0%', transition: 'ease-in-out 300ms all' }} >
        <RefreshIcon />
      </button>
      {isRefreshing && <LinearProgress sx={{top: 0, left: 0, width: '100%', height: '5px'}} />}
    </div>
  );
};

export default SteppedLineChart;
