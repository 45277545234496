import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

import { ParetoChart } from '../../components';

    function prepareParetoData(data) {
        // Calculate the total sum of all data points
        const total = data.reduce((sum, value) => sum + value, 0);
      
        // Calculate the cumulative percentage for each data point
        let cumPercentage = 0;
        const dataWithPercentage = data.map((value, index) => {
          cumPercentage += value / total * 100;
          return cumPercentage
        });
      
        return dataWithPercentage;
      }


export default function StationBoard() {
    const [data, setData] = React.useState([]);
    const [dropDownName, setDD] = React.useState("");
    const [useALl, setCursor] = React.useState(true);
    const [stationList, setStationList] = React.useState([]);
    const [part_count, setPartCount] = React.useState(0);
    const [test_count, setTestCount] = React.useState(0);
    useEffect(() => {
        fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/stationslist`)
          .then(response => response.json())
          .then(data => {
            setStationList(data);
          })
          .catch(error => {
            console.log(error);
          });
      }, []);

    const handleChange = (event) => {
 
    const value = event.target.value;
    fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/pareto`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json"
        },
        body: JSON.stringify({
        station: value,
        get_last : useALl
        })
    })
    .then(response => response.json())
    .then(data => {
        const name =  data['pareto'].map(obj => obj.test_name);
        const fail = data['pareto'].map(obj => obj.fail_count);
        const cumm = prepareParetoData(fail);
        const partc = data['part_count']
        const testc = data['test_count']
        setTestCount(testc)
        setPartCount(partc)
        
        setData({'name': name, 'fail' : fail, 'cumm': cumm})
        setDD(event.target.value)
        
    })
    .catch(error => {
        console.error("Error:", error);
    });
    };

    const handleCheckChange = (event) => {
      const value = event.target.checked;
      fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/pareto`, {
          method: "POST",
          headers: {
          "Content-Type": "application/json"
          },
          body: JSON.stringify({
          station: dropDownName,
          get_last : value
          
          })
      })
      .then(response => response.json())
      .then(data => {
          const name =  data['pareto'].map(obj => obj.test_name);
          const fail = data['pareto'].map(obj => obj.fail_count);
          const cumm = prepareParetoData(fail);
          const testc = data['test_count']
          setTestCount(testc)
          const partc = data['part_count']
          setPartCount(partc)
          setData({'name': name, 'fail' : fail, 'cumm': cumm})
          setCursor(value)
      })
      .catch(error => {
          console.error("Error:", error);
      });
      };

    return (
        <div>
            <ParetoChart datas= {data}/>

            <div>
            <FormControlLabel control={
            <Checkbox 
            checked={useALl} 
            onChange={handleCheckChange}
            defaultChecked />} label="Last Tests Only" />
            <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Stations</InputLabel>
                <Select
                labelid="demo-simple-select-label"
                id="demo-simple-select"
                value={dropDownName}
                label="Station"
                onChange={handleChange}
                >
                   {stationList.map((x) => 
                <MenuItem value={x.Name}> {x.Name} </MenuItem>

                   )}
                </Select>
            </FormControl>
            </Box>
            <br></br>
            <Typography variant="h5" component="h5">
              Number of Individual Parts: {part_count}
            </Typography>
            <Typography variant="h5" component="h5">
              Number of Tests: {test_count}
            </Typography>
            </div>
        </div>
    )

}