import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function OverrideModal({ open, handleClose, serial_number, sid,station_name,process_id, handleStatusUpdate }) {
    const [value, setValue] = React.useState('');
    const [pwd, setPass] = React.useState('');
    const [email, setEmail] = React.useState('');

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    const handlePassword = (event) => {
        setPass(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = () => {
        var url = `https://${process.env.REACT_APP_SERVER_ROUTE}/status/override`
        if (process_id !== null) {
            url = `https://${process.env.REACT_APP_SERVER_ROUTE}/status/override/${process_id}`
        }
        fetch(url, {
            method: "POST",
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify({
              value:value,
              email: email,
              password:pwd,
              serial_sid:sid,
              station_name:station_name
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            console.log("Submitted");
            handleClose(); // You can close the modal after submitting if desired
            handleStatusUpdate(true)

        })
        .catch(error => {
            console.error("Error:", error);

        });

    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h5" align="center">Overriding: {serial_number}</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="email"
                        type="email"
                        onChange={handleEmail}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Password"
                        type="password"
                        onChange={handlePassword}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Value</InputLabel>
                        <Select
                            value={value}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value={"PASS"}>PASS</MenuItem>
                            <MenuItem value={"FAIL"}>FAIL</MenuItem>
                            <MenuItem value={"ERIDANPASS"}>ERIDANPASS</MenuItem>
                            <MenuItem value={"INCOMPLETE"}>INCOMPLETE</MenuItem>
                        </Select>
                    </FormControl>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
