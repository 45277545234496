import React, { useState, useEffect } from "react";
import ReactApexCharts from "react-apexcharts";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';


function roundTo4SignificantFigures(number) {
    const sigFigs = 4;
  const scaleFactor = Math.pow(10, sigFigs - Math.floor(Math.log10(Math.abs(number))) - 1);
  const roundedNumber = Math.round(number * scaleFactor) / scaleFactor;
  return roundedNumber
}



export default function BoxPlot({ boxData, serialsData, stats_data }) {
  
  const [checkedLimits, setCheckedLimits] = useState({
    upperLimit: true,
    lowerLimit: true,
    eridanUpperLimit: true,
    eridanLowerLimit: true,
  });

  // ... useEffect declarations remain the same

  const handleCheckboxChange = (e) => {
    const updatedCheckedLimits = {
      ...checkedLimits,
      [e.target.name]: e.target.checked,
    };
  
    setCheckedLimits(updatedCheckedLimits);
  
    // Update the chart after changing the checked limits
    const annotations = createAnnotationsWithCheckedLimits(updatedCheckedLimits);
  
    setChart((prevChart) => {
      const newChartOptions = {
        ...prevChart.options,
        annotations: {
          yaxis: annotations,
        },
      };
  
      return {
        ...prevChart,
        options: newChartOptions,
      };
    });
  };
  
  

  const createAnnotationsWithCheckedLimits = (checkedLimits) => {
    const annotations = [];
    if (checkedLimits.upperLimit && stats_data[0].upperlimit && stats_data[0].upperlimit !== "MIXED LIMITS") {
      annotations.push(createAnnotation(stats_data[0].upperlimit, "red", "Upper Limit"));
    }
    if (checkedLimits.lowerLimit && stats_data[0].lowerlimit && stats_data[0].lowerlimit !== "MIXED LIMITS") {
      annotations.push(createAnnotation(stats_data[0].lowerlimit, "red", "Lower Limit"));
    }
    if (checkedLimits.eridanUpperLimit && stats_data[0].eridan_upperlimit && stats_data[0].eridan_upperlimit !== "MIXED LIMITS") {
      annotations.push(createAnnotation(stats_data[0].eridan_upperlimit, "orange", "Eridan Upper Limit"));
    }
    if (checkedLimits.eridanLowerLimit && stats_data[0].eridan_lowerlimit && stats_data[0].eridan_lowerlimit !== "MIXED LIMITS") {
      annotations.push(createAnnotation(stats_data[0].eridan_lowerlimit, "orange", "Eridan Lower Limit"));
    }
    return annotations;
  };
  
  const [step, setStep] = useState(10)
  const limitData = {

    max: !isNaN(parseFloat(stats_data[0].upperlimit)) && parseFloat(stats_data[0].upperlimit) > Math.max(...boxData)
      ? parseFloat(stats_data[0].upperlimit)
      : Math.max(...boxData),
    min: !isNaN(parseFloat(stats_data[0].lowerlimit)) && parseFloat(stats_data[0].lowerlimit) < Math.min(...boxData)
      ? parseFloat(stats_data[0].lowerlimit)
      : Math.min(...boxData),
      tickAmount:  step ? parseFloat(step)  : 10,
      labels: {
        formatter: function (value) {
          return roundTo4SignificantFigures(value);
        },
      },
  };
  const [initialMax, setInitialMax] = useState(limitData.max);
  const [initialMin, setInitialMin] = useState(limitData.min);
  
  const useLimits = true; 
  const yAxisData = useLimits ? limitData : withoutlimitData;

  const createAnnotation = (value, color, text) => {
    return {
      y: value,
      strokeDashArray: 0,
      borderColor: color,
      label: {
        position: "left",
        offsetX: 50,
        borderColor: color,
        style: {
          color: "#fff",
          background: color,
        },
        text: text,
      },
    };
  };

  const createAnnotations = (useLimits) => {
    
    const annotations = [];
    if (useLimits) {
      if (stats_data[0].upperlimit && stats_data[0].upperlimit !== "MIXED LIMITS") {
        annotations.push(createAnnotation(stats_data[0].upperlimit, "red", "Upper Limit"));
      }
      if (stats_data[0].lowerlimit && stats_data[0].lowerlimit !== "MIXED LIMITS") {
        annotations.push(createAnnotation(stats_data[0].lowerlimit, "red", "Lower Limit"));
      }
      if (stats_data[0].eridan_upperlimit && stats_data[0].eridan_upperlimit !== "MIXED LIMITS") {
        annotations.push(createAnnotation(stats_data[0].eridan_upperlimit, "orange", "Eridan Upper Limit"));
      }
      if (stats_data[0].eridan_lowerlimit && stats_data[0].eridan_lowerlimit !== "MIXED LIMITS")
       {
        annotations.push(createAnnotation(stats_data[0].eridan_lowerlimit, "orange", "Eridan Lower Limit"));
      }
    }
    return annotations;
  };
  
  const annotations = createAnnotationsWithCheckedLimits(checkedLimits);
  
  


  console.log(stats_data)
  const quantile = (arr, q) => {
    const sorted = arr.sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  };
  const [withLimits, setWithLimits] = useState(true);

  useEffect(() => {
    setChart((prevChart) => ({
        ...prevChart,
        series: [
          {
            type: "boxPlot",
            data: [
              {
                x: "Analytics",
                y: [
                  Math.min(...boxData),
                  quantile(boxData, 0.25),
                  quantile(boxData, 0.5),
                  quantile(boxData, 0.75),
                  Math.max(...boxData),
                ],
              },
            ],
          },
        ],
    }));
  }, [boxData]);

  useEffect(() => {
    setChart((prevChart) => ({
        ...prevChart,
        series: [
          {
            type: "boxPlot",
            data: [
              {
                x: "Analytics",
                y: [
                  Math.min(...boxData),
                  quantile(boxData, 0.25),
                  quantile(boxData, 0.5),
                  quantile(boxData, 0.75),
                  Math.max(...boxData),
                ],
              },
            ],
          },
        ],
    }));
  }, [boxData, stats_data]);
  
  const withoutlimitData = {
    max: Math.max(...boxData),
    min: Math.min(...boxData),
    
    labels: {
      formatter: function (value) {
        return roundTo4SignificantFigures(value);
      },
    },
  };
  const [tempLimitData, setLimitData] = useState(limitData)


  const getChartConfig = (yAxisData, annotations) => ({
    chart: {
      type: "boxPlot",
      height: 400,
      width: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    yaxis: yAxisData,
    annotations: {
      yaxis: annotations,
    },
    title: {
      text: "Session Report ",
      align: "left",
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: "grey",
          lower: "dark grey",
        },
      },
    },
  });



  const [chart, setChart] = useState({
    series: [
      {
        type: "boxPlot",
        data: [
          {
            x: "Analytics",
            y: [Math.min(...boxData), quantile(boxData, 0.25), quantile(boxData, 0.5), quantile(boxData, 0.75), Math.max(...boxData)],
          },
        ],
      },
    ],
    options: {
      ...getChartConfig(yAxisData, createAnnotationsWithCheckedLimits(checkedLimits)),
    },
  });
  const handleChart = (e) => {
    const yAxisData = {
      max: parseFloat(initialMax),
      min: parseFloat(initialMin),
      tickAmount: parseFloat(step),
      labels: {
        formatter: function (value) {
          return roundTo4SignificantFigures(value);
        },
      },
    };
  
    const annotations = createAnnotationsWithCheckedLimits(checkedLimits);
  
    setChart((prevChart) => ({
      ...prevChart,
      options: {
        ...prevChart.options,
        yaxis: yAxisData,
        annotations: {
          yaxis: annotations,
        },
      },
    }));
  };
  
  const handleChange = (e) => {
    const useLimits = e.target.checked;
    setWithLimits(useLimits);
    let yAxisData;
  
    if (useLimits) {
      yAxisData = {
        max: parseFloat(initialMax),
    min: parseFloat(initialMin),
        tickAmount: parseFloat(step),
        labels: {
          formatter: function (value) {
            return roundTo4SignificantFigures(value);
          },
        },
      };
    } else {
      yAxisData = {
        max: parseFloat(initialMax),
    min: parseFloat(initialMin),
        tickAmount: parseFloat(step),
        labels: {
          formatter: function (value) {
            return roundTo4SignificantFigures(value);
          },
        },
      };
    }
  
    const annotations = createAnnotationsWithCheckedLimits(checkedLimits);
  
    setChart({
      series: [
        {
          type: "boxPlot",
          data: [
            {
              x: "Analytics",
              y: [
                Math.min(...boxData),
                quantile(boxData, 0.25),
                quantile(boxData, 0.5),
                quantile(boxData, 0.75),
                Math.max(...boxData),
              ],
            },
          ],
        },
      ],
      options: {
        ...getChartConfig(yAxisData, annotations),
      },
    });
  };
  
  
  
  
    return (
      <div>
        <div id="chart">
          <h2>Test Boxplot</h2>
          <ReactApexCharts
            options={chart.options}
            series={chart.series}
            type="boxPlot"
            height={500}
            width={400}
          />
        </div>
        {stats_data[0].lowerlimit !== "MIXED LIMITS" && (
          <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedLimits.upperLimit}
                onChange={handleCheckboxChange}
                name="upperLimit"
              />
            }
            label="Upper Limit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedLimits.lowerLimit}
                onChange={handleCheckboxChange}
                name="lowerLimit"
              />
            }
            label="Lower Limit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedLimits.eridanUpperLimit}
                onChange={handleCheckboxChange}
                name="eridanUpperLimit"
              />
            }
            label="Eridan Upper Limit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedLimits.eridanLowerLimit}
                onChange={handleCheckboxChange}
                name="eridanLowerLimit"
                />
                }
                label="Eridan Lower Limit"
                />
                </FormGroup>
        )}
        <Stack direction="row" spacing={2}>
        <TextField id="outlined-basic" label="Step" variant="outlined"  defaultValue={step} onChange={(v)=>{setStep(v.target.value)}} />
        <TextField id="outlined-basic" label="Graph Max" variant="outlined" defaultValue={initialMax} onChange={(v)=>{setInitialMax(v.target.value)}} />
        <TextField id="outlined-basic" label="Graph Min" variant="outlined" defaultValue={initialMin}onChange={(v)=>{setInitialMin(v.target.value)}} />
        <Button variant="outlined" onClick={handleChart}> <SendIcon/></Button>

      </Stack>

      </div>
    );
  }
  
