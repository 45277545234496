import { loginSuccess, loginFailure, logout } from '../store/auth/authStateSlice';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const getHeaders = () =>{

    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',


    };
}

class ApiMethods {
    
    static apiRequest(method, url, body = {}) {
        console.log("Fetch")
        console.log("[Fetching]",url )
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: method,
                credentials: 'include',
            };
            if (!(body instanceof FormData)) {
                requestOptions.headers = getHeaders();
            }
    
            if (method === 'POST' || method === 'PUT') {
                if (body instanceof FormData) {
                    requestOptions.body = body;
                } else {
                    requestOptions.body = JSON.stringify(body);
                }
                // requestOptions.body = JSON.stringify(body);
            }

            fetch(url, requestOptions)
                .then(response => {
                    console.log("RRRRR" , response)
                        resolve(response);
                })
                .catch(error => {
                    console.log("error here" , error)
                    // reject({ message: "Network or unknown error", error: error });
                    resolve({ message: "Network or unknown error", error: error });

                });
        });
    }

    static get(url) {

        return this.apiRequest('GET', url);
    }

    static post(url, data) {
        console.log("posting request data" , data)
        return this.apiRequest('POST', url, data);
    }
    

    static put(url,data) {
        return this.apiRequest('PUT', url,data);
    }

}

export default ApiMethods;