import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { ComboBox} from '../../components'
import { useSelector, useDispatch } from 'react-redux';
import { initializeComboBoxData,setComboOption } from "../../store/combobox/comboBoxSlice";
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
function EcoBoard() {
  const dispatch = useDispatch()
  const data = useSelector(state => state.combobox.value)

  // const [selectedEco, setSelectedEco] = React.useState(null);
  const [ecoRows, setecoRows] = React.useState([]);
  const [revRows, setrevRows] = React.useState([]);
  // const [partList, setPartList] = React.useState([]);
  // const [options, setOption] = React.useState('');
  const handleApiError = ApiError()
  const handeClickRow = async () => {
    const d = await ApiManager.eco_getEcoDetailByPartNum(data.option)
    const data = await handleApiError(d)
    setrevRows(data)

  }
    
  const handleCheckChange = async () => {

    const d = await ApiManager.eco_getEcoDetail()
    const data = await handleApiError(d)
    setecoRows(data)
    console.log(ecoRows)
    };
    React.useEffect(() => {
        ( async () =>{
          const d = await ApiManager.eco_getPartList()
          const data = await handleApiError()
          if ('error'  in data){return}
          // setPartList(data);
          dispatch(initializeComboBoxData(data))
          console.log("data")

          console.log(data)
        })();
    }, []);

    React.useEffect(() => {
        handleCheckChange();
    
      }, []);

      React.useEffect(() => {

        ( async () =>{
          const d = await ApiManager.eco_getPartList()
          const data = await handleApiError()
          if ('error'  in data){return}
          // setPartList(data);
          dispatch(initializeComboBoxData(data))
          console.log("data")

          console.log(data)
        })();
        
    }, []);



    React.useEffect( ()=>{
    
        if (data.option.includes("<svg") || data.option === '') {
          // setOption("")
          dispatch(setComboOption)

          return
        }
        handeClickRow()


        

      }, [data.option])
  const revColumns = [
    { field: 'version', headerName: 'Rev', width: 250 },
    { field: 'creation_date', headerName: 'Date Created', width: 250 },
    { field: 'description', headerName: 'Description', width: 400 },
    {
        field: 'link',
        headerName: 'Link',
        width: 250,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.value, '_blank')} // This will open the link in a new tab
          >
            Open Link
          </Button>
        ),
      },
  ];
  return (
    <div style={{ width: '100%'  }}>
    
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16, marginTop: 25 }}>
  <ComboBox  label={"Part"}  fill={[]} />
</div>
      {revRows && (
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button variant="contained" color="secondary">
              Add Rev
            </Button>
          </div>
          <div style={{ height: 400 }}>
            <DataGrid rows={revRows} columns={revColumns} pageSize={5} />
          </div>
        </div>
      )}
    </div>
  );
}
export default EcoBoard;