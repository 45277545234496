import { Star } from '@material-ui/icons';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

/**
 * A simple grouped drop down with state changes
 * 
 * @param {Array} options should be in this format: ["Option 1", "Option 2", "Option 3"]
 * @returns React Component
 */
const DropDownSelector = ({ options, title, sx, selection, setSelection, starredItems }) => {
    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120, ...sx }}>
            <InputLabel htmlFor={title}>{title}</InputLabel>
            <Select
                value={selection || undefined}
                onChange={handleChange}
                id={title}
                label={title}
                key={selection}
                renderValue={() => {return selection}}
            >
                {(options).map((option, index) => (
                    <MenuItem key={index} value={option} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{option}</span>
                        {starredItems && starredItems.length > 0 && starredItems.includes(option) && <Star />}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DropDownSelector;
