import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,Title ,  registerables } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend,Title , ...registerables);


export default function PieChart({summary}) {
    const data = {
        labels: [
          'Pass',
          'Retest',

          'Failed'
        ],
        datasets: [{
          data: summary,
          backgroundColor: [
            '#07fc03',
            '#39A2EB',
          '#FF6384'          ],
          hoverBackgroundColor: [
            '#07fc03', 
            '#39A2EB',

          '#FF6384'
          ]
        }]
      };
      const plugins =  {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets;
            if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              let sum = datasets[0].data.reduce((a, b) => a + b, 0);
               let percentage = Math.round((value / sum) * 100) + '%';
              return percentage;
            } 
          },
          color: '#fff',
        }
      }
 

  return (<Pie data={data}    plugins={plugins}     />  )

}
