import React, { useEffect, useState } from 'react';

import CustomButtons from './CustomButtons'
import ColumnResizing from './ColumnResizing';
import LocalStorageManager from './LocalStorageManager';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, CircularProgress, LinearProgress, Stack, Tooltip, Typography, Zoom, styled, tooltipClasses } from '@mui/material';
import { useRef } from 'react';
import { DataGrid, GridToolbar, useGridApiContext } from '@mui/x-data-grid';

/**
*	A MUI-X table with column resizing, reordering, copy on cell click, saving states, font size changing and copy icons
*
*	@param {Array[Object]} columns - the column style
*	@param {Function} fetchData - fetches the data {result: [], size: number}
*	@param {String} id - the id for the table (String). Used for zeroing the HTML for the buttons and resizing columns
*	(optional) @param {String} location - the location to store the local data. if not provided, it will use "TempTableData" and remove it on unmount or page change (whenever the component isn't visible)
*	(optional) @param {*} doFetchIndex - for calling fetch outside of this component, create a useState and update it. It uses a useEffect on this state to call
*	(optional) @param {Object} externalQuerySettings - for adding external queries to the fetch (ex: start_time, end_time, etc)
*	(optional) @param {Function} onCellClick - for adding external functionality to clicking cells. overrides the defualt copy functionality
*	(optional) @param {Function} onRowClick - for adding external functionality to clicking a row
*	(optional) @param {Object} sx - for adding external style changes
*	(optional) @param {Boolean} showRefreshDataButton - a toggle for whether to show a refresh button
*	(optional) @param {Function} closeButton - a function that when supplied creates a close button who's onclick is this function.
*	(optional) @param {Function} handleCellEditCommit - a function that gets called when a cell is finished being edited
*
*	To use "Show Copy Icon" switches, make sure at least one column has the property: 'canShowLink', that is set to true. otherwise, it would have no effect
*/
const Table = ({ columns, fetchData, location, doFetchIndex, externalQuerySettings, onCellClick, onRowClick, sx, id, showRefreshDataButton, closeButton, frontEndFilter, applyFrontEndFilter, setApplyFrontEndFilter, paginationMode, loading, selectionModel, onSelectionModelChange, getRowClassName, checkboxSelection, componentsProps, handleCellEditCommit }) => {

	//if location isn't provided, it's using temp data
	if (!location) {
		location = "TempTableData" + id
	}

	const useQuery = () => {
		return React.useMemo(() => {
			const params = new URLSearchParams(window.location.search);
			const queryObject = {};

			params.forEach((value, key) => {
				queryObject[key] = value;
			});

			return queryObject;
		}, [window.location.search]);
	};

	const queryObject = useQuery();

	//get the stored data
	const storedData = LocalStorageManager.getData({ location });

	/**
	 * goes through the local storage, takes what columns it needs, adds columns it doesn't have and then sets the local storage data.
	 * @returns the array of columns
	 */
	const getStoredColumnData = () => {
		const arr = storedData?.data?.columns?.map((col) => (
			columns.map(c => c.field === col.field ? { ...c, flex: col.flex } : c)
				.find(c => c.field === col.field)
		)).filter((col) => col).concat(columns.filter((col) => !storedData?.data?.columns?.some((c) => c.field === col.field)));
		LocalStorageManager.writeData({ location, columns: arr })
		return arr;
	}

	//useStates for the grid and data
	const [isLoading, setIsLoading] = useState(false)
	const [rows, setRows] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [gridUpdateCounter, setGridUpdateCounter] = useState(0);

	//useStates dependent on the stored data for this current table
	const [cols, setCols] = useState((storedData?.data?.columns) ? getStoredColumnData : columns);
	const [density, setDensity] = useState(storedData.result && storedData.data && storedData.data.density ? storedData.data.density : "standard");
	const [visibilityModel, setVisibilityModel] = useState((storedData.result && storedData?.data?.visibility) || (() => columns.reduce((obj, ele) => ({ ...obj, [ele.field]: true }), {})));
	const [sort, setSort] = useState((storedData.result && storedData?.data?.sort) ? storedData.data.sort : []);
	const [filterModel, setFilterModel] = useState(() => {
		if (queryObject) {
			const sameKeys = Object.keys(queryObject).filter((key) => cols.some((col) => col.field === key))
			console.log("SameKeys", sameKeys)
			if (sameKeys.length > 0) {

				const queryFilterKey = sameKeys[0]
				const v = {
					items: [
						{ columnField: queryFilterKey, operatorValue: 'equals', id: 0, value: queryObject[queryFilterKey] }
					]
				}
				LocalStorageManager.writeData({ location, filters: v })
				return v
			}
		}

		return JSON.parse(localStorage.getItem("filters"))?.items ? JSON.parse(localStorage.getItem("filters")) : { items: [] }
	});
	const [pageSize, setPageSize] = useState(storedData?.data?.pageSize || 50)
	const [prevPageSize, setPrevPageSize] = useState(storedData?.data?.pageSize || 50)

	//useStates dependent on general preferences
	const [showLinks, setShowLinks] = useState(LocalStorageManager.isShowCopyIconEnabled());
	const [fontSize, setFontSize] = useState(LocalStorageManager.getFontSize());
	const [onScroll, setOnScroll] = useState(undefined);

	//useStates for the copy notification
	const [showTooltip, setShowTooltip] = React.useState(false);
	const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
	const [tooltipTimeoutRef, setToolTipTimeoutRef] = React.useState(null);

	//useRefs for the copy notification
	const dataGridRef = useRef(null);
	const rowCountRef = useRef(totalElements || 0);

	//keeps track of the sizes of the columns
	const sizes = [];

	//the settings for the queries
	const querySettings = { limit: 50 };

	/**
	 * Updates the query settings given a key and value
	 * @param {String} key - the key
	 * @param {String} value - the value
	 */
	const updateQuerySetting = (key, value) => {
		querySettings[key] = value
	}

	/**
	 * checks if the two instances of the columns array are equal. is order dependent
	 * @param {Array[Object]} columns1 
	 * @param {Array[Object]} columns2 
	 * @returns {Boolean} whether the columns are equal
	 */
	const columnsEqual = (columns1, columns2) => {
		try {
			for (let i in columns1) {
				//checks if the fields and flexes are equal
				if (columns1[i].field !== columns2[i].field || columns1[i].flex !== columns2[i].flex) {
					return false;
				}
			}
			return true;
		} catch {
			return false;
		}
	}

	/**
	 * Adds the copy icon if enabled. Changes the columns render function to do so
	 */
	const doCopyIconConditionalAdd = () => {
		const copy = [...cols];

		for (let col of copy) {
			if (col.canShowLink) {
				const originalRenderCell = getColByField(col.field, columns)?.renderCell;
				if (originalRenderCell) {
					col.renderCell = showLinks ? (params) => {
						return (
							<div style={{ display: 'flex', height: '100%', width: '100%' }}>
								<style>
									{`
          								.content-copy-icon {
            								cursor: pointer;
	            							height: 100%;
								            color: #2d5ca9;
								            padding-left: 10px;
								            padding-right: 10px;
								            transition: background-color 0.3s;
											border-radius: 5px;
											transition: color 0.3s;
											margin-right: 5px;
											
											&:hover {
												color: #0a9dd9
											}
										}

										.content-copy-icon:hover {
							            	background-color: #dbe9f8;
										}
        							`}
								</style>
								<ContentCopyIcon className="content-copy-icon" />
								{originalRenderCell(params)}
							</div>
						)
					} : originalRenderCell;
				}
			}
		}

		setCols(copy);
		return copy
	}

	/**
	 * adds the data save buttons if the columns have changed
	 * @param {Array[Object]} cs - columns
	 */
	const conditionalAddDataSaveButtons = (cs) => {

		//If using temp data, don't add the buttons
		if (location === "TempTableData" + id)
			return;

		//if cs isn't provided, defualt to cols
		if (!cs) {
			cs = cols;
		}

		//gets the data from local storage
		const localDataRequest = LocalStorageManager.getData({ location });

		//if there is local storage and the columns aren't equal, create the buttons
		if (localDataRequest.result && !columnsEqual(cs, localDataRequest.data.columns)) {
			CustomButtons.addDataButtons({
				reset,
				cols,
				writeSizeData: (data) => {
					LocalStorageManager.writeData({ location, columns: cs })
					console.log(cs)
				},
				id,
				close: closeButton
			})
		}
	}

	/**
	 * updates the grid
	 * @param {Array[Object]} cs - the new columns 
	 */
	const doUpdateGrid = (cs) => {
		//doesn't do anything if cs is empty or null
		if (cs.length > 0) {
			setCols(cs);
			//updates the grid using it's key
			setGridUpdateCounter(gridUpdateCounter + 1);

			//the next tick, it will add the moving columns and buttons
			setTimeout(() => {
				ColumnResizing.addMovingColumns({ cols: cs, sizes, doUpdateGrid, setCols, setOnScroll, id, hasCheckBox: checkboxSelection });
				CustomButtons.addButtons({
					cols: cs,
					setCols,
					doUpdateGrid,
					showLinks,
					setShowLinks,
					fontSize,
					setFontSize,
					fetchData: fetch,
					id,
					showRefreshDataButton,
					close: closeButton
				});

				conditionalAddDataSaveButtons(cs);
			}, 0);
		}
	}

	/**
	 * gets the column data given the field
	 * @param {String} field 
	 * @param {Array[Object]} columns 
	 * @returns a copy of the column
	 */
	const getColByField = (field, columns) => {
		field = field.toLowerCase();
		for (let col of columns) {
			if (col.field.toLowerCase() === field) {
				return { ...col };
			}
		}
		return null;
	};

	/**
	 * updates the resizing columns
	 */
	const updateResizingColumns = () => {
		const tableDiv = document.getElementById(id)
		let defaultWidth = ColumnResizing.getDefaultWidth(tableDiv);

		ColumnResizing.updateColumns({ cols, sizes, model: visibilityModel, defaultWidth, setCols, CustomButtons, doUpdateGrid, tableDiv });
	}

	/**
	 * Resets the column sizing and order back to what was in local storage
	 */
	const reset = () => {

		/**
		 * processes the data from local storage
		 * @param {Array[Object]} data 
		 */
		const processData = (data) => {
			const szs = [];
			const lColumns = [];

			for (let col of data.data.columns) {
				szs.push(col.flex);
				lColumns.push({ ...cols.find(c => c.field === col.field), minWidth: columns.find(c => c.field === col.field).minWidth });
			}

			const tableDiv = document.getElementById(id)
			ColumnResizing.updateColumns({ cols: lColumns, model: data.result.visibility, sizes: szs, doUpdateGrid, tableDiv });
		};

		//fetches the data from local storage
		let data = LocalStorageManager.getData({ location });

		const MAX_FETCH_ATTEMPTS = 3;
		let i = 0;

		//Fetches up to the max fetch attempts (it should never reach it, but if local storage corrupts for some reason, then it won't infinite loop)
		while (!data.result && i++ < MAX_FETCH_ATTEMPTS) {
			LocalStorageManager.writeData({ location, columns, showCopyIcon: showLinks, filters: filterModel, sort, density, visibility: visibilityModel });
			data = LocalStorageManager.getData({ location });
		}

		//data isn't valid
		if (i === MAX_FETCH_ATTEMPTS) {
			return;
		}

		processData(data);
	};

	/**
	 * updates the filter for the query settings and then the table
	 * @param {Object} filter 
	 */
	const updateFilter = (filter) => {
		console.log("onFilterModelChange called with params:", JSON.stringify({ filter }));
		if (filter.items.length > 0) {
			updateQuerySetting("filter_column", filter.items[0].columnField);
			updateQuerySetting("filter_type", filter.items[0].operatorValue);
			updateQuerySetting("filter_value", filter.items[0].value || "");
		} else {
			updateQuerySetting("filter_type", "");
		}
		setFilterModel(filter);
	}

	/**
	 * updates the sort settings for the query and then the table
	 * @param {Array} sort 
	 */
	const updateSort = (sort) => {
		console.log("onSortModelChange called with params:", JSON.stringify({ sort }));

		if (sort.length > 0) {
			updateQuerySetting("sorting_col", sort[0].field);
			updateQuerySetting("sorting_asc", sort[0].sort);
		} else {
			updateQuerySetting("sorting_col", "");
			updateQuerySetting("sorting_asc", "");
		}
		setSort(sort)
	}

	/**
	 * Fetches the data, handles feedback for loading and sets the rows and total elements
	 */
	const fetch = async () => {
		//for feedback that a load is taking place
		setIsLoading(true)

		//fetches the data
		const fetch = await fetchData({ ...querySettings, ...externalQuerySettings });

		//after fetch, no longer loading, sets rows and total elements
		setIsLoading(false)
		setRows(Array.isArray(fetch.result) ? fetch.result : [])
		setTotalElements(fetch.size || 0)
	}

	/**
	 * sets the initial query settings from local storage
	 */
	const setInitialQuerySettings = async () => {
		//gets the data from local storage
		const localStorageFetch = LocalStorageManager.getData({ location });
		if (localStorageFetch.result) {
			setDensity(localStorageFetch.data.density)

			updateFilter(localStorageFetch.data.filters)
			updateSort(localStorageFetch.data.sort)
			setVisibilityModel(localStorageFetch.data.visibility)

		} else {
			LocalStorageManager.writeData({ location, columns, filters: filterModel, sort, density, visibility: visibilityModel })
		}
	}

	/**
	 * Does the copy to clipboard on click
	 * @param {Object} params - the params from the cell
	 * @param {Event} event - the event for the click
	 */
	const handleCellClick = (params, event) => {
		//copies to clipboard
		navigator.clipboard.writeText(params.value)

		//gets the correct cell
		const cellElement = document.querySelector(`.cell-${params.id}`);

		//the cell element must exist
		if (!cellElement)
			return;

		//gets the bounding rectangle for the cell
		const cellRect = cellElement.getBoundingClientRect();

		//gets the bounding rectangle for the body
		const bodyRect = document.body.getBoundingClientRect();

		//gets the X and Y position for the tooltip
		const x = event.clientX - cellRect.x
		const y = cellRect.top - bodyRect.top - cellRect.height * 3;
		setTooltipPosition({ x: x, y: y });

		//Clear the previous timer if it exists
		if (tooltipTimeoutRef) {
			clearTimeout(tooltipTimeoutRef);
		}

		//show the tooltip
		setShowTooltip(true);

		//add the timeout function to hide the tooltip
		setToolTipTimeoutRef(setTimeout(() => {
			setShowTooltip(false);
		}, 1500))
	}

	//Gets the row count from the total elements
	const rowCount = React.useMemo(() => {
		if (totalElements !== undefined) {
			rowCountRef.current = totalElements;
		}
		return rowCountRef.current;
	}, [totalElements]);

	//Gets the styling for the tooltip
	const HtmlTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: '#2d5ca9', // Color of the arrow
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#0a9dd9',
			color: '#fff',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #FFF',
		},
	}));

	/**
	 * useEffect for front end filtering
	 */
	useEffect(() => {
		if (applyFrontEndFilter && frontEndFilter && typeof frontEndFilter == 'function') {
			setRows(frontEndFilter(rows))
			setApplyFrontEndFilter(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applyFrontEndFilter])

	/**
	 * useEffect for when the window gets resized to update the columns size values
	 */
	useEffect(() => {
		const handleResize = () => {
			const tableDiv = document.getElementById(id)
			ColumnResizing.updateColumns({ model: visibilityModel, cols, sizes, setCols, doUpdateGrid, tableDiv });
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cols, sizes, doUpdateGrid, setCols, visibilityModel]);

	/**
	 * Updates the table when showLinks is changed
	 */
	useEffect(() => {
		LocalStorageManager.setShowCopyButtonEnabled(showLinks)
		//On the next tick
		setTimeout(() => {
			const copy = doCopyIconConditionalAdd();
			ColumnResizing.addMovingColumns({ cols: copy, sizes, doUpdateGrid, setCols, setOnScroll, id, hasCheckBox: checkboxSelection });
			// eslint-disable-next-line react-hooks/exhaustive-deps
			CustomButtons.addButtons({
				cols: copy, setCols, reset, doUpdateGrid, writeSizeData: (data) => {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					columns = copy;
					LocalStorageManager.writeData({ location, columns: data })
				},
				showLinks,
				setShowLinks,
				fontSize,
				setFontSize,
				fetchData: fetch,
				id,
				showRefreshDataButton,
				close: closeButton
			});
			conditionalAddDataSaveButtons();
		}, 1);
	}, [showLinks]);

	/**
	 * useEffect for updating the show links when the columns change
	 */
	useEffect(() => {
		setShowLinks(LocalStorageManager.isShowCopyIconEnabled());
	}, [columns])

	/**
	 * useEffect for updating the font size in local storage
	 */
	useEffect(() => {
		LocalStorageManager.setFontSize(fontSize)
		setRows([...rows.map((row) => ({...row, fontSize}))])
		setCols([...cols])
	}, [fontSize])

	/**
	 * useEffect for updating the local storage for the table's sort, filtering and pagination for when the page size changes
	 */
	useEffect(() => {
		//gets the data from local storage
		const storedData = LocalStorageManager.getData({ location });

		//update the limit to match the page size
		updateQuerySetting("limit", pageSize);

		//if there is data in local storage
		if (storedData.result) {
			//update the offset based on the page number stored
			updateQuerySetting("offset", (storedData.data?.pageNumber || 0) * pageSize)

			//update the filter and sort based off local storage
			updateFilter(storedData.data.filters);
			updateSort(storedData.data.sort);
		}

		//if the new page size is smaller or equal to the previous, we don't have to fetch data again.
		if (prevPageSize <= pageSize) {
			if (pageSize < totalElements) {
				fetch()
			}
		} else {
			setRows(rows.slice(0, pageSize))
		}

		//updates the previous page size to be what it is now, so when it changes again, it's accurate
		setPrevPageSize(pageSize)

		//write the pageSize to local storage
		LocalStorageManager.writeData({ location, pageSize })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize])

	/**
	 * useEffect to call fetch based on the fetch index.
	 */
	useEffect(() => {
		// Ensure doFetchIndex is not undefined or 0 before calling fetch
		if (typeof doFetchIndex === 'number' && doFetchIndex >= 0) {
			fetch();  // Call fetchData only when doFetchIndex changes and is valid
		}
		else if (Array.isArray(doFetchIndex)) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doFetchIndex]);

	/**
	 * resets, updates the columns and sets the initial query on load
	 */
	useEffect(() => {
		reset();
		updateResizingColumns();
		setInitialQuerySettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * adds the scroll event on the table
	 */
	useEffect(() => {
		const gridElement = dataGridRef.current;

		const observer = new MutationObserver(() => {
			const scrollableContainer = gridElement.querySelector('.MuiDataGrid-virtualScroller');
			if (scrollableContainer) {
				scrollableContainer.addEventListener('scroll', onScroll);

				return () => {
					scrollableContainer.removeEventListener('scroll', onScroll);
				};
			}
		});

		if (gridElement) {
			observer.observe(gridElement, { childList: true, subtree: true });
		}

		//on component dismount
		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * removes temp table data when dismounting
	 */
	useEffect(() => {
		const removeLocalStorageItem = () => {
			localStorage.removeItem("TempTableData" + id);
			console.log('LocalStorage item removed');
		};

		const handleBeforeUnload = () => {
			removeLocalStorageItem();
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			removeLocalStorageItem();
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * An on change for a cell edit
	 */
	const handleChange = React.useCallback((event) => {
		if (handleCellEditCommit && typeof handleCellEditCommit === 'function')
			handleCellEditCommit(event)
	}, []);

	//id is required
	if (!id) {
		return (
			<h1>
				Table Requires ID Tag
			</h1>
		)
	}

	return (
		<div style={{ width: '100%', position: 'relative', marginTop: '6px' }} ref={dataGridRef} id={id}>
			{isLoading || loading ?
				<Box sx={{ width: '100%', height: '4px' }}>
					<LinearProgress />
				</Box>
				:
				<div style={{ height: '4px' }} />
			}

			<HtmlTooltip
				TransitionComponent={Zoom}
				TransitionProps={{ timeout: 200 }}
				open={showTooltip}
				onClose={() => setShowTooltip(false)}
				title={
					<React.Fragment>
						<Typography color="inherit">Copied to Clipboard</Typography>
					</React.Fragment>
				}
				placement="top"
				style={{
					position: 'absolute',
					left: tooltipPosition.x,
					top: tooltipPosition.y,
					zIndex: 9999,
				}}
			></HtmlTooltip>

			<DataGrid
				onSortModelChange={(sort) => {
					updateSort(sort)

					//writes the data to local storage
					if (LocalStorageManager.getData({ location }))
						LocalStorageManager.writeData({ location, sort })
					else
						LocalStorageManager.writeData({ location, columns, filters: filterModel, sort, density })

					//if there are less total elements than the page displays, we don't need to fetch the backend
					if (pageSize < totalElements)
						fetch()
				}}
				initialState={{
					pagination: {
						paginationModel: { pageSize, page: 0 },
					},
				}}
				sortModel={sort}
				rows={isLoading || loading ? [] : rows}
				columns={cols}
				pageSize={pageSize}
				onPageSizeChange={(page, details) => {
					//updates the page size and limit on the query
					setPageSize(page)
					updateQuerySetting("limit", pageSize);
				}}
				{...(!paginationMode && { rowCount })}
				paginationMode={paginationMode ? "client" : "server"}
				onPageChange={(newPage) => {
					//writes the page number to local storage
					LocalStorageManager.writeData({ location, pageNumber: newPage });

					const storedData = LocalStorageManager.getData({ location });

					//updates the filter and the sort back to what they were
					updateFilter(storedData.data.filters);
					updateSort(storedData.data.sort);

					//changes the offset and fetches
					updateQuerySetting("offset", newPage * (pageSize));
					fetch()
				}}
				getCellClassName={(params) => `cell-${params.id}`}
				components={{
					Toolbar: GridToolbar,
					NoRowsOverlay: () => (
						<Stack height="100%" alignItems="center" justifyContent="center">
							{isLoading || loading ? <CircularProgress /> : "Sorry No Results ;("}
						</Stack>
					),
					NoResultsOverlay: () => (
						<Stack height="100%" alignItems="center" justifyContent="center">
							{isLoading || loading ? <CircularProgress /> : "Sorry No Results ;("}
						</Stack>
					)
				}}
				filterModel={filterModel}
				onFilterModelChange={(filter) => {
					//updates the filter
					updateFilter(filter)

					//updates the local storage filter
					if (LocalStorageManager.getData({ location }).result)
						LocalStorageManager.writeData({ location, filters: filter })
					else
						LocalStorageManager.writeData({ location, columns, filters: filter, sort, density })

					fetch()
				}}
				editMode={handleChange ? "cell" : "row"}
				componentsProps={componentsProps}
				sx={{
					boxShadow: 2,
					border: 2,
					borderColor: 'white',
					'& .MuiDataGrid-cell:hover': {
						color: 'primary.main',
					},
					'& .MuiDataGrid-toolbarContainer': {
						paddingTop: '16px',
						paddingBottom: '16px'
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: "#2d5ca9",
						color: "white",
					},
					...(
						!getRowClassName ? {
							'& .MuiDataGrid-virtualScrollerRenderZone': {
								'& .MuiDataGrid-row': {
									'&:nth-of-type(even)': { backgroundColor: '#eff6ff' },
									'&:nth-of-type(odd)': { backgroundColor: 'white' },
								}
							}
						} : {
							'& .TableRow-Default': {
								'&:nth-of-type(even)': { backgroundColor: '#eff6ff' },
								'&:nth-of-type(odd)': { backgroundColor: 'white' },
							},

							"& .TableRow-Retest": {
								backgroundColor: '#0a9dd9',
								transition: 'ease-in-out .1s all',
								"& > *": {
									color: "white",
									"& > * > *": {
										color: 'white'
									}
								},
								"&:hover": {
									backgroundColor: '#1C7DC1',
									"& > *": {
										color: "white",
										"& > * > *": {
											color: 'white'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#0364A8',
									"&:hover": {
										backgroundColor: '#00579B',
										"& > *": {
											color: "white",
											"& > * > *": {
												color: 'white'
											}
										},
									},
								}
							},
							"& .TableRow-Pass": {
								backgroundColor: '#c0d8c1',
								transition: 'ease-in-out .1s all',
								"& > * ": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#b2d6ba',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#98c8a3',
									"&:hover": {
										backgroundColor: '#81bc8e',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},
							"& .TableRow-Fail": {
								backgroundColor: '#f9bab9',
								transition: 'ease-in-out .1s all',
								"& > * ": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#f69391',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#f3706d',
									"&:hover": {
										backgroundColor: '#f0504c',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},
							"& .TableRow-RequestClosed": {
								backgroundColor: '#b8dcf4',
								transition: 'ease-in-out .1s all',
								"& > *": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#90d4fc',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#30aff9',
									"&:hover": {
										backgroundColor: '#13a4f8',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},
							"& .TableRow-RequestOpen": {
								backgroundColor: '#ffd4b4',
								transition: 'ease-in-out .1s all',
								"& > *": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#ffbb88',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#ffa561',
									"&:hover": {
										backgroundColor: '#ff913e',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},
							"& .TableRow-EridanPass": {
								backgroundColor: '#fff9b4',
								transition: 'ease-in-out .1s all',
								"& > *": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#fff688',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#fff361',
									"&:hover": {
										backgroundColor: '#fff03e',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},
							"& .TableRow-Note": {
								backgroundColor: '#ffffff',
								transition: 'ease-in-out .1s all',
								"& > *": {
									color: "black",
									"& > * > *": {
										color: 'black'
									}
								},
								"&:hover": {
									backgroundColor: '#e6e6e6',
									"& > *": {
										color: "black",
										"& > * > *": {
											color: 'black'
										}
									},
								},
								"&.Mui-selected": {
									backgroundColor: '#cfcfcf',
									"&:hover": {
										backgroundColor: '#bababa',
										"& > *": {
											color: "black",
											"& > * > *": {
												color: 'black'
											}
										},
									},
								}
							},

						}
					), '& .MuiDataGrid-row': {
						color: "#045eb3",
					},
					fontSize,
					...sx
				}}
				columnVisibilityModel={visibilityModel}
				onColumnVisibilityModelChange={(model) => {
					//updates the visibility model
					setVisibilityModel(model);

					//writes the visibility model to local storage
					if (LocalStorageManager.getData({ location }))
						LocalStorageManager.writeData({ location, visibility: model })
					else
						LocalStorageManager.writeData({ location, columns, filters: filterModel, sort, density })

					//updates the moving columns to account for the missing column on the next tick
					setTimeout(() => {
						ColumnResizing.addMovingColumns({ cols, sizes, model, setOnScroll, id, hasCheckBox: checkboxSelection });
						const tableDiv = document.getElementById(id)
						ColumnResizing.updateColumns({ cols, sizes, model: visibilityModel, setCols, doUpdateGrid, tableDiv });
					}, 0);
				}}
				onCellClick={(onCellClick && typeof onCellClick === 'function') ? onCellClick : handleCellClick ? undefined : handleCellClick}
				onCellEditCommit={(params) => handleChange(params)}
				density={density || 'standard'}
				onStateChange={(v) => {
					if (density) {
						if (v && v.density && v.density.value) {
							//updates the density
							setDensity(v.density.value)

							//updates the density in local storage
							if (LocalStorageManager.getData({ location }))
								LocalStorageManager.writeData({ location, density: v.density.value })
							else
								LocalStorageManager.writeData({ location, columns, filters: filterModel, sort, density: v.density.value })
						}
					}
				}}
				onRowClick={onRowClick}
				selectionModel={selectionModel}
				onSelectionModelChange={onSelectionModelChange}
				getRowClassName={getRowClassName}
				checkboxSelection={checkboxSelection}
			/>
		</div>
	);
};

export default Table;
