import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState  = {
    value : 0,
    loading: false,
    error: null
}

export const fetchTestData = createAsyncThunk(
  'test/fetchTestData',
  async (payload, thunkAPI) => {
    try {
      // Perform async operation here
      return 'data'; // Replace with actual fetched data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    test: (state) => {
      state.value += 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTestData.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(fetchTestData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { test } = testSlice.actions;
// export { fetchTestData };
export default testSlice.reducer;
