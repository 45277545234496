// Redux store
import { configureStore , combineReducers } from '@reduxjs/toolkit';
import testReducer from './test/testSlice.js';
import comboBoxReducer from './combobox/comboBoxSlice.js';
import comboBoxStatusReducer from './combobox/comboBoxStatusSlice.js';
import partReducer from './combobox/partSlice.js';
import stationReducer from './combobox/stationSlice.js';
import equipmentReducer from './combobox/equipmentSlice.js';

import authStateReducer from './auth/authStateSlice.js';

import loadStateReducer from './loadstate/loadStateSlice.js';
import errorSliceReducer from './error/errorSlice.js';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
  }
  
  const rootReducer = combineReducers({
    test: testReducer,
    combobox : comboBoxReducer,
    comboboxstatus : comboBoxStatusReducer,
    part : partReducer ,
    station : stationReducer ,
    equipment : equipmentReducer , 
    loadstate : loadStateReducer,
    auth : authStateReducer ,
    error : errorSliceReducer

    
  })

  const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);


