const dividersClassName = "MuiDataGrid-iconSeparator";
const headerClassName = "MuiDataGrid-columnHeader";
const rowClassName = "MuiDataGrid-row";

/**
 * Gets the default width given the table
 * @param {HTMLDivElement} tableDiv
 * @returns the default width
 */
const getDefaultWidth = (tableDiv) => {
	console.log(
		"getDefaultWidth called",
		tableDiv,
		tableDiv.getElementsByClassName(headerClassName)
	);
	let sum = 0;
	if (!tableDiv) {
		return sum;
	}

	Array.from(tableDiv.getElementsByClassName(headerClassName)).forEach((header) => {
		sum += parseInt(header.style.width, 10);
	});
	return sum;
};

/**
 * Gets the default visibility model (visible for each)
 * @param {Array[Object]} cols
 * @returns
 */
const getDefaultVisibilityModel = (cols) => {
	console.log(
		"getDefaultVisibilityModel called with params:",
		JSON.stringify({ cols })
	);
	let obj = {};
	cols.forEach((col, index) => {
		obj[col.field] = true;
	});
	return obj;
};

/**
 * Updates the columns of the table given new sizes
 *
 * @param {Array[Object]} model
 * @param {Array[Object]} cols
 * @param {Array[Number]} sizes
 * @param {Function} setCols
 * @param {Function} doUpdateGrid
 * @param {HTMLDivElement} tableDiv
 */
const updateColumns = ({
	model,
	cols,
	sizes,
	setCols,
	doUpdateGrid,
	tableDiv,
}) => {
	// Creating a copy of the columns array to avoid direct mutation
	const copy = [...cols];
	console.log(copy);

	let i = 0,
		index = 0;

	// Using the provided model or a default visibility model for columns
	const lModel = model || getDefaultVisibilityModel(cols);

	// Iterating through the visibility model
	for (let vis in lModel) {
		console.log(vis);
		console.log(cols[i]);

		// If the column is visible
		if (lModel[vis]) {
			console.log(sizes[i], cols[i]);

			try {
				// Assigning the size to the corresponding column's flex property
				copy[index].flex = sizes[i] || cols[i].flex;
			} catch {
				break; // Breaking out of the loop in case of an error
			}
			i++;
		}
		index++;
	}

	i = 0;

	// Adjusting the flex or minWidth property based on the visibility model and size
	for (let vis in lModel) {
		if (lModel[vis]) {
			// Calculating the flex value safely
			const val = ((i) => {
				try {
					return parseInt(copy[i].flex);
				} catch {
					return 1 / Object.keys(lModel).length;
				}
			})(i);

			try {
				// Assigning either flex or minWidth property based on calculated value
				copy[i][val ? "flex" : "minWidth"] = val / getDefaultWidth(tableDiv);
			} catch {
				// In case of an error, pushing a new column object with calculated flex value
				copy.push({ flex: val / getDefaultWidth(tableDiv) });
			}
		}
		i++;
	}

	doUpdateGrid(copy);
};

/**
 *
 * @param {Number} index - the index of the currently displayed children
 * @param {Array[Number]} sizes - the sizes
 * @param {Number} colIndex - the column index
 * @param {Number} nextColIndex - the next column index
 * @param {HTMLDivElement} tableDiv - the table div
 * @returns
 */
const updateColumnWidths = ({
	index,
	sizes,
	colIndex,
	nextColIndex,
	tableDiv,
}) => {
	// Check if tableDiv is not provided or valid
	if (!tableDiv) {
		return; // Exit function if tableDiv is not defined
	}

	// Set default colIndex if not provided or not a number
	if (!colIndex || typeof colIndex !== "number") {
		colIndex = index; // Use index as colIndex if not explicitly provided
	}

	// Set default nextColIndex if not provided or not a number
	if (!nextColIndex || typeof nextColIndex !== "number") {
		nextColIndex = colIndex + 1; // Set nextColIndex to colIndex + 1 if not explicitly provided
	}

	// Iterating through each row with the specified class name
	Array.from(tableDiv.getElementsByClassName(rowClassName)).forEach((row) => {
		// Updating styles for the current column and the next column
		row.children[index].style.width = sizes[colIndex] + "px"; // Setting width
		row.children[index].style.minWidth = sizes[colIndex] + "px"; // Setting min width
		row.children[index].style.maxWidth = sizes[colIndex] + "px"; // Setting max width
		row.children[index + 1].style.width = sizes[nextColIndex] + "px"; // Setting width for next column
		row.children[index + 1].style.minWidth = sizes[nextColIndex] + "px"; // Setting min width for next column
		row.children[index + 1].style.maxWidth = sizes[nextColIndex] + "px"; // Setting max width for next column
	});
};

/**
 * Gets the column index by matching the header names
 * @param {String} headerName - the header name to match
 * @param {Array[Object]} cols - the columns
 */
const getColumnIndexFromHeaderName = ({ cols, headerName }) => {
	let i = 0;
	for (let col of cols) {
		if (col.headerName === headerName) {
			return i;
		}
		i++;
	}
	return -1;
};

/**
 * Adds the moving columns to the correct table based on the ID
 * @param {Number} defaultWidth- the default width of the grid
 * @param {Array[Object]} cols - the columns
 * @param {Array[Number]} sizes - the sizes of the columns
 * @param {Function} setCols - the function to update the columns useState
 * @param {Function} doUpdateGrid - the function to update the grid
 * @param {Function} setOnScroll - the on scroll function
 * @param {String} id - the id for the table (used to calibrate the functions)
 * @param {Boolean} hasCheckBox - whether the table has a checkbox
 */
const addMovingColumns = ({
	defaultWidth,
	cols,
	sizes,
	setCols,
	doUpdateGrid,
	setOnScroll,
	id,
	hasCheckBox,
}) => {
	//Gets the calibrated div that contains the table, so it doesn't add it to some other table
	const tableDiv = document.getElementById(id);
	if (!tableDiv) {
		return;
	}

	//Gets the headers from
	let headers = Array.from(tableDiv.getElementsByClassName(headerClassName));

	console.log(headers)

	//Updates the default width if not provided
	if (typeof defaultWidth !== "number" || defaultWidth === 0) {
		defaultWidth = getDefaultWidth(tableDiv);
	}

	//Sets default values for the table
	const minSize = 30; // the minimum  size of the column
	const defaultMouseMove = document.onmousemove; // the default on move function
	const defaultMouseUp = document.onmouseup; // the default on mouse up function

	// the dividers
	let hs = Array.from(tableDiv.getElementsByClassName(dividersClassName));

	//for each divider, add the on mouse down, mouse move and mouse up
	hs.forEach((ele, index) => {
		if (index === 0 && hasCheckBox) {
			return;
		}
		const headerEle = headers[index];
		let nextEle = headers[index + 1];

		//If the next element doens't exist, we're at the end of the table, and we don't want to grow the table beyond constraints
		if (nextEle) {
			// Gets the text content (headerName) from the HTML column
			// Used to match to calibrate which column we want to use
			// Since visibility settings change which columns neighbor each other
			// Reordering reorders the columns data structure, so it's unimportant here,
			// but if it ever didn't change that, this would fix that
			const currHeaderName = headerEle.getElementsByClassName(
				"MuiDataGrid-columnHeaderTitle"
			)[0].textContent;
			const nextHeaderText = nextEle.getElementsByClassName(
				"MuiDataGrid-columnHeaderTitle"
			)[0].textContent;

			// gets the column index that matches the header
			const headerIndex = getColumnIndexFromHeaderName({
				cols,
				headerName: currHeaderName,
			});

			//gets the column indx that matches the next header
			const nextHeaderIndex = getColumnIndexFromHeaderName({
				cols,
				headerName: nextHeaderText,
			});

			ele.style.cursor = "col-resize"; // Styling to give visual feedback that the columns are resizable

			//Sets the default values of the widths and mouse position to undefined
			let originalMouseX = undefined;
			let originalWidth = parseInt(headerEle.style.width, 10);
			let nextEleOriginalWidth = parseInt(nextEle.style.width, 10);

			//Updates the sizes array
			sizes[headerIndex] = originalWidth;
			sizes[nextHeaderIndex] = nextEleOriginalWidth;

			//The mouse down event to start a "Drag"
			ele.onmousedown = (e) => {
				//Adds visual feedback to the user that they're resizing
				document.body.style.cursor = "col-resize";
				document.documentElement.style.cursor = "col-resize";

				//Sets the starter value for the cursor's X position
				originalMouseX = e.clientX;

				//Sets the original width
				originalWidth = parseInt(headerEle.style.width, 10);

				//Sets the max size based on the sizes of the two affected columns
				const maxSize = sizes[headerIndex] + sizes[nextHeaderIndex];

				//Sets the on mouse move (Dragging)
				document.onmousemove = (e) => {
					//sets the mouse position
					const currMouseX = e.clientX;

					//Calculates the size of the column (Clamps between minSize and maxSize)
					const size = Math.max(
						Math.min(
							maxSize - minSize,
							currMouseX - originalMouseX + originalWidth
						),
						minSize
					);

					//Gets the size of the next column that fills the rest of the space
					const nextSize = maxSize - size;

					//Updates the sizes array
					sizes[headerIndex] = size;
					sizes[nextHeaderIndex] = nextSize;

					//Adds temporary CSS styling to show the user
					headerEle.style.width = size + "px";
					headerEle.style.minWidth = size + "px";
					headerEle.style.maxWidth = size + "px";

					nextEle.style.width = nextSize + "px";
					nextEle.style.minWidth = nextSize + "px";
					nextEle.style.maxWidth = nextSize + "px";

					//Updates the rows CSS styling
					updateColumnWidths({
						index,
						sizes,
						colIndex: headerIndex,
						nextColIndex: nextHeaderIndex,
						tableDiv,
					});

					//Updates the rows CSS styling on scroll.
					//MUI X Data Grid doesn't render all of the elements at once for performace, 
					//so trying to do temporary CSS styling would break if the user ONLY scrolls without moving the mouse
					setOnScroll(() =>
						updateColumnWidths({
							index,
							sizes,
							colIndex: headerIndex,
							nextColIndex: nextHeaderIndex,
							tableDiv,
						})
					);
				};

				//Resets the document properties and calls updateColumns
				document.onmouseup = () => {
					document.body.style.cursor = "default";
					document.documentElement.style.cursor = "default";
					document.onmousemove = defaultMouseMove;
					document.onmouseup = defaultMouseUp;
					updateColumns({
						cols,
						sizes,
						defaultWidth,
						setCols,
						doUpdateGrid,
						tableDiv,
					});
					setOnScroll(undefined);
				};
			};
		}
	});
};

/**
 * Gets the column given the header name
 * @param {String} headerName - the header name
 * @param {Array[Object]} cols - the columns
 * @returns 
 */
const getColFromHeaderName = (headerName, cols) => {
	headerName = headerName.toLowerCase();
	for (let col of cols) {
		if (col.headerName.toLowerCase() === headerName) {
			return col;
		}
	}
	return null;
};

/**
 * a function to get the flexes of the columns
 * @param {Array[Object]} cols - the columns
 * @param {HTMLDivElement} tableDiv - the table div
 * @returns 
 */
const getFlexes = (cols, tableDiv) => {
	console.log("getFlexes called with params:", JSON.stringify({ cols }));
	const width = getDefaultWidth(tableDiv);
	let obj = {};
	Array.from(tableDiv.querySelector(`.${headerClassName}`)).map((header) => {
		obj[getColFromHeaderName(header.textContent, cols).field] =
			parseInt(header.style.width, 10) / width;
		return obj;
	});
	return obj;
};

const exp = {
	addMovingColumns,
	updateColumnWidths,
	updateColumns,
	getDefaultWidth,
	getFlexes,
};

export default exp;
