import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure, logout } from '../../store/auth/authStateSlice';
import ApiManager from '../../api_utils/ApiManager';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [manageAnchorEl, setManageAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const manageOpen = Boolean(manageAnchorEl);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const pageError = useSelector((state) => state.error);
  const { errorMessage } = pageError;
  const { user, error } = authData;
  const navigate = useNavigate();

  const generateUserName = (name) => {
    if (name !== null && name !== undefined) {
      const names = name.split(' ');
      const firstLetters = names.map(word => word.charAt(0));
      return firstLetters.join('');
    }
  };

  const shortName = generateUserName(user);

  const handleClose = () => {
    setAnchorEl(null);
    setManageAnchorEl(null);
  };

  const handleManageClick = (event) => {
    setManageAnchorEl(event.currentTarget);
  };

  const logoutEvent = async () => {
    try {
      const data = await ApiManager.auth_logOutUser();
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      dispatch(loginFailure(error));
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-container container">
        <div className="navbar-logo-box">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="logo" />
          </Link>
          <Button onClick={handleManageClick} style={{ marginLeft: '20px' }}>Manage</Button> {/* Added margin for spacing */}
          <Menu
            anchorEl={manageAnchorEl}
            open={manageOpen}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem component={Link} to="/status/manage">
              Status
            </MenuItem>
            <MenuItem component={Link} to="/configurations">
              Configurations
            </MenuItem>
            <MenuItem component={Link} to="/mac-addresses">
              Mac Addresses
            </MenuItem>
          </Menu>
        </div>
        <ul className="nav-menu">
          <li className="nav-item">
            <Avatar
              alt={user}
              style={{ backgroundColor: '#1E90FF' }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {shortName}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <GoogleLogout
                  clientId="134289677333-526h5cl7r4itcvuak3li2591eeme7qbc.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={logoutEvent}
                />
              </MenuItem>
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
