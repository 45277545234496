import React, { useState, useRef, useEffect } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import './ColumnOrder.css'

/**
 * A component that allows users to drag and drop colums to reorder them, with visual feedback for the dragging item.
 * @param {Object} columns - The columns
 * @param {Function} updateColumns - Function to update the columns
 * @param {Function} doUpdateGrid - Function to update the grid
 */
const ColumnOrder = ({ columns, updateColumns, doUpdateGrid }) => {
    const [currentColumns, setCurrentColumns] = useState(columns);
    const [draggingIndex, setDraggingIndex] = useState(null);
    const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });
    const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });
    const draggingItemRef = useRef(null);
    const draggingElementRef = useRef(null);

    /**
     * Handles the start of the dragging
     * @param {Number} index - the index of the column
     */
    const handleDragStart = (index) => (event) => {
        const element = draggingElementRef.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            setDraggingOffset({
                x: rect.width / 2,
                y: rect.height / 2,
            });
        }
        setDraggingIndex(index);
        setDraggingPosition({ x: event.clientX, y: event.clientY });
        draggingItemRef.current = currentColumns[index];
        event.dataTransfer.setDragImage(new Image(), 0, 0); // Hides the default drag image
    };

    /**
     * Prevents page changes
     * @param {Number} index 
     */
    const handleDragOver = (index) => (event) => {
        event.preventDefault();
    };

    /**
     * Handles the drop, reorders the columns and updates the grid
     * @param {Number} index 
     */
    const handleDrop = (index) => (event) => {
        event.preventDefault();
        const dragIndex = draggingIndex;
        const updatedColumns = [...currentColumns];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(index, 0, draggedColumn);
        setCurrentColumns(updatedColumns);
        updateColumns(updatedColumns);
        setDraggingIndex(null);
        setDraggingPosition({ x: 0, y: 0 });
        doUpdateGrid(updatedColumns)
    };

    /**
     * Handles the resetting of values
     */
    const handleDragEnd = () => {
        setDraggingIndex(null);
        setDraggingPosition({ x: 0, y: 0 });
    };

    /**
     * Handles the visual feedback for dragging a column
     * @param {Event} event 
     */
    const handleMouseMove = (event) => {
        if (draggingIndex !== null) {
            setDraggingPosition({ x: event.clientX - draggingOffset.x, y: event.clientY - draggingOffset.y });
        }
    };

    /**
     * Handles the dragging
     */
    useEffect(() => {
        window.addEventListener('drag', handleMouseMove);
        window.addEventListener('mouseup', handleDragEnd);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleDragEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggingIndex]);

    return (
        <div className='columnOrder-container'>
            {currentColumns.map((col, index) => (
                <div
                    key={index}
                    className='columnOrder-item'
                    style={{
                        opacity: draggingIndex === index ? 0.5 : 1,
                    }}
                    draggable
                    onDragStart={handleDragStart(index)}
                    onDragOver={handleDragOver(index)}
                    onDrop={handleDrop(index)}
                    onDragEnd={handleDragEnd}
                    ref={draggingIndex === index ? draggingElementRef : null}
                >
                    <DragIndicatorIcon />{col.headerName}
                </div>
            ))}
            {draggingIndex !== null && (
                <div
                    className='columnOrder-dragging'
                    style={{
                        top: draggingPosition.y,
                        left: draggingPosition.x,
                    }}
                >
                    {draggingItemRef.current.headerName}
                </div>
            )}
        </div>
    );
};

export default ColumnOrder;
