import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

const useStyles = makeStyles({
  formControl: {
    width: '200px', // adjust as needed
  },
  highlightedCell: {
    backgroundColor: 'red',
    color: 'white',
  },
});

function ManageSerialRevs() {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = React.useState(null);
  const [selectedAction, setSelectedAction] = React.useState('');

  const rows = [
    {
      id: 1,
      revVersion: '1.0',
      dateAssigned: '01/01/2023',
      serial: 'S12345',
      latestRev: 'Rev A',
      currentRev: 'Rev B'
    },
    {
      id: 2,
      revVersion: '1.1',
      dateAssigned: '02/01/2023',
      serial: 'S12346',
      latestRev: 'Rev A',
      currentRev: 'Rev C'
    },
    {
      id: 3,
      revVersion: '1.2',
      dateAssigned: '03/01/2023',
      serial: 'S12347',
      latestRev: 'Rev B',
      currentRev: 'Rev B'
    },
  ];
  // 2. Create the cellClassName function
const highlightMismatchedRevs = (params) => {
  if (params.field === 'latestRev' || params.field === 'currentRev') {
    const { latestRev, currentRev } = params.row;
    if (latestRev !== currentRev) {
      return classes.highlightedCell;
    }
  }
  return "";
}

  const columns = [
    { field: 'revVersion', headerName: 'Rev Version', width: 150 },
    { field: 'dateAssigned', headerName: 'Date Assigned', width: 150 },
    { field: 'serial', headerName: 'Serial #', width: 150 },
    { 
      field: 'latestRev', 
      headerName: 'Latest Rev', 
      width: 150, 
      cellClassName: highlightMismatchedRevs 
    },
    { 
      field: 'currentRev', 
      headerName: 'Current Rev', 
      width: 150, 
      cellClassName: highlightMismatchedRevs 
    },
  ];

  const handleSelectionChange = (newSelection) => {
    console.log("New Selection:", newSelection);
    setSelectedRows(newSelection);
 }
 
 
 
 
 const handleDropdownChange = (event) => {
    console.log("Selected Action Updated:", event.target.value);
    setSelectedAction(event.target.value);
 }

 const handleSubmit = () => {
  console.log("Submit button clicked. Action:", selectedAction, "Selected Rows:", selectedRows);
  if (selectedAction && Array.isArray(selectedRows)) {
     selectedRows.forEach(rowId => {
        const row = rows.find(r => r.id === rowId);
        console.log(`Performing '${selectedAction}' on:`, row);
     });
  }
}


  return (
    <div style={{ width: '100%', height: '80vh' }}>
      <Box marginBottom={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="action-label">Choose action</InputLabel>
              <Select
                labelId="action-label"
                value={selectedAction}
                onChange={handleDropdownChange}
                label="Choose action"
                input={<OutlinedInput label="Choose action" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Uprev & Rework">Uprev & Rework</MenuItem>
                <MenuItem value="Rework">Rework</MenuItem>
                <MenuItem value="Add Note">Add Note</MenuItem>
                <MenuItem value="Unassign Rev">Unassign Rev</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleSubmit} style={{ height: '56px' }}> 
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        checkboxSelection
        onSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
}

export default ManageSerialRevs;