import React, { useEffect , useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../store/auth/authStateSlice';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

// const https = require('https');
const fetch = require('node-fetch');
const clientId = "134289677333-526h5cl7r4itcvuak3li2591eeme7qbc.apps.googleusercontent.com"
// https.globalAgent.options.rejectUnauthorized = false;
const defaultTheme = createTheme();

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleApiError = ApiError();
  const [loadingLogin, setLoadingLogin] = useState(false)
  useEffect( ()=>{
    function start() {
        console.log("asdasd")
        gapi.client.init({
            clientId :clientId ,
            scope:""
        })
    }
    gapi.load('client:auth2', start);
  })
  const responseGoogle =  async (response) => {

    // console.log(response);
    console.log("Successsssss" , JSON.stringify({ google_id_token : response.tokenId }))
    // const params = new URLSearchParams(window.location.search);
    // const full_route = window.location.href
    // const routeParam = params.get('parameter');
    // console.log("Route Parameter:", routeParam , full_route);
    const currentRoute = window.location.href;
    const path = window.location.pathname;
    const queryString = window.location.search;
    const fullPath = path + queryString;
    console.log("full" , fullPath)
    try {
      setLoadingLogin(true)
        const data = await ApiManager.auth_logInUser( response.tokenId)
        // console.log(data)
        const dataName = await handleApiError(data);
        
        setLoadingLogin(false)

        dispatch(loginSuccess(dataName.name))

        console.log("Current Route:", currentRoute);
        console.log("LOGGING IN" , dataName )
        if (fullPath === "/login") {
            navigate("/");

        }
        else {
          console.log("redirecting " , fullPath)
            navigate(fullPath);

        }
    } catch (error) {
        console.log('errrrror', error)
        setLoadingLogin(false)

        dispatch(loginFailure(error))
    }
    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/auth/google`, {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ google_id_token : response.tokenId }),
    // })
    // .then(res => res.json())
    // .then(data => {
    //     console.log('payloadddd here');

    //   console.log('Login successful:', data);


    //     dispatch(loginSuccess(data.name))
    //      navigate('/queue');
    // })
    // .catch(error => {
    //   console.error('Error during login:', error);
    // //   dispatch(loginFailure(error))

    // });
    // Here you would usually verify the Google token against your backend service
    // For now, let's just navigate to the dashboard if Google login is successful
    // navigate('/dashboard');
  };

  const handleFailure = (response) => {
    console.error(response);
    console.log('Failed to log in with Google. Please try again.');
  };

  const logout = () => {
    console.log("Logged out successfully");
  };

  return (
    // <Paper elevation={3} style={{ padding: '20px', maxWidth: '400px', margin: '20px auto' }}>
    //   <Typography variant="h5" style={{ marginBottom: '20px' }}>Login with Google</Typography>
    //   <GoogleLogin
    //     clientId={clientId}
    //     buttonText="Login with Google"
    //     onSuccess={responseGoogle}
    //     onFailure={handleFailure}
    //     cookiePolicy={'single_host_origin'}
    //     isSignedIn={false}
    //   />
    // </Paper>

    <ThemeProvider theme={defaultTheme}>
      { loadingLogin && <LinearProgress/> }
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://eridanio.wpenginepowered.com/wp-content/uploads/2019/07/Eridan-Application-MIMO.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img  src="/logo.png" alt="logo"/>



<Paper elevation={2} style={{ padding: '20px', width: 500 , height: 300 ,margin: '50px auto' , backgroundColor: '#f9fcff' }}> 

   <Box style={{ padding: '20px', maxWidth: '700px', margin: '20px auto' }}  >
   <Typography component="h2" variant="h4" style={{  alignItems: 'center'}}>
Sign In to Datatools
</Typography>
<div style={{ marginLeft: '100px' , marginTop: '50px', alignItems: 'center'}}>
<GoogleLogin
   clientId={clientId}
   buttonText="Login with Google"
  onSuccess={responseGoogle}
   onFailure={handleFailure}
  cookiePolicy={'single_host_origin'}
   isSignedIn={false}
 />

</div>

 </Box>
</Paper> 
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    







  );
};

export default Login;
