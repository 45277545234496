import React from 'react';
import { Stepper, StepConnector, styled } from '@material-ui/core';
import {StepContainer} from '../.';  // Adjust the path as necessary based on your folder structure

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '& .MuiStepConnector-line': {
        borderColor: '#333',
        borderWidth: '4px',
        boxShadow: '0px 0px 7px rgba(39, 144, 245, 0.8)',
    },
}));

export default function StatusBar({statuses , handleClick,page , partNum, logInfo , handleStatusUpdate}) {  // Renamed for clarity
    // const steps = statuses
    console.log("ssss" , statuses)

    return (
        <div style={{ background: 'rgba(121, 184, 251, 0.8)', padding: '15px', borderRadius: '30px' }}>
            <Stepper alternativeLabel activeStep={2} connector={<CustomStepConnector />}>
                {statuses.map((step, index) => (
                    
                    page === "log" ? (
                        <StepContainer 
                            key={index} 
                            label={step.name} 
                            status={step.status} 
                            ref_id={step.ref_id}
                            process_id={step.process_id}
                            sid={logInfo.id} 
                            handleClick={handleClick}  
                            page={page} 
                            partNum={partNum} 
                            logInfo={logInfo}
                            handleStatusUpdate={handleStatusUpdate}
                        />
                    ) : (
                        <StepContainer 
                        key={index} 
                        label={step.name} 
                        status={step.status} 
                        ref_id={step.ref_id}
                        process_id={step.process_id}
                        sid={step.sid} 
                        handleClick={handleClick}  
                        page={page}
                        partNum={partNum} 
                        logInfo={logInfo}
                    />                    )
                    ))}
            </Stepper>
        </div>
    );
}

