import React, {useState, useEffect} from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index' ,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Failures',
      
          font: {
            size: 24
          }
    },
  },
  scales: {
    y: {
      type: 'linear' ,
      display: true,
      position: 'left' ,
    },
    y1: {
      type: 'linear' ,
      display: true,
      position: 'right' ,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


export function ParetoChart({datas}) {

  const [chartData,setChartData] = useState(
    {
      labels :[],
      datasets: [
    
        {
          type: 'line' ,
          label: 'Cummulative Percentages',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          data: [],
          yAxisID: 'y1',
          fill: false,
    
        },
        {
          type: 'bar' ,
          label: 'Number of Defectives',
          backgroundColor: 'rgb(39, 222, 245)',
          data: [],
          borderColor: 'white',
          borderWidth: 2,
          yAxisID: 'y',
          fill: false,
    
        }
      ],
    }

  )

  useEffect(() => {
    const labels = datas.name
    setChartData (
      {
        labels,
        datasets: [
      
          {
            type: 'line' ,
            label: 'Cummulative Percentages',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            data: datas.cumm,
            yAxisID: 'y1',
            fill: false,
      
          },
          {
            type: 'bar' ,
            label: 'Number of Failures',
            backgroundColor: 'rgb(39, 222, 245)',
            data: datas.fail,
            borderColor: 'white',
            borderWidth: 2,
            yAxisID: 'y',
            fill: false,
      
          }
        ],
      }
    )
  },[datas]);

  return <Chart options={options} type='bar' data={chartData} />;
}
