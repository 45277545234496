import React , { useEffect} from 'react'
import {ParetoChart,DateForm} from '../../components'


    function prepareParetoData(data) {
        // Calculate the total sum of all data points
        const total = data.reduce((sum, value) => sum + value, 0);
      
        // Calculate the cumulative percentage for each data point
        let cumPercentage = 0;
        const dataWithPercentage = data.map((value, index) => {
          cumPercentage += value / total * 100;
          return cumPercentage
        });
      
        return dataWithPercentage;
      }


export default function PartChart() {
    const [data, setData] = React.useState([]);
    const [temp, setTemp] = React.useState([])
    useEffect ( () =>{
      
      const name =  temp.map(obj => obj.test_name);
      const fail = temp.map(obj => obj.fail_count);
      const cumm = prepareParetoData(fail);
      setData({'name': name, 'fail' : fail, 'cumm': cumm})
    }
      ,[temp])
    return (
        <div>
          {/* <TestComponent/> */}
        <ParetoChart datas= {data}/>
        <br/>
      <DateForm setRows={setTemp} route={"pareto"}/>


        </div>
    )

}