import React, { useState } from 'react';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import ApiManager from '../../api_utils/ApiManager';
const theme = createTheme({
  palette: {
    primary: {
      main: '#2D5CA9',
    },
    background: {
      default: '#fff',
    },
  },
});

export default function AddPartNumberForm({ setisSet , handleApiError }) {
  const [isError, setisError] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();

    var part_input = event.target.part_number.value
    var description_input = event.target.description.value

    if (part_input.length === 0) {
      setisError("Part Number must have an input")
      return
    }
    if (description_input.length === 0) {
      setisError("Description cannot be empty")
      return
    }

    var b = {
      part_number: part_input,
      description: description_input,
    }
    const d =  await ApiManager.intake_postAddPartNumber(b)
    const data = await handleApiError(d)
        console.log(data)
        console.log(data.message)
        if (data.message.includes("Error:")) {
          setisError(data.message)
          return
        }
        setisSet(true)

    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/addpartnumber`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data)
    //     console.log(data.message)
    //     if (data.message.includes("Error:")) {
    //       setisError(data.message)
    //       return
    //     }
    //     setisSet(true)
    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //   });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" spacing={2} sx={{ border: 'solid 1px black', padding: '30px' }}>
        <Typography variant="h5">Add Part Number</Typography>
        {isError && <Typography style={{ color: 'red' }} variant="h5">{isError}</Typography>}
        <form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2} sx={{ marginTop: '20px' }}>
            <TextField id="part_number" label="Part Number" variant="outlined" fullWidth />

            <TextField id="description" label="Descriptor" variant="outlined" fullWidth />
            <Button type="submit" variant="contained" sx={{ marginTop: '20px' }}>Submit</Button>
          </Stack>
        </form>
      </Stack>
    </ThemeProvider>
  )
}
