import React , { useEffect, useState } from "react";
import FileUpload from "react-material-file-upload";

export default function FileUploadForm(props) {

  const [files, setFiles] = useState([]);

  useEffect(()=>{
    if ('resetFile' in props && props.resetFile){
      setFiles([])
    }
  } , [props.resetFile])
  const handleFilesSelected = (e) => {
                
    
    if (e.every(item => files.includes(item))){
      setFiles(e.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.name === obj.name)
      ))
      props.onFilesSelected(e.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.name === obj.name)
      ))
    }
    else
    {
      Array.prototype.push.apply(e,files)
      setFiles(e.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.name === obj.name)
      ));
      props.onFilesSelected(e.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.name === obj.name)
      ))
    }
  };

  return (
    <div >
      <FileUpload
        value={files}
        onChange= { (e) => {
          handleFilesSelected(e)
         }
        }
        
        multiFile={true}
        leftLabel="or"
        rightLabel="to select files"
        buttonLabel="click here"
        buttonRemoveLabel="Remove all"
        maxFileSize={10}
        maxUploadFiles={0}
        bannerProps={{ elevation: 0, variant: "outlined" }}
        containerProps={{ elevation: 0, variant: "outlined" }}
      />
    </div>
  );
}
