import React, { useEffect, useState } from 'react';
import { ComboBox, OverrideModal } from '../../components'
import Stack from '@mui/material/Stack';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from 'react-redux';
import { initializeComboBoxDataStatus, setComboOptionStatus, resetPageStatus } from "../../store/combobox/comboBoxStatusSlice";
import { setState } from '../../store/loadstate/loadStateSlice';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';
import HoldButton from '../../components/holdbutton/HoldButton';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';

import StatusAggregationBar from '../../components/status/StatusAggregationBar';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { ErrorOutline } from '@material-ui/icons';

import Stale from '../../utils/Stale';

/**
 * The Status Page. Contains the current status of each test for a specified part
 */
export default function StatusBoardPage() {

  //The filter options. Live is disregarded (used in the chart to have transparency)
  const filterOptions = {
    Completed: 'rgba(0, 128, 0, 1)',
    Incomplete: 'rgba(128, 128, 128, 1)',
    Fail: 'rgba(255, 0, 0, 1)',
    Stale: 'rgba(235, 152, 42, 1)',
    Live: 'rgba(255, 255, 255, 0)'
  }

  /**
   * Gets the query string and converts it to object form
   * @returns the query string in object form
   */
  const useQuery = () => {
    return React.useMemo(() => {
      const params = new URLSearchParams(window.location.search);
      const queryObject = {};

      params.forEach((value, key) => {
        queryObject[key] = value;
      });

      return queryObject;
    }, []);
  };

  const queryObject = useQuery();

  const navigate = useNavigate()

  const dispatch = useDispatch();
  const data = useSelector(state => state.comboboxstatus.value)
  const isPopstate = useSelector(state => state.loadstate.value)
  const [sid, setSid] = React.useState();
  const [ref_id, setRefID] = React.useState();
  const [selectedField, setSelectedField] = React.useState();
  const [serial_number, setSerialNumber] = React.useState();
  const [contextMenu, setContextMenu] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [statusUpdated, handleStatusUpdate] = React.useState(false)
  const [gridData, setGridData] = React.useState([]);
  const [rows, setRows] = React.useState([])
  const [referenceData, setReferenceData] = React.useState({})
  const [loadingStatus, setLoadingStatus] = React.useState(false)
  const [updateFetch, setUpdateFetch] = useState(0)
  const [columns, setColumns] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    if (queryObject.filter) {
      const queryFilters = queryObject.filter.split(',');
      const filters = queryFilters.map((filter) => (Object.keys(filterOptions).some((f) => f.toLowerCase() === filter.toLowerCase()) && filter)).filter((filter) => filter);
      if (filters.length > 0) {
        if (filters.length === 1 && filters[0].toLowerCase() === 'stale') {
          return Object.keys(filterOptions)
        }
        return filters;
      }
    }
    return Object.keys(filterOptions).filter((option) => option !== 'Stale')
  });
  const [usePercents, setUsePercents] = useState(false)

  const handleApiError = ApiError()

  /**
   * Reduces the grid data to a simple object:
   * {
   *    Completed: {
   *      value: value,
   *      color: color
   *    },
   *    Incomplete: {
   *      value: value,
   *      color: color
   *    },
   *    Fail: {
   *      value: value,
   *      color: color
   *    }
   * }
   */
  const statusTotals = gridData.reduce((acc, item) => {
    if (item.status === "Completed") {
      acc.Completed.value++;
    } else if (item.status === "Incomplete") {
      acc.Incomplete.value++;
    } else {
      acc.Fail.value++;
    }
    return acc;
  }, {
    Completed: { value: 0, color: filterOptions.Completed },
    Incomplete: { value: 0, color: filterOptions.Incomplete },
    Fail: { value: 0, color: filterOptions.Fail }
  })

  /**
 * Reduces the grid data to a simple object:
 * {
 *    Stale: {
 *      value: value,
 *      color: color
 *    },
 *    Live: {
 *      value: value,
 *      color: color
 *    }
 * }
 */
  const staleTotals = gridData.reduce((acc, item) => {
    if (item.stale) {
      acc.Stale.value++;
    } else {
      acc.Live.value++;
    }
    return acc;
  }, {
    Stale: { value: 0, color: filterOptions.Stale },
    Live: { value: 0, color: filterOptions.Live }
  })

  /**
   * Closes the context menu
   */
  function closeMenu() {
    setContextMenu(false);
  }

  /**
   * handles the close of the menu
   */
  const handleClose = () => {
    setContextMenu(null);
    setOpen(false);
  };

  /**
   * Gets the icon given a key
   * @param {String} key the value key for the icon 
   * @param {Object} sx any extra styling
   * @returns the associated icon
   */
  const getIcon = (key, sx) => {
    switch (key.toLowerCase()) {
      case "pass":
      case "completed":
        return <CheckIcon sx={sx} />;
      case "fail":
        return <CloseIcon sx={sx} />;
      case "incomplete":
        return <HorizontalRuleIcon sx={sx} />;
      case "stale":
        return <ErrorOutline sx={sx} />;
      default:
        return null;
    }
  };

  /**
   * Handles the opening of the context menu for the correct serial and station test. Ignores if the column is the Serial Number
   * @param {Event} event the event
   */
  const handleContextMenu = (event) => {

    const columnsToIgnore = ["Serial #"]

    const clickedElement = event.target;
    const cellElement = clickedElement.closest('.MuiDataGrid-cell');
    const rowElement = clickedElement.closest('.MuiDataGrid-row');

    if (cellElement) {

      const cellField = cellElement.getAttribute("data-field"); //The column field for the clicked cell
      if (!columnsToIgnore.includes(cellField)) { //Filters against unwanted columns
        event.preventDefault();

        setSelectedField(cellField)

        if (rowElement) {
          const serialCell = Array.from(rowElement.children)
            .find((child) => child.dataset.field === "Serial #") //Gets the serial number for the row
          if (serialCell) {
            const serial = serialCell.querySelector('.MuiButton-label').textContent
            setSerialNumber(serial)
          }
        }

        setSid(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
      }
    }
  };

  /**
   * Updates the query string to add or replace a key with a new value. Replaces last window state so back button works properly
   * @param {String} key 
   * @param {String} value 
   */
  const updateQueryString = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate({
      search: searchParams.toString()
    }, { replace: true });
  };

  /**
   * Determines the status of the serial (Completed || Fail || Incomplete)
   * @param {Object} row the row
   * @param {Array} columnsToCheck the columns to check the result of
   * @returns 
   */
  const getRowStatus = (row, columnsToCheck) => {
    let status = 'Completed';

    if (columnsToCheck.some((col) => row[col] === 'FAIL')) {
      status = 'Fail';
    } else if (columnsToCheck.some((col) => row[col] === 'INCOMPLETE')) {
      status = 'Incomplete';
    }

    return status;
  }

  /**
   * Fetches to get the statuses for each part number. Calculates the overall status (Completed || Incomplete || Fail) and whether it's stale
   */
  const fetchStatuses = async () => {
    try {
      setLoadingStatus(true)

      const response = await ApiManager.status_getStatusDetail(data.option);
      const d = await handleApiError(response);
      setLoadingStatus(false)

      const columnsToCheck = d["grid_data"].length > 0 ? Object.keys(d["grid_data"][0]).filter((col) => !["Serial #", 'id'].includes(col)) : []
      setGridData(d["grid_data"].map((row) => {
        const status = getRowStatus(row, columnsToCheck);
        console.log(row.lastSeen)
        return { ...row, status, stale: status !== 'Completed' && Stale.isStale(row.lastSeen) }
      }))
      setReferenceData(d['data_reference'])
    } catch (error) {
      console.log('hereee', error);
    }
  }

  /**
   * Filters the data given the selected fields
   * @param {Array} data the complete data for the part
   * @param {Array} selectedFilters the selected filters in array form ['Completed', 'Incomplete', 'Fail', 'Stale']
   * @returns 
   */
  const filterData = (data, selectedFilters) => {
    const isFilteringStale = selectedFilters.includes('Stale');

    return data.filter((d) => {
      if ((isFilteringStale && d.stale) || !isFilteringStale) {
        if (selectedFilters.some((f) => d.status.toLowerCase() === f.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

  /**
   * When the status updates, refetch data
   */
  useEffect(
    () => {
      if (statusUpdated === true) {
        fetchStatuses()
        handleStatusUpdate(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusUpdated])

  /**
   * When the selected field changes and the serial number isn't undefined, update the RefID
   */
  useEffect(() => {
    if (serial_number !== undefined) {
      setRefID(referenceData[serial_number][selectedField]['ref_id'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  /**
   * Handles the pop state
   */
  useEffect(() => {
    const handlePopstate = () => {
      dispatch(setState(true));
    };

    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /**
   * Adds a before unload event to reset the page's status
   */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isPopstate) {
        dispatch(resetPageStatus())
      }

      dispatch(setState(false));
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /**
   * Creates the columns given the dataset
   */
  useEffect(() => {
    if (gridData.length > 0) {
      // Assuming all objects in gridData have the same keys
      const newColumns = Object.keys(gridData[0])
        .filter(key => !["id", "status", 'stale', 'lastSeen'].includes(key))  // filter out id, status, stale and lastSeen
        .map(key => {
          const column = {
            field: key,
            headerName: key,
            hideable: key !== 'Serial #',
            flex: (key === 'Serial #' ? Math.max(Object.keys(gridData[0]).length / 10, 1) : 1) / Object.keys(gridData[0]).length,
            cellClassName: (params) => {
              return "super-app";
            },
            valueGetter: (params) => params.value,
            renderCell: (params) => {
              if (key !== 'id' && key !== "Serial #") {
                const bg = (() => {
                  switch (params.formattedValue) {
                    case 'INCOMPLETE':
                      return "gray";
                    case 'FAIL':
                      return 'red';
                    case 'PASS':
                      return 'green';
                    default:
                      return 'inherit';
                  }
                })();
                const color = (() => {
                  switch (params.formattedValue) {
                    case 'INCOMPLETE':
                      return "white";
                    case 'FAIL':
                      return 'white';
                    case 'PASS':
                      return 'white';
                    default:
                      return 'inherit';
                  }
                })();
                return (
                  <div
                    className={`status-cell status-${params.value.toLowerCase()}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '1%',
                      margin: 'calc(0.5% + 8px) 0.5%',
                      background: bg,
                      color: color,
                    }}
                  >
                    {getIcon(params.value)}
                  </div>
                );
              }
              // If the field is 'Part_Number', use the first renderCell function
              if (key === 'Part_Number') {
                const url = `${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${params.value}`;
                return (
                  <HoldButton url={url} params={params} textAlign={"center"} color={"#2d5ca9"} fontSize={params.row.fontSize} />
                );
              }
              // If the field is 'Serial_Number', use the second renderCell function
              else if (key === 'Serial #') {
                const url = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${data.option}?sid=${params.id}`;
                return (
                  <HoldButton url={url} params={params} textAlign={"center"} color={"#2d5ca9"}>
                    <div style={{ color: '#eb982a', position: 'absolute', left: '8px', height: '100%', display: 'flex', alignItems: 'center' }}>
                      {params.row.stale && <ErrorOutline fontSize='large' />}
                    </div>
                    {params.value}
                  </HoldButton>
                );
              }
            }
          };
          return column;
        });

      setColumns(newColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  /**
   * gets the list of parts and adds them to the drop down selector
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await ApiManager.part_getPartList();
        const responseData = await handleApiError(response);
        if (!('error' in responseData))
          dispatch(initializeComboBoxDataStatus(responseData))
      } catch (responseData) {
        console.log('hereee', responseData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * fetches the statuses on the selected part number changing
   */
  useEffect(() => {
    if (data.option.includes("<svg") || data.option === '') {
      dispatch(setComboOptionStatus(""))
      return
    }
    fetchStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.option])

  /**
   * When the selected filters change, update the query string
   */
  useEffect(() => {
    updateQueryString('filter', selectedFilters.join(','))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])


  /**
   * Updates the rows based on the filter data and the complete part data
   */
  useEffect(() => {
    const newRows = filterData(gridData, selectedFilters);
    setRows([...newRows])
    setUpdateFetch(i => i + 1)
  }, [gridData, selectedFilters])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>

        <ComboBox label={"Part"} fill={[]} data={data} />
        <div style={{ width: '90vw', margin: '8px 0', display: 'flex', justifyContent: 'center', placeItems: 'center' }}>
          <StatusAggregationBar
            statuses={statusTotals}
            stales={staleTotals}
            isLoading={loadingStatus}
          />
        </div>
        <div style={{ display: 'flex', margin: '4px 0' }}>
          <button style={{
            padding: '8px 16px',
            margin: '0 8px',
            backgroundColor: '#202020',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            transition: 'all ease-in-out 300ms',
            cursor: 'pointer',
          }}
            onClick={() => setUsePercents(i => !i)}
          >
            {usePercents ? "½" : "%"}
          </button>
          {Object.keys(filterOptions).filter((key) => key !== 'Live').map((filterOption) => {

            const isIncluded = (selectedFilters.length > 0 && selectedFilters.some((f) => f.toLowerCase() === filterOption.toLowerCase()));
            const value = staleTotals[filterOption] !== undefined ? staleTotals[filterOption].value : statusTotals[filterOption].value;

            return (
              <button
                style={{
                  padding: '8px 16px',
                  margin: '0 8px',
                  backgroundColor: !isIncluded ? 'white' : filterOptions[filterOption],
                  color: !isIncluded ? filterOptions[filterOption] : 'white',
                  border: 'none',
                  boxShadow: `0 0 0 2px ${filterOptions[filterOption]} inset`,
                  borderRadius: '4px',
                  transition: 'all ease-in-out 300ms',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  placeItems: 'center'
                }}
                onClick={() => {
                  const newFilters = isIncluded
                    ? selectedFilters.filter((f) => f.toLowerCase() !== filterOption.toLowerCase())
                    : [...selectedFilters, filterOption];

                  setSelectedFilters([...newFilters]);
                }}
              >
                {getIcon(filterOption, { fontSize: '1rem' })}<span style={{ marginLeft: '.25rem' }}>{filterOption}{filterOption === "Stale" && " Only"}{gridData.length > 0 && ` - ${usePercents ? (value / gridData.length * 100).toPrecision(3) + '%' : value}`}</span>
              </button>
            )
          })}
        </div>
        <Typography variant='body'>Total: {loadingStatus ? <CircularProgress size={'1rem'} /> : gridData.length}</Typography>
        <div style={{ width: '90vw', overflowX: 'auto' }}>
          <Table
            componentsProps={{
              row: {
                onContextMenu: (event) => handleContextMenu(event),
                style: { cursor: "context-menu" }
              }
            }}
            key={((gridData && gridData.length > 0) ? columns : []).length}
            columns={(gridData && gridData.length > 0) ? columns : []}
            fetchData={() => {
              return { result: rows, size: rows.length }
            }}
            sx={{ height: '700px', width: `max(${columns.length * 200 + 200}px, 100%)` }}
            id={"EquipmentLogs"}
            onCellClick={() => { }}
            doFetchIndex={updateFetch}
            paginationMode={"client"}
            loading={loadingStatus}
          />
        </div>
        {gridData && typeof gridData === "object" && Object.keys(gridData).length > 0 &&
          (
            <Stack spacing={2} sx={{ height: '100%', width: '90vw' }}>
              <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
                componentsProps={{
                  root: {
                    onContextMenu: (e) => {
                      e.preventDefault();
                      handleClose();
                    }
                  }
                }}
              >
                <MenuItem onClick={(e) => {
                  e.preventDefault()
                  closeMenu()
                  setOpen(true)
                }}>Override</MenuItem>
                {selectedField && ref_id
                  && ref_id !== null
                  && <MenuItem><Link style={{ textDecoration: 'none', color: 'black' }} to={`${process.env.REACT_APP_LOCAL_ROUTE}/log/${data.option}?sid=${sid}&refid=${ref_id}`}>Test Data</Link></MenuItem>}
              </Menu>
            </Stack>
          )
        }
        <OverrideModal open={open} handleClose={handleClose} serial_number={serial_number} sid={sid} station_name={selectedField} process_id={null} handleStatusUpdate={handleStatusUpdate} />
      </div>
    </>
  );
}
