import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Autocomplete from '@mui/material/Autocomplete';
import ApiManager from '../../api_utils/ApiManager';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: '#f0f8ff',
}));

const BlueButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1e90ff',
  '&:hover': {
    backgroundColor: '#4169e1',
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: 'darkred',
  },
}));

export default function MyForm({ setSerialData, test_name, stationName , handleApiError}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [partNumber, setPartNumber] = useState([]);
  const [serialNumber, setSerialNumber] = useState([]);
  const [software, setSoftwarew] = useState([]);

  const [partNumberOptions, setPartNumberOptions] = useState([]);
  const [serialNumberOptions, setSerialNumberOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);

  const [FTP, setFTP] = useState(true);
  const [RTP, setRTP] = useState(true);
  const [fail, setFail] = useState(true);

  const [lasttest, setLasttest] = useState(true);

  // const [options, setOptions] = useState([]);

  useEffect(() => {
    const obj = {
           test_name: test_name,
           station_name:stationName,
      };
    ( async ()=>{
      const d = await ApiManager.boxplot_postDataStudio(obj)
      const data = await handleApiError(d)
      const optionListS = data.serialOptions.map(option => ({
      label: option.name,
      value: option.name,
      }));


      setSerialNumberOptions(optionListS)
      const optionListP = data.partOptions.map(option => ({
      label: option.name,
      value: option.name,
      }));
      setPartNumberOptions(optionListP)

      const optionListSO = data.softwareOptions.map(option => ({
      label: option.software_version,
      value: option.software_version,
      }));
      setSoftwareOptions(optionListSO)


    })();
    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/getbpdatastudio`, {
    //   method: "POST",
    //   credentials: 'include',

    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     test_name: test_name,
    //     station_name:stationName,
    //   })
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     const optionListS = data.serialOptions.map(option => ({
    //       label: option.name,
    //       value: option.name,
    //     }));


    //     setSerialNumberOptions(optionListS)
    //     const optionListP = data.partOptions.map(option => ({
    //       label: option.name,
    //       value: option.name,
    //     }));
    //     setPartNumberOptions(optionListP)

    //     const optionListSO = data.softwareOptions.map(option => ({
    //       label: option.software_version,
    //       value: option.software_version,
    //     }));
    //     setSoftwareOptions(optionListSO)

    //   })
    //   .catch(error => console.error(error));


  }, [test_name]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const resetDefault = async () => {
    
    

    const b = {
      start_time: null,
      end_time:  null,

      part_number: partNumber,
      test_name: test_name,
      serial_number: "",
      software_version: "",
      station_name:stationName,
      FTP: true,
      RTP: true,
      fail: true, 
      last_test_only: true
    };
    const d = await ApiManager.boxplot_postTestByName(b)
    const data = await handleApiError(d)
    setSerialData(data)

    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/gettestbytestname`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setSerialData(data)


    //   })
    //   .catch(error => {
    //     console.error("Error:", error);

    //   });;


  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    

    const b = {
      start_time: startDate ? startDate.valueOf() : null,
      end_time: endDate ? endDate.valueOf() : null,

      part_number: partNumber,
      test_name: test_name,
      serial_number: serialNumber,
      software_version: software,
      station_name:stationName,
      FTP: FTP,
      RTP: RTP,
      fail: fail, 
      last_test_only: lasttest
    };

    const d = await ApiManager.boxplot_postTestByName(b)
    const data = await handleApiError(d)
      setSerialData(data)

    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/gettestbytestname`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setSerialData(data)


    //   })
    //   .catch(error => {
    //     console.error("Error:", error);

    //   });
  };

  const handleChangeTest = (e) => {
    if (e.target.value === "FIRSTTIMEPASS") {
      setFTP(e.target.checked)
    }
    if (e.target.value === "RETESTPASS") {
      setRTP(e.target.checked)
    }
    if (e.target.value === "FAIL") {
      setFail(e.target.checked)
    }

  };


  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3} direction="row">
            <DateTimePicker
              label="Start Date&Time"
              value={startDate}
              onChange={handleStartDateChange}

              renderInput={(params) => <TextField {...params} />}
            />
            <DateTimePicker
              value={endDate}
              label="End Date&Time picker"
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>

        </LocalizationProvider>

        <FormControl sx={{ width: '30%' }}>
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo"
            options={partNumberOptions.filter((option) => !partNumber.includes(option))}
            getOptionLabel={(option) => option.label}
            value={partNumber}
            onChange={(event, newValue) => {
              setPartNumber(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Part Number" />}
          />
        </FormControl>
        <FormControl sx={{ width: '30%' }}>
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo3"
            options={serialNumberOptions.filter((option) => !serialNumber.includes(option))}

            getOptionLabel={(option) => option.label}
            value={serialNumber}
            onChange={(event, newValue) => {
              setSerialNumber(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Serial Number" />}
          />
        </FormControl>
        <FormControl sx={{ width: '15%' }}>
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo3"
            options={softwareOptions.filter((option) => !software.includes(option))}

            getOptionLabel={(option) => option.label}
            value={software}
            onChange={(event, newValue) => {
              setSoftwarew(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Software Version" />}
          />
        </FormControl>
        <FormControl sx={{ width: '8%' }} onChange={handleChangeTest}>
          <Stack direction="row" sx={{ width: '100%' }} spacing={1}>
            <FormControlLabel
              value="FIRSTTIMEPASS"
              control={
                <Checkbox
                  labelid="FIRSTTIMEPASS-label"
                  defaultChecked
                  sx={{
                    color: 'green',
                    '&.Mui-checked': {
                      color: 'green', display: 'flex', justifyContent: 'left', alignItems: 'left'
                    },
                  }}
                />
              }
              label="FIRSTTIMEPASS"
              sx={{ flexGrow: 1 }}
            />
            <FormControlLabel
              value="RETESTPASS"
              control={
                <Checkbox
                  defaultChecked
                  onChange={handleChangeTest}
                  labelid="RETESTPASS-label"
                  sx={{
                    color: '#c3c906',
                    '&.Mui-checked': {
                      color: '#c3c906', display: 'flex', justifyContent: 'left', alignItems: 'left'
                    },
                  }}
                />
              }
              label="RETESTPASS"
              sx={{ flexGrow: 1 }}
            />
            <FormControlLabel
              value="FAIL"

              control={
                <Checkbox
                  defaultChecked
                  onChange={handleChangeTest}
                  labelid="FAIL-label"
                  sx={{
                    color: 'red',
                    '&.Mui-checked': {
                      color: 'red', display: 'flex', justifyContent: 'left', alignItems: 'left'
                    },
                  }}
                />
              }
              label="FAIL"
              sx={{ flexGrow: 1 }}
            />
          </Stack>

        </FormControl>
        <FormControl sx={{ width: '15%' }} >
        <FormControlLabel
              value="Last Tests Only"
              control={
                <Checkbox
                  labelid="LTO-label"
                  defaultChecked
                  onChange={(value) => {
                    setLasttest(value.target.checked)
                  }}
                  sx={{
                    color: 'blue',
                    '&.Mui-checked': {
                      color: 'blue', display: 'flex', justifyContent: 'left', alignItems: 'left'
                    },
                  }}
                />
              }
              label="Last Tests Only"
              sx={{ flexGrow: 1 }}
            />

        </FormControl>
        <RedButton variant="contained"  onClick={()=>{
          resetDefault();
          
          }}>
          Reset To Default
        </RedButton>
        <BlueButton variant="contained" type="submit">
          Search
        </BlueButton>

      </FormContainer>
    </form>

  );
}