import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    value : {
        payload : ['ER981-001040-001', 'ER981-001032-001'],
        option : "" ,
        start_time : null ,
        end_time : null
          }
}
const comboBoxSlice = createSlice({
    name: 'combobox',
    initialState,
    reducers: {
      initializeComboBoxData: (state, action) => {
        state.value.payload = action.payload; // Directly modify the existing state
      },
      setComboOption: (state, action) => {
        state.value.option = action.payload; // Directly modify the existing state
      },
      setStartTimeOption: (state, action) => {
        state.value.start_time = action.payload; // Directly modify the existing state
      },
      setEndTimeOption: (state, action) => {
        state.value.end_time = action.payload; // Directly modify the existing state
      },
      resetPage: (state, action) => {
        state.value.option = ""
        state.value.start_time = null;
        state.value.end_time = null; // Directly modify the existing state
      },
    }
  });
  

export const { initializeComboBoxData , setComboOption , setStartTimeOption , setEndTimeOption, resetPage } = comboBoxSlice.actions;
export default comboBoxSlice.reducer;
