import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState  = {
    value : {
        start_time : dayjs().subtract(12, 'month').valueOf() ,
        end_time :  dayjs().valueOf()
          }
}
console.log("initialState", initialState)
const partSlice = createSlice({
    name: 'part',
    initialState,
    reducers: {
        initializePart : (state, action) => {
            let start = dayjs().subtract(12, 'month').valueOf()
            let end = dayjs().valueOf()
            state.value.start_time = start
            state.value.end_time = end
            console.log("sssssss" , start, end)
        },
      setStartTimeOption: (state, action) => {
         state.value.start_time = action.payload; // Directly modify the existing state
      },
      
      setEndTimeOption: (state, action) => {
         state.value.end_time = action.payload; // Directly modify the existing state
      }
    }
  });
  

export const {  setStartTimeOption , setEndTimeOption , initializePart } = partSlice.actions;
export default partSlice.reducer;
