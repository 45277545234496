import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIRichTextEditor from 'mui-rte'
import { FileUploadForm } from '../../components'
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

import { convertToRaw } from 'draft-js'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ApiManager from '../../api_utils/ApiManager';
import { IconButton } from '@mui/material';
const style = {
    position: 'absolute',
    borderRadius: '8px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1100px',
    height: "500px",
    bgcolor: 'white', /* For browsers that do not support gradients */
    p: 20,
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: 0, }


  };

function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ? hours : 12;
    const formattedDate = `${monthNames[monthIndex]} ${day}, ${year} ${hours}:${minutes.toString().padStart(2, '0')}${ampm}`;
    return formattedDate;
  }

export default function DisplayDataRequestModal ({displayDataRequest, setOpenRequest, setDataRequest , openRequest, handleApiError}) {
    const [isLoadingComments, setisLoadingComments] = useState(false);
    const [isSent, setisSet] = useState(false)

    const [emptyRichText, setemptyRichText] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [richText, setRichText] = useState('')
    const [isError, setisError] = useState(false);
    const [openFormComments, setOpenFormComments] = useState(false)
    const [defaultRichText, setDefaultRichText] = useState('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')
    const [author, setAuthor] = useState("")
  
    const [resetFile, setResetFile] = useState(false)
    const handleCloseRequest = () => {
        setOpenRequest(false)
      }

      React.useEffect(() => {
          // Add your useEffect code here
          console.log(displayDataRequest)
      }, [displayDataRequest])
      const handleFilesSelected = (selectedFiles) => {
        setSelectedFiles(selectedFiles)
      };
      const handleComments = async (e) => {
        e.preventDefault();
    
    
        if (e.target.author.value === '' || emptyRichText) {
          setisError(true)
          return
        }
        setResetFile(false)
        setisError(false)
        setisLoadingComments(true)
        let formData = new FormData();
        formData.append('author', e.target.author.value);
        formData.append('content', richText);
        formData.append('request_id', displayDataRequest['request_id'].split("_")[1]);
        formData.append('serial_number', displayDataRequest['serial_number']);
    
        if (selectedFiles.length === 0) {
          formData.append('upload_files', new File([], 'placeholder'));
        }
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('upload_files', selectedFiles[i]);
        }

        const responseComment = await ApiManager.postaddComment(formData)
        const commentData = await handleApiError(responseComment)
        console.log("comment is created" , commentData)
        if (commentData['message'] === 'Successfully Created Comment') {
          console.log("comment is created sucess" , commentData)
 
          // let url = ''

          let body = {}
          // url = `https://${process.env.REACT_APP_SERVER_ROUTE}/getrequest`
          body = {
            request_id: displayDataRequest['request_id'].replace("REQUEST_", ""),
            serial_number: displayDataRequest['serial_number']
          }


          const responseRequest = await ApiManager.postgetRequest(body)
          const requestData = await handleApiError(responseRequest)

          let today = dayjs(requestData.fixed_time)
          console.log("requestData" , requestData)

          requestData.timelapse = parseFloat(today.diff(requestData.request_time, 'hour', true)).toFixed(2)
          requestData['request_id'] = displayDataRequest['request_id']
          requestData['serial_number'] = displayDataRequest['serial_number']

          setDataRequest(requestData)
          console.log("Data" , requestData)
          setRichText(JSON.stringify({ "blocks": [{ "type": "text", "text": "" }], "entityMap": {} }))
          setDefaultRichText(richText)
          setAuthor('')
          setDefaultRichText('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')
          setSelectedFiles([])
          setisLoadingComments(false)
          setisSet(true);
          setResetFile(true)
          setTimeout( async () => {

            setisSet(false);
          }, 2000);

        }
      }

      const requestTheme = createTheme({
        // Set up your custom MUI theme here
        MUIRichTextEditor: {
    
        }
    
      });
      Object.assign(requestTheme, {
        overrides: {
          MUIRichTextEditor: {
            root: {
              width: "100%",
              height: '100%',
              maxHeight: '100%'
            },
            anchorLink: {
              color: '#FFEB3B',
              textDecoration: 'underline',
    
            },
          }
        }
      })
    
      const commentTheme = createTheme({
        // Set up your custom MUI theme here
        MUIRichTextEditor: {
    
        }
    
      });
      Object.assign(commentTheme, {
        overrides: {
          MUIRichTextEditor: {
            root: {
              width: "100%",
              height: '100%',
              maxHeight: "450px"
            },
            editor: {
              height: '100%',
              overflow: 'auto',
              maxHeight: '350px',
              padding: '20px'
            },
            placeHolder: {
              padding: '20px',
              width: 'inherit',
              position: 'static',
              height: '100%',
              maxHeight: '100%',
              overflow: 'auto'
    
            },
            anchorLink: {
              color: '#FFEB3B',
              textDecoration: 'underline',
    
            },
          }
        }
      })
    
    return (
        <div>
                        <Snackbar open={isLoadingComments} autoHideDuration={10000} >
        <MuiAlert severity="info" elevation={6} variant="filled" sx={{ width: '350px', fontSize: '25px' }}>
          Comment Pending!
        </MuiAlert>
      </Snackbar>

      <Snackbar open={isSent} autoHideDuration={2000} >
        <MuiAlert onClose={() => setisSet(false)} severity="success" elevation={6} variant="filled" sx={{ width: '350px', fontSize: '25px' }}>
          Comment Added!
        </MuiAlert>
      </Snackbar>
        <Modal
          open={openRequest}
          onClose={handleCloseRequest}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style}>

            <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>

              <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#2D5CA9", borderTopRightRadius: '8px' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                  {displayDataRequest.subject}
                </Typography>
                <IconButton onClick={handleCloseRequest} sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}><ClearIcon /></IconButton>
                {/* <button id='cancel' className="cancel-style" style={{ marginRight: '20px' }} onClick={handleCloseRequest}> <ClearIcon /> </button> */}
              </div>



              {displayDataRequest.hasOwnProperty('requester') &&
                <div className="modal-style" style={{ border: "solid 1px black" }}>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', marginLeft: '100px' }} variant="h6" component="h2" >
                    Name : {displayDataRequest.requester} {displayDataRequest.author}
                  </Typography>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', float: 'right', marginRight: '100px' }} variant="h6" component="h2" >
                    Time : {displayDataRequest.request_time && new Date(displayDataRequest.request_time).toLocaleString()} {displayDataRequest.note_timestamp && new Date(displayDataRequest.note_timestamp).toLocaleString()}
                  </Typography>
                  <div fontWeight={"bold"}>
                    <div style={{ height: "auto", minHeight: '100px', marginLeft: '25px' }}>
                      {displayDataRequest.details &&
                        <ThemeProvider theme={requestTheme}>
                          <MUIRichTextEditor
                            controls={["my-style"]}
                            customControls={['']}
                            defaultValue={displayDataRequest.details}
                            readOnly={true} /></ThemeProvider>
                      }
                      {displayDataRequest.task &&
                        <ThemeProvider theme={requestTheme}>
                          <MUIRichTextEditor
                            controls={["my-style"]}
                            customControls={['']}
                            defaultValue={displayDataRequest.task}
                            readOnly={true}
                          />
                        </ThemeProvider>
                      }

                      {
                        Object.keys(JSON.parse(displayDataRequest.request_files.body)).map((key, index) => (
                          <p key={key + index}>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.request_files.body)[key]['url2']} rel="noopener noreferrer" target="_blank"> <CloudDownloadIcon />  </a>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.request_files.body)[key]['url1']} rel="noopener noreferrer" target="_blank"> <VisibilityIcon />  </a>
                            {key}
                          </p>
                        ))
                      }

                    </div>
                  </div>


                </div>

              }
              {displayDataRequest.hasOwnProperty('author') &&
                <div className="modal-style" style={{ height: '90%' }}>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', marginLeft: '100px' }} variant="h6" component="h2" >
                    Name : {displayDataRequest.requester} {displayDataRequest.author}
                  </Typography>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', float: 'right', marginRight: '100px' }} variant="h6" component="h2" >
                    Time : {displayDataRequest.note_timestamp && new Date(displayDataRequest.note_timestamp).toLocaleString()}
                  </Typography>
                  <div fontWeight={"bold"}>
                    <div style={{ minHeight: "200px", marginLeft: '25px' }}>
                      {displayDataRequest.details &&
                        <ThemeProvider theme={requestTheme}>
                          <MUIRichTextEditor
                            controls={["my-style"]}
                            customControls={['']}
                            defaultValue={displayDataRequest.details}
                            readOnly={true} /></ThemeProvider>
                      }
                      {displayDataRequest.task && <MUIRichTextEditor
                        controls={["my-style"]}
                        customControls={[
                          ''
                        ]}
                        defaultValue={displayDataRequest.task}
                        readOnly={true}
                      />
                      }

                      {
                        Object.keys(JSON.parse(displayDataRequest.note_files.body)).map((key, index) => (
                          <p key={key + index}>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.note_files.body)[key]['url2']} rel="noopener noreferrer" target="_blank"> <CloudDownloadIcon />  </a>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.note_files.body)[key]['url1']} rel="noopener noreferrer" target="_blank"> <VisibilityIcon />  </a>
                            {key}

                          </p>
                        ))
                      }

                    </div>
                  </div>
                </div>
              }
              {displayDataRequest.fixed_by &&

                <div className="modal-style" style={{ borderBottom: "solid 1px black", marginBottom: '25px' }}>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', marginLeft: '100px' }} variant="h6" component="h2">
                    Respondant: {displayDataRequest.fixed_by}
                  </Typography>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', float: 'middle', marginLeft: '300px' }} variant="h6" component="h2">
                    Time lapse: {displayDataRequest.timelapse} hours
                  </Typography>
                  <Typography fontWeight={"bold"} style={{ display: 'inline-block', float: 'right', marginRight: '100px' }} variant="h6" component="h2">
                    Time: {displayDataRequest.fixed_time && new Date(displayDataRequest.fixed_time).toLocaleString()}
                  </Typography>
                  <div>
                    <Typography fontWeight={"bold"} style={{ marginLeft: '10px', marginTop: '20px', }}>Comment: </Typography>
                    <div style={{ height: "auto", minHeight: '100px', marginLeft: '25px' }}>
                      <ThemeProvider theme={requestTheme}>
                        <MUIRichTextEditor
                          controls={["my-style"]}
                          customControls={[
                            ''
                          ]}
                          defaultValue={displayDataRequest.comments}
                          readOnly={true}
                        />
                      </ThemeProvider>

                      {
                        Object.keys(JSON.parse(displayDataRequest.fix_files.body)).map((key, index) => (
                          <p key={key + index}>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.fix_files.body)[key]['url2']} rel="noopener noreferrer" target="_blank"> <CloudDownloadIcon />  </a>
                            <a style={{ textDecoration: 'none' }} href={JSON.parse(displayDataRequest.fix_files.body)[key]['url1']} rel="noopener noreferrer" target="_blank"> <VisibilityIcon />  </a>
                            {key}
                          </p>
                        ))
                      }
                    </div>
                  </div>

                </div>

              }

              {!displayDataRequest.fixed_by && !displayDataRequest.author &&
                <div>
                  <form onSubmit={handleComments}>
                    {isError && <Typography variant="h5" color={'red'}>Error: There must be an author and a comment to send a comment.</Typography>}

                    <div style={{ width: '100%' }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Author"
                        multiline
                        value={author}
                        onChange={(e) => { setAuthor(e.target.value) }}
                        name="author"
                        style={{ width: '200px', margin: '15px' }} // Set the width and add some margin
                        maxRows={20}
                      />
                      <div style={{ height: '100%', border: '1px solid black', margin: '10px' }}>
                        <ThemeProvider theme={commentTheme} key="commentThing">
                          <MUIRichTextEditor label="Start typing comments"
                            defaultValue={defaultRichText}
                            onChange={(e) => {
                              const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
                              rteContent && setRichText(JSON.stringify(rteContent))

                              const contentState = e.getCurrentContent()
                              const hasContent = contentState.hasText()

                              if (!hasContent) {
                                setemptyRichText(true)
                              } else {

                                setemptyRichText(false)
                              }


                            }}
                            onFocus={(e) => {
                              setOpenFormComments(true)
                            }}

                            onBlur={(e) => {

                              emptyRichText && setOpenFormComments(false)


                            }}
                          ></MUIRichTextEditor>
                        </ThemeProvider>
                      </div>
                    </div>


                    {openFormComments &&
                      <div>
                        <FileUploadForm
                          id="outlined-multiline-static"
                          label="uploads"
                          name="uploads"
                          onFilesSelected={handleFilesSelected}
                          resetFile={resetFile}
                        />
                        <div style={{ display: 'flex', padding: '8px' }}>
                          <div style={{ marginLeft: 'auto', marginRight: '8px' }}>
                            <Button
                              variant="contained"
                              color='error'
                              style={{ marginRight: '8px' }}
                              onClick = {(e) => {
                                setRichText(JSON.stringify({ "blocks": [{ "type": "text", "text": "" }], "entityMap": {} }))
                                setDefaultRichText(richText)
                                setOpenFormComments(false)
                                setDefaultRichText('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')
                              }

                              }
                            >
                              Cancel

                            </Button>

                            <Button
                              variant="contained"
                              type="submit"
                              endIcon={<SendIcon />}

                            >
                              Add Comment
                            </Button>
                          </div>
                        </div>
                      </div>
                    }
                  </form>
                </div>
              }
              {!displayDataRequest.author &&
                <div
                  style={{
                    position: '16px', // Add some padding around the TextField
                  }}
                >

                  <div>
                    {displayDataRequest.comment_data && Object.keys(displayDataRequest.comment_data).length > 0 &&
                      <div style={{ textAlign: 'center', marginBottom: '15px', fontSize: '43px' }}>Comments</div>
                    }

                    {
                      Object.keys(displayDataRequest.comment_data).map((key, index) => (
                        <div
                          key={key}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginBottom: '15px',
                          }}
                        >


                          <Avatar sx={{ bgcolor: 'blue', margin: '8px' }}>
                            {displayDataRequest.comment_data[key].author && displayDataRequest.comment_data[key].author.length > 0 && (
                              displayDataRequest.comment_data[key].author.length === 1 ? displayDataRequest.comment_data[key].author.toUpperCase() : displayDataRequest.comment_data[key].author.split(' ').map(n => n[0]).join('').toUpperCase()
                            )}

                          </Avatar>



                          <div style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#e3f2f7', width: "93%" }}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginLeft: '10px', marginRight: '8px', fontWeight: 'bold', fontSize: "25px", }}>{displayDataRequest.comment_data[key].author}</div>
                              <div style={{ marginLeft: '10px', marginRight: '8px', fontSize: "20px", marginTop: '5px' }}>{formatDate(displayDataRequest.comment_data[key].comment_timestamp)}</div>
                            </div>
                            <div className="comment-container"
                              style={{
                                padding: '8px',

                                borderRadius: '8px',
                                Width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: "20px"
                              }}
                            >
                              <ThemeProvider theme={requestTheme}>
                                <MUIRichTextEditor
                                  controls={["my-style"]}
                                  customControls={['']}
                                  defaultValue={displayDataRequest.comment_data[key].content}
                                  readOnly={true}
                                  inheritFontSize={true}
                                  style={{ fontSize: '40px' }}
                                />
                              </ThemeProvider>
                              <pre
                                style={{
                                  margin: 0,
                                  wordWrap: 'break-word',
                                }}
                              >
                              </pre>

                              <div className="files"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                  marginTop: '5px',
                                }}
                              >

                                {
                                  Object.keys(displayDataRequest.comment_data[key].comment_files).map((k, index) => (
                                    <div key={k} style={{ fontSize: '20px' }}>
                                  
                                      <a style={{ textDecoration: 'none', marginRight: '10px' }} href={displayDataRequest.comment_data[key].comment_files[k]['url1']} target="_blank" rel="noopener noreferrer" > <VisibilityIcon style={{ fontSize: '30px' }} />  </a>
                                      <a style={{ textDecoration: 'none', marginRight: '10px' }} href={displayDataRequest.comment_data[key].comment_files[k]['url2']} target="_blank" rel="noopener noreferrer" > <CloudDownloadIcon style={{ fontSize: '30px' }} />  </a>
                                      {k}
                                      {/* <a style={{ textDecoration: 'none' }} href={displayDataRequest.comment_data[key].comment_files[Object.keys(displayDataRequest['comment_data'][0]['comment_files'])[key]]['url1']} target="_blank" rel="noopener noreferrer" > <VisibilityIcon style={{ fontSize: '30px' }} />  </a> */}
                                      {/* {displayDataRequest.comment_data[key].comment_files[index]['name']} */}
                                    </div>



                                  ))}
                              </div>

                            </div>

                          </div>



                        </div>
                      ))}




                  </div>


                </div>
              }


            </div>


          </Box>


        </Modal>
      </div>
    )
}