import React, { useRef, useEffect, useState, useCallback } from 'react';
import StationProgressBarItem from './StationProgressBarItem';
import { CircularProgress, Typography } from '@material-ui/core';

/**
 * A component for the serials progress bar
 * @param {Array[Object]} stationTests the tests 
 * @param {Boolean} isLoading if there is currently a fetch in progress
 * @returns the component
 */
const StationProgressBar = ({ stationTests, isLoading }) => {
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(-1);    
    const [updateScrollbar, setUpdateScrollbar] = useState(0)
    const [enableScrollbar, setEnableScrollbar] = useState(false)
    const [leftCenterPX, setLeftCenterPX] = useState(0)

    const itemRefs = useRef([]);

    useEffect(() => {
        const updateScrollRatio = () => {
            handleScroll();
        };

        updateScrollRatio();
        window.addEventListener('resize', updateScrollRatio);
        return () => window.removeEventListener('resize', updateScrollRatio);
    }, []);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setScrollPosition(scrollLeft / (scrollWidth));
            setScrollWidth(clientWidth / scrollWidth);
            setEnableScrollbar(scrollWidth > clientWidth)
            setLeftCenterPX(Math.max(0, containerRef.current.parentNode.clientWidth / 2 - (stationTests.length / 2 * 350)))
        }
    };

    const handleItemClick = (index) => {
        if (containerRef.current && itemRefs.current[index]) {
            const { offsetLeft } = itemRefs.current[index];
            containerRef.current.scrollTo({
                left: offsetLeft - containerRef.current.clientWidth / 2 + itemRefs.current[index].clientWidth / 2,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        handleScroll()
    }, [updateScrollbar])

    const gridContainerStyle = {
        position: 'absolute',
        left: `${leftCenterPX}px`,
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        whiteSpace: 'nowrap',
        margin: '16px 0px',
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        msOverflowStyle: 'none', // Hide scrollbar for Internet Explorer and Edge,
    };

    const gridColumnStyle = {
        flex: '0 0 350px',
        boxSizing: 'border-box',
    };

    const getColor = (result) => {
        switch (result) {
            case "PASS":
                return '#079307';
            case "FAIL":
                return '#CF3C38';
            case 'INCOMPLETE':
                return '#5D5D5D';
            default:
                return '#5D5D5D'; // Default color for unexpected cases
        }
    };

    const testDisplay = (
        <div style={{ position: 'relative', paddingBottom: '8px', overflowX: 'clip', height: '180px' }}>
            <div style={gridContainerStyle} ref={containerRef} onScroll={handleScroll}>
                {stationTests.map((testInfo, index) => (
                    <div
                        style={gridColumnStyle}
                        key={index}
                        ref={el => itemRefs.current[index] = el}
                        onClick={() => handleItemClick(index)}
                    >
                        <StationProgressBarItem
                            testInfo={testInfo}
                            zIndex={stationTests.length - index}
                            isEndItem={stationTests.length - 1 === index}
                            causeUpdate={() => setUpdateScrollbar(e => e + 1)}
                        />
                    </div>
                ))}
            </div>
            {enableScrollbar && <>
                <div style={{ display: 'flex', position: 'absolute', bottom: '2px', left: 0, height: '10px', width: '100%', opacity: '100%' }}>
                    {stationTests.map((testInfo, index) => (
                        <div
                            key={index}
                            style={{ flex: hoveredItem === index ? 1.5 : 1, margin: '0 4px', height: '100%', borderRadius: '9999px', cursor: 'pointer', transition: 'ease-in-out .3s all', position: 'relative', background: hoveredItem === index ? "white" : getColor(testInfo.status), border: hoveredItem === index ? `4px solid ${getColor(testInfo.status)}` : '4px solid transparent' }}
                            onClick={() => handleItemClick(index)}
                            onMouseEnter={() => setHoveredItem(index)}
                            onMouseLeave={() => setHoveredItem(-1)}
                        >
                            <div style={{ position: 'absolute', width: '100%', height: '100%', borderRadius: '9999px', cursor: 'pointer', transform: 'scaleY(4)' }}
                                onClick={() => handleItemClick(index)}
                                onMouseEnter={() => {
                                    setHoveredItem(index)
                                }}
                                onMouseLeave={() => setHoveredItem(-1)} />
                        </div>
                    ))}
                </div>
                <div style={{ left: `${(scrollPosition) * 100}%`, bottom: "-8px", width: `${scrollWidth * 100}%`, height: '24px', position: 'absolute', background: '#ffffffa0', pointerEvents: 'none' }} />
            </>}
        </div>
    );

    const noTests = (
        <Typography variant='h4' style={{ textAlign: 'center', padding: '16px' }}>
            NO PROCESS ASSIGNED
        </Typography>
    );

    if (!isLoading) {
        if (stationTests && stationTests.length > 0) {
            return testDisplay;
        } else {
            return noTests;
        }
    } else {
        return (
            <div style={{ padding: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                <CircularProgress size={'large'} style={{ scale: '150%' }} />
            </div>
        );
    }
};

export default StationProgressBar;
