import { Button, FormControlLabel, Switch, Typography } from '@mui/material';
import React, { useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeselectIcon from '@mui/icons-material/Deselect';

/**
 * A component that exports data to JSON and CSV.
 * @param {Object} object the object to export
 * @param {Object} keys an object of keys -> alias. {key: "Key String"}
 * @param {String} title the title of the export
 * @param {Boolean} enabledJSON enabled JSON exporting
 * @param {Boolean} enabledCSV enabled CSV exporting
 * @returns the object exporter component
 */
const ObjectExporter = ({ object, keys, title, enableJSON, enableCSV }) => {
    //export options (Which keys should be exported)
    const [enabled, setEnabled] = useState(Object.keys(keys))

    /**
     * Toggles the state of a key
     * @param {String} key 
     */
    const toggleEneabled = (key) => {
        if (enabled.includes(key)) {
            setEnabled([...enabled].filter((k) => k !== key))
        } else {
            setEnabled([...enabled, key])
        }
    }

    /**
     * Gets an object that contains only the enabled keys
     * @returns the object containing only the enabled keys
     */
    const getSelectedobj = () => {
        const obj = {};

        for (let key of enabled) {
            obj[key] = object[key]
        }

        return obj
    }

    /**
     * Downloads the JSON object
     */
    const toJSON = () => {
        const obj = getSelectedobj()

        downloadFile(JSON.stringify(obj, null, 2), 'json')
    }

    /**
     * Downloads the CSV Object
     */
    const toCSV = () => {
        const obj = getSelectedobj();

        /**
         * Flattens an object
         * @param {Object} ob the object to flatten 
         * @returns 
         */
        const flattenObject = (ob) => {
            var toReturn = {};

            for (var i in ob) {
                if (!ob.hasOwnProperty(i)) continue;

                if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                    var flatObject = flattenObject(ob[i]);
                    for (var x in flatObject) {
                        if (!flatObject.hasOwnProperty(x)) continue;

                        toReturn[i + '.' + x] = flatObject[x];
                    }
                } else {
                    toReturn[i] = ob[i];
                }
            }
            return toReturn;
        }


        const flattenedObject = flattenObject(obj);

        /**
         * Rebuilds the object to recreate arrays
         */
        const rebuildObject = (flattenedObject) => {
            const o = {};
            const keysToIndex = {};

            let currIndex = 0;
            for (let key in flattenedObject) {
                const keySegments = key
                    .split(".")
                    .filter(
                        (keySegment) =>
                            !Array.from(keySegment).some((char) => "0123456789".includes(char))
                    );
                const newKey = keySegments.join(".");

                if (newKey in o) {
                    o[newKey].push(flattenedObject[key]);
                } else {
                    o[newKey] = [flattenedObject[key]];
                    keysToIndex[newKey] = currIndex++;
                }
            }

            return o;
        }

        const rebuiltObject = rebuildObject(flattenedObject);

        /**
         * converts to a double array
         */
        const toCSVArray = (o) => {
            const csvArray = [];

            csvArray.push(Object.keys(o));
            let index = 0;
            while (true) {
                const row = [];

                for (let key in o) {
                    row.push(o[key][index]);
                }

                if (row.length == 0 || !row.some((ele) => ele != null)) {
                    break;
                } else {
                    csvArray.push(row);
                    index++;
                }
            }

            return csvArray;
        }


        //Maps the double array to CSV
        const csvFile = toCSVArray(rebuiltObject)
            .map(
                (row) =>
                    row
                        .map((ele) => {
                            if (ele === undefined) return
                            // Ensure ele is a string
                            const strEle = String(ele);

                            // Check if the cleaned element contains any numeric characters
                            const hasNumbers = /[0-9]/.test(strEle);

                            // Return the cleaned element enclosed in quotes if it contains numbers, else return it as-is
                            return hasNumbers ? `"${strEle}"` : strEle;
                        })
                        .join(",") + ","
            )
            .join("\n");

        downloadFile(csvFile, 'csv');
    }

    /**
     * Downloads the file
     * @param {String} fileobj 
     * @param {String} ext 
     */
    const downloadFile = (fileobj, ext) => {
        const fileName = `${title}.${ext}`

        const a = document.createElement("a");
        const file = new Blob([fileobj], { type: 'text/plain' });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography
                variant='h5'
                sx={{
                    color: '#333',
                    marginBottom: 1,
                    letterSpacing: '0.5px',
                    textAlign: 'center',
                    paddingBottom: 1,
                    borderBottom: '2px solid #ddd',
                }}>Export {title}
            </Typography>

            <div style={{ margin: 'auto' }}>
                <Button startIcon={<DoneAllIcon />} onClick={() => setEnabled(Object.keys(keys))}>
                    Select All
                </Button>
                <Button startIcon={<DeselectIcon />} onClick={() => setEnabled([])}>
                    Deselect All
                </Button>
            </div>
            <div style={{ width: '100%', height: 'calc(100%)', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                {Object.keys(keys).map((key, index) => (
                    <FormControlLabel key={index} control={<Switch checked={enabled.includes(key)} onChange={() => toggleEneabled(key)} />} label={keys[key]} />
                ))}
            </div>
            <div style={{ margin: 'auto' }}>
                {enableJSON && <Button startIcon={<FileDownloadIcon />} onClick={toJSON} sx={{ margin: '0 8px' }}>
                    JSON
                </Button>}
                {enableCSV && <Button startIcon={<FileDownloadIcon />} onClick={toCSV} sx={{ margin: '0 8px' }}>
                    CSV
                </Button>}
            </div>
        </div>
    )
}

export default ObjectExporter