import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    value : {
        payload : [],
        option : "" ,

          }
}
const equipmentSlice = createSlice({
    name: 'combobox',
    initialState,
    reducers: {
      initializeComboBoxDataEquipment: (state, action) => {
        state.value.payload = action.payload; 
      },
      setComboBoxEquipment: (state, action) => {
        state.value.payload = action.payload;
      },
      setComboOptionEquipment: (state, action) => {
        state.value.option = action.payload;
      },
      resetPageEquipment: (state, action) => {
        state.value.option = ""

      },
    }
  });
  

export const { initializeComboBoxDataEquipment , setComboOptionEquipment ,  resetPageEquipment , setComboBoxEquipment } = equipmentSlice.actions;
export default equipmentSlice.reducer;
