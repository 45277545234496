import React, { useState } from 'react';
import { Grid, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AddSerialForm, AddPartNumberForm, AddComponentsForm } from '../../components';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';

export default function Intake() {
  const [isSent, setisSet] = useState(false);
  const [serials, setSerials] = useState([]);
  const handleApiError = ApiError();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setisSet(false);
  };

  const handleGenerateSerials = (data) => {
    // Check if `data` contains serial details
    if (data.serial_details) {
      const rowsWithId = data.serial_details.map((item) => ({
        id: item.unit_count,
        ...item
      }));
      console.log(rowsWithId)
      setSerials(rowsWithId);
      setisSet(true);
    } else {
      console.error('Unexpected data format received:', data);
    }
  };
  const columns = [
    { field: 'unit_count', headerName: 'Unit Count', width: 150, flex: 0.125 },
    { field: 'part_name', headerName: 'Part Number', width: 250, flex: 0.2 },
    { field: 'description', headerName: 'Description', width: 150, flex: 0.125 },
    { field: 'serial_name', headerName: 'Serial', width: 200, flex: 0.15 },
    { field: 'cm_name', headerName: 'Contract Manufacturer', width: 180, flex: 0.15 },
    { field: 'serialStatus', headerName: 'Serial Status', width: 150, flex: 0.125 },
    { field: 'creation_date', headerName: 'Creation Date', width: 150, flex: 0.125 }
  ];


  return (
    <div>
      <Snackbar open={isSent} autoHideDuration={1500} onClose={handleClose}>
        <MuiAlert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
          sx={{ width: '350px', fontSize: '25px' }}
        >
          Complete!
        </MuiAlert>
      </Snackbar>
      <Typography variant='h2' align='center'>Intake Forms</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AddPartNumberForm setisSet={setisSet} handleApiError={handleApiError} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddSerialForm setisSet={setisSet} handleApiError={handleApiError} onSerialsGenerated={handleGenerateSerials} />
        </Grid>
        <Grid item xs={12}>
          <AddComponentsForm setisSet={setisSet} handleApiError={handleApiError} />
        </Grid>

        <Table
          columns={columns}
          fetchData={() => ({ result: serials, size: serials.length })}
          fetchIndex={serials}
          doFetchIndex={serials}
          id={"intakePage"}
          paginationMode={"client"}
          location={"tableSettings_intakePage"}
          sx={{ height: 600 }}
        />

      </Grid>
    </div>
  );
}
