

import React, { useEffect } from 'react'
import './template.css'
import { createTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DisplayDataRequestModal } from '../../components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Card from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const columns = [
  {
    field: 'recorded_timestamp',
    headerName: 'Time Stamp',
    flex: 0.125,

    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value)

  },
  {
    field: 'type',
    headerName: 'Action',

    flex: .125
  },
  {
    field: 'activity',
    headerName: 'Logs',
    flex: .75,

    editable: true
  }
];

const columns2 = [
  { field: 'test_timestamp', headerName: 'Timestamp', flex: 0.0625, minWidth: 150 },
  { field: 'test_name', headerName: 'Test', flex: 0.125, minWidth: 250 },
  { field: 'result', headerName: 'Result', flex: 0.125, minWidth: 200 },
  { field: 'comment', headerName: 'Comment', flex: 0.125, minWidth: 250 },
  { field: 'measurement', headerName: 'Measurement', flex: 0.0625, minWidth: 150 },
  { field: 'units', headerName: 'Units', flex: 0.0625, minWidth: 150 },
  { field: 'upperbound', headerName: 'Upper Limit', flex: 0.0625, minWidth: 150 },
  { field: 'lowerbound', headerName: 'Lower Limit', flex: 0.0625, minWidth: 150 },
  { field: 'eridan_upperbound', headerName: 'Eridan Upper Limit', flex: 0.0625, minWidth: 150 },
  { field: 'eridan_lowerbound', headerName: 'Eridan Lower Limit', flex: 0.0625, minWidth: 150 },
  { field: 'machine', headerName: 'Machine', flex: 0.0625, minWidth: 150 },
  { field: 'software_version', headerName: 'Software Version', flex: 0.0625, minWidth: 150 }
];


export default function Template({ serial_name, activity, setLoading, stepClick }) {
  const handleApiError = ApiError()
  const noteTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(noteTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          height: '100%',
          maxHeight: '100%'
        },
        anchorLink: {
          color: '#FFEB3B',
          textDecoration: 'underline',

        },
      }
    }
  })

  const requestTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(requestTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          height: '100%',
          maxHeight: '100%'
        },
        anchorLink: {
          color: '#FFEB3B',
          textDecoration: 'underline',

        },
      }
    }
  })

  const commentTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(commentTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          height: '100%',
          maxHeight: "450px"
        },
        editor: {
          height: '100%',
          overflow: 'auto',
          maxHeight: '350px',
          padding: '20px'
        },
        placeHolder: {
          padding: '20px',
          width: 'inherit',
          position: 'static',
          height: '100%',
          maxHeight: '100%',
          overflow: 'auto'

        },
        anchorLink: {
          color: '#FFEB3B',
          textDecoration: 'underline',

        },
      }
    }
  })
  const [rows, setRows] = React.useState([]);
  const [rows2, setRows2] = React.useState([]);
  const [fetch1, setFetch1] = React.useState(0);
  const [fetch2, setFetch2] = React.useState(0)
  const [testResult, settestResult] = React.useState({});
  const [openTest, setOpenTest] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState(false);
  const [testFiles, setTestFiles] = React.useState({});

  const [displayOpenTest, setDisplayOnTest] = React.useState(false);
  const [displayDataTest, setDataTest] = React.useState({});
  const [displayDataRequest, setDataRequest] = React.useState({});
  React.useEffect(() => {
    // Add your useEffect code here
    console.log(displayDataRequest)
  }, [displayDataRequest])

  useEffect(() => {
    setRows(activity)
    setFetch1(fetch1 + 1)
    console.log("rows", activity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity])

  useEffect(() => {
    console.log('steppp')
    console.log(stepClick)
    if (stepClick !== "") {
      getTest(stepClick)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepClick])
  const handleCloseTest = () => {
    setDisplayOnTest(false)
  }

  const handleTest = async (e) => {
    setLoading(true)
    const obj = {
      test_id: e.row.id
    };

    try {
      console.log('data', obj)

      const d = await ApiManager.log_postLogTestResult(obj)
      console.log('data', d)
      const data = await handleApiError(d)
      settestResult(e.row)
      setDisplayOnTest(true)
      setDataTest(data)
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
    }
  }

  const getTest = async (ref_id) => {
    console.log('refff', ref_id)
    setLoading(true)

    setOpenTest(true)
    setOpenRequest(false)

    const obj = {
      test_id: ref_id,
      serial_number: serial_name
    };
    try {
      const d = await ApiManager.log_postStationTest(obj)
      const data = await handleApiError(d)

      setRows2(data.tests)
      setFetch2(fetch2 + 1)
      setTestFiles(data.test_files)
      console.log(data.test_files, "asdlkaslk")
      setLoading(false)

      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }
    catch (error) {
      setLoading(false)
    }

  }
  const handleEvent = async (e) => {
    setLoading(true)

    if (e.row.type.includes('Request') || e.row.type.includes('Note')) {
      setOpenTest(false)
      let body = {}
      if (e.row.type.includes('Request')) {
        body = {
          request_id: e.row.ref_id.replace("REQUEST_", ""),
          serial_number: serial_name
        }
        const requestReponse = await ApiManager.postgetRequest(body)
        const requestData = await handleApiError(requestReponse)
        if (requestData === "Unauthorized") return

        console.log("requestData", requestData)
        let today = dayjs(requestData.fixed_time)

        requestData.timelapse = parseFloat(today.diff(requestData.request_time, 'hour', true)).toFixed(2)
        requestData['request_id'] = e.row.ref_id
        requestData['serial_number'] = serial_name
        setDataRequest({})
        setDataRequest(requestData)
        setLoading(false)

        setOpenRequest(true)
      }
      else if (e.row.type.includes('Note')) {
        body = {
          note_id: e.row.ref_id.replace("NOTE_", ""),
          serial_number: serial_name
        }

        const noteReponse = await ApiManager.postgetNote(body)
        const noteData = await handleApiError(noteReponse)
        noteData['request_id'] = e.row.ref_id
        noteData['serial_number'] = serial_name
        setDataRequest({})
        setDataRequest(noteData)
        setLoading(false)

        setOpenRequest(true)
      }

    }
    else {
      const ref_id = e.row.ref_id.replace("TEST_", "")
      getTest(ref_id)

    }

  }


  const style = {
    position: 'absolute',
    borderRadius: '25px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1100px',
    height: "500px",
    bgcolor: 'white', /* For browsers that do not support gradients */
    p: 20,
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: 0, }


  };
  return (
    <div>
      <Table
        sx={{
          height: 600
        }}
        fetchData={() => { return { result: rows, size: rows.length } }}
        columns={columns}
        paginationMode={"client"}
        id={"Log1"}
        doFetchIndex={fetch1}
        onCellClick={() => { }}
        getRowClassName={(params) => {
          console.log("GetValue", params.row)
          switch (params.row.type) {
            case "TestFIRSTTIMEPASS":
              return 'TableRow-Pass'
            case "TestRETESTPASS":
              return 'TableRow-Pass'
            case "TestFAIL":
              return 'TableRow-Fail'
            case "RequestClosed":
              return 'TableRow-RequestClosed'
            case "TestERIDANPASS":
              return 'TableRow-EridanPass'
            case "Note":
              return 'TableRow-Note'
            case 'RequestOpen':
              return 'TableRow-RequestOpen'
            default:
              return undefined
          }
        }}
        location={"tableSettings_log_table1"}
        onRowClick={handleEvent}
      />
      {openTest &&
        <div>
          <Card sx={{ maxWidth: "100%", border: 'black 1px solid', margin: '15px', }}>
            <Stack direction="column" spacing={2}>
              <Item style={{ background: "#2D5CA9", color: 'white' }}>
                <div style={{ fontSize: "24px" }}>Test Files:</div>
              </Item>
              {testFiles && Object.keys(testFiles).map((k, index) => (
                <div key={k} style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }}>
                  <a style={{ textDecoration: 'none', marginRight: '10px' }} href={testFiles[k]['url2']} target="_blank" rel="noopener noreferrer">
                    <CloudDownloadIcon style={{ marginLeft: '10px', fontSize: '30px', verticalAlign: 'middle' }} />
                  </a>
                  <a style={{ textDecoration: 'none', marginRight: '10px' }} href={testFiles[k]['url1']} target="_blank" rel="noopener noreferrer">
                    <VisibilityIcon style={{ fontSize: '30px', verticalAlign: 'middle' }} />
                  </a>
                  <span style={{ verticalAlign: 'middle' }}><a href={testFiles[k]['url1']} target="_blank" rel="noopener noreferrer">{k}</a></span>
                </div>
              ))}
              {(!testFiles || Object.keys(testFiles).length === 0) && (
                <div style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  <div>No files  :(</div>
                  <div style={{ marginRight: '10px', fontSize: '30px' }}></div>
                </div>
              )}
            </Stack>
          </Card>

          <Table
            sx={{
              height: 600
            }}
            fetchData={() => { return { result: rows2, size: rows2.length } }}
            columns={columns2}
            paginationMode={"client"}
            id={"Log2"}
            doFetchIndex={fetch2}
            onCellClick={() => { }}
            getRowClassName={(params) => {
              switch (params.row.result) {
                case "FIRSTTIMEPASS":
                  return 'TableRow-Pass'
                case "RETESTPASS":
                  return 'TableRow-Retest'
                case "FAIL":
                  return 'TableRow-Fail'
                default:
                  return undefined
              }
            }}
            location={"tableSettings_log_table2"}
            onRowClick={handleTest}
          />
        </div>
      }
      {openRequest &&
        <> <DisplayDataRequestModal displayDataRequest={displayDataRequest} setOpenRequest={setOpenRequest} setDataRequest={setDataRequest} openRequest={openRequest} handleApiError={handleApiError} /> </>

      }
      {openTest && displayOpenTest &&
        <div>
          <Typography gutterBottom variant="body1">
            <Modal
              open={displayOpenTest}
              onClose={handleCloseTest}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <div style={{ display: 'flex', backgroundColor: "#2D5CA9", borderTopRightRadius: '25px' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                      {testResult.test_name}
                    </Typography>
                    <button id='cancel' className="cancel-style" onClick={handleCloseTest}> <ClearIcon /> </button>
                  </div>
                  {testResult.result.includes("PASS") &&
                    <Typography id="modal-modal-status-pass" variant="h6" component="h2">
                      {testResult.result}

                    </Typography>
                  }
                  {!testResult.result.includes("PASS") &&
                    <Typography id="modal-modal-status-fail" variant="h6" component="h2">
                      {testResult.result}

                    </Typography>
                  }

                  <Stack direction="row" spacing={.3}>
                    <Box id="modal-list-container">
                      <Typography id="modal-list-title" variant="h6" component="h2">
                        Limits
                      </Typography>
                      <Stack spacing={1}>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <span><strong>Upper Limit:</strong></span>
                          <span>{displayDataTest.upperbound}</span>
                        </Typography>

                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <strong>Lower Limit:</strong> {displayDataTest.lowerbound}
                        </Typography>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <strong>Eridan Upper Limit:</strong> {displayDataTest.eridan_upperbound}
                        </Typography>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <strong>Eridan Lower Limit:</strong> {displayDataTest.eridan_lowerbound}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box id="modal-list-container" >
                      <Typography id="modal-list-title" variant="h6" component="h2">
                        Metrics
                      </Typography>
                      <Stack spacing={1}>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <strong>Measurement:</strong> {displayDataTest.measurement}
                        </Typography>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <strong>Units:</strong> {displayDataTest.units}
                        </Typography>

                      </Stack>


                    </Box>
                    <Box id="modal-list-container" >
                      <Typography id="modal-list-title" variant="h6" component="h2">
                        Computer Details
                      </Typography>
                      <Stack spacing={1}>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <span><strong>Software Version:</strong></span>
                          <span>{displayDataTest.software_version}</span>
                        </Typography>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <span><strong>Machine:</strong></span>
                          <span>{displayDataTest.machine}</span>
                        </Typography>
                        <Typography id="modal-list-li" variant="h6" component="h2">
                          <span><strong>User:</strong></span>
                          <span>{displayDataTest.user}</span>
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <div id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: '0', width: '100%', minHeight: '45%' }}>
                    <strong>Comments: </strong>
                    <pre>
                      {displayDataTest.comment}

                    </pre>
                  </div>
                </div>


              </Box>

            </Modal>
          </Typography>
          <Divider variant="middle" />

        </div>

      }

    </div>

  );
}

