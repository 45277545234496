import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

/**
 * A display for a test's information
 * @param {Object} testInfo an object containing the test name and status
 * @param {Number} zIndex the Z index to place the item at
 * @param {Boolean} isEndItem if it's the last element, the arrow isn't rendered
 * @param {Function} causeUpdate a function to cause the parent object to update it's container width
 * @returns the component
 */
const StationProgressBarItem = ({ testInfo, zIndex, isEndItem, causeUpdate }) => {
    /**
     * gets the color of the background given a test result
     * @param {String} result the result of the test 
     * @returns the color associated with the test result
     */
    const getColor = (result) => {
        switch (result) {
            case "PASS":
                return '#079307';
            case "FAIL":
                return '#CF3C38';
            case 'INCOMPLETE':
                return '#5D5D5D';
            default:
                return '#5D5D5D'; // Default color for unexpected cases
        }
    };

    /**
     * Causes an update on object mount
     */
    useEffect(() => causeUpdate(), [])

    return (
        <div style={{ position: 'relative', width: '100%', height: '150px', backgroundColor: getColor(testInfo.status), zIndex, color: 'white', overflowY: 'clip', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!isEndItem && (
                <div style={{ position: 'absolute', top: '5%', right: '-80px', transform: 'scaleX(30%)', height: '100%', aspectRatio: 1 }}>
                    <div style={{ rotate: '45deg', aspectRatio: 1, height: '80%', borderTop: 'solid white 5px', borderRight: 'solid white 5px', background: getColor(testInfo.status) }} />
                </div>
            )}
            <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', left: '16px' }}>
                <Typography variant='h6' style={{ textAlign: 'center', zIndex: 999 }}>
                    {testInfo.name}
                </Typography>
                <Typography variant='subtitle2' style={{ textAlign: 'center', zIndex: 999, fontWeight: '100' }}>
                    {testInfo.status}
                </Typography>
            </div>
        </div>
    );
};

export default StationProgressBarItem;
