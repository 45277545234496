import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import { BoxPlot, MyForm } from '../../components'
import { GridToolbar } from '@mui/x-data-grid';
import Stack from "@mui/material/Stack";
import ApiError from '../../api_utils/ApiError';
import ApiManager from '../../api_utils/ApiManager';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '../../components/table/Table';


const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

export default function BoxPlotBoard() {
  const [boxData, setBox] = useState([])
  const [serials_data, setSerialData] = useState([])
  const [stats_data, setStatData] = useState([])
  const [testname, setTestName] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredSerials, setFilteredSerials] = useState([]);
  const [stationName, setStationName] = useState("")
  const [loadingBoxPlot, setloadingBoxPlot] = useState(false)
  const handleApiError = ApiError()

  useEffect(() => {
    if (serials_data.length === 0) return
    const rowIds = serials_data.map((row) => row.id);
    setSelectedRows(rowIds);
    const newFilteredSerials = serials_data
      .filter((row) => rowIds.includes(row.id))
      .map((row) => row);
    const newBoxData = serials_data.filter((row) => rowIds.includes(row.id)).map((row) => row.measurement);
    setBox(newBoxData);

    setFilteredSerials(newFilteredSerials);
    setStatData([reconstructStatsData(newFilteredSerials)]);
  }, [serials_data]);

  const roundToSigFigs = (num, sigFigs) => {
    if (num === 0) {
      return 0;
    }

    const magnitude = Math.floor(Math.log10(Math.abs(num)));
    const factor = Math.pow(10, sigFigs - magnitude - 1);
    return Math.round(num * factor) / factor;
  };

  const getLimitValue = (serialsData, limitKey, defaultValue) => {
    const values = serialsData.map(row => row[limitKey]);
    const uniqueValues = Array.from(new Set(values));
    return uniqueValues.length === 1 ? uniqueValues[0] : defaultValue;
  };

  const reconstructStatsData = (serialsData) => {
    const limits = ["lowerbound", "upperbound", "eridan_lowerbound", "eridan_upperbound"];
    const mixedLimits = {};
    limits.forEach((limit) => {
      if (limit === "eridan_lowerbound" || limit === "eridan_upperbound") {
        mixedLimits[limit] = serialsData.some((row, i, arr) => i > 0 && (row[limit] !== null && arr[i - 1][limit] !== null && row[limit] !== arr[i - 1][limit])) ? "MIXED LIMITS" : serialsData[0][limit];
      } else {
        mixedLimits[limit] = serialsData.some((row, i, arr) => i > 0 && row[limit] !== arr[i - 1][limit]) ? "MIXED LIMITS" : serialsData[0][limit];
      }
    });

    const eridan_limits_valid = !(
      mixedLimits.eridan_upperbound === "MIXED LIMITS" ||
      mixedLimits.eridan_lowerbound === "MIXED LIMITS"
    );


    const measurements = serialsData.map(row => row.measurement);
    const mean = (measurements.reduce((a, b) => a + b, 0) / measurements.length).toFixed(4);
    const variance = measurements.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / measurements.length;
    const stddev = Math.sqrt(variance).toFixed(4);

    const computeCapability = (limit1, limit2, stddev) => {
      if (limit1 === null || limit2 === null || stddev === 0) {
        return { cpu: null, cpl: null, cpk: null };
      }

      const cpu = (limit1 - mean) / (3 * stddev);
      const cpl = (mean - limit2) / (3 * stddev);
      const cpk = Math.min(cpu, cpl);
      return { cpu, cpl, cpk };
    };

    const result = {
      mean,
      max_measurement: Math.max(...measurements),
      min_measurement: Math.min(...measurements),
      stddev,
      upperlimit: mixedLimits.upperbound,
      lowerlimit: mixedLimits.lowerbound,
      eridan_upperlimit: mixedLimits.eridan_upperbound,
      eridan_lowerlimit: mixedLimits.eridan_lowerbound,
    };

    if (result.upperlimit !== "MIXED LIMITS" && result.lowerlimit !== "MIXED LIMITS") {

      const { cpu, cpl, cpk } = computeCapability(parseFloat(result.upperlimit), parseFloat(result.lowerlimit));
      result.cpu = cpu;
      result.cpl = cpl;
      result.cpk = cpk;
    } else {
      result.cpu = null;
      result.cpl = null;
      result.cpk = null;
    }

    if (result.eridan_upperlimit !== "MIXED LIMITS" && result.eridan_lowerlimit !== "MIXED LIMITS") {
      const { cpu, cpl, cpk } = computeCapability(parseFloat(result.eridan_upperlimit), parseFloat(result.eridan_lowerlimit));
      result.eridan_cpu = cpu;
      result.eridan_cpl = cpl;
      result.eridan_cpk = cpk;
    } else {
      result.eridan_cpu = null;
      result.eridan_cpl = null;
      result.eridan_cpk = null;
    }

    return {
      mean: roundToSigFigs(mean, 4),
      max_measurement: roundToSigFigs(Math.max(...measurements), 4),
      min_measurement: roundToSigFigs(Math.min(...measurements), 4),
      stddev: roundToSigFigs(stddev, 4),
      upperlimit: getLimitValue(serialsData, 'upperbound', 'MIXED LIMITS'),
      lowerlimit: getLimitValue(serialsData, 'lowerbound', 'MIXED LIMITS'),
      cpu: roundToSigFigs((((getLimitValue(serialsData, 'upperbound') - mean) / (3 * stddev)).toFixed(4)), 4),
      cpl: roundToSigFigs((((mean - getLimitValue(serialsData, 'lowerbound')) / (3 * stddev)).toFixed(4)), 4),
      cpk: roundToSigFigs((Math.min(
        (getLimitValue(serialsData, 'upperbound') - mean) / (3 * stddev),
        (mean - getLimitValue(serialsData, 'lowerbound')) / (3 * stddev)
      )).toFixed(4), 4),
      eridan_upperlimit: getLimitValue(serialsData, 'eridan_upperbound', 'MIXED LIMITS'),
      eridan_lowerlimit: getLimitValue(serialsData, 'eridan_lowerbound', 'MIXED LIMITS'),
      eridan_cpu: eridan_limits_valid ? roundToSigFigs((((getLimitValue(serialsData, 'eridan_upperbound') - mean) / (3 * stddev)).toFixed(4)), 4) : null,
      eridan_cpl: eridan_limits_valid ? roundToSigFigs((((mean - getLimitValue(serialsData, 'eridan_lowerbound')) / (3 * stddev)).toFixed(4)), 4) : null,
      eridan_cpk: eridan_limits_valid ? roundToSigFigs((Math.min(
        (getLimitValue(serialsData, 'eridan_upperbound') - mean) / (3 * stddev),
        (mean - getLimitValue(serialsData, 'eridan_lowerbound')) / (3 * stddev)
      )).toFixed(4), 4) : null,
    };
  };






  const handleSelectionModelChange = (newSelectionModel) => {

    if (newSelectionModel.length === 0) {
      setSelectedRows(selectedRows);
      return;
    }

    setSelectedRows(newSelectionModel);
    const newFilteredSerials = serials_data
      .filter((row) => newSelectionModel.includes(row.id))
      .map((row) => row);
    setStatData([reconstructStatsData(newFilteredSerials)]);
    const newBoxData = serials_data.filter((row) => newSelectionModel.includes(row.id)).map((row) => row.measurement);
    setBox(newBoxData);
    setFilteredSerials(newFilteredSerials);

  };

  const columns = [
    { field: 'test_timestamp', headerName: 'Test Time Stamp', flex: 0.125, type: 'dateTime', valueGetter: ({ value }) => value && new Date(value) },
    { field: 'name', headerName: 'Name', flex: 0.125 },
    { field: 'test_name', headerName: 'Test Name', flex: 0.0625, editable: true },
    { field: 'machine', headerName: 'Machine', flex: 0.0625, editable: true },
    { field: 'software_version', headerName: 'Software ver', flex: 0.0625, editable: true },
    { field: 'measurement', headerName: 'Measurement', flex: 0.0625, editable: true },
    { field: 'result', headerName: 'Result', flex: 0.0625 },
    { field: 'units', headerName: 'Units', flex: 0.0625 },
    { field: 'user', headerName: 'User', flex: 0.0625 },
    { field: 'upperbound', headerName: 'Upper Limit', flex: 0.0625 },
    { field: 'lowerbound', headerName: 'Lower Limit', flex: 0.0625 },
    { field: 'eridan_upperbound', headerName: 'Eridan Upper Limit', flex: 0.0625 },
    { field: 'eridan_lowerbound', headerName: 'eridan Lower Limit', flex: 0.0625 },
    { field: 'comment', headerName: 'Comment', flex: 0.0625 }
  ];

  useEffect(() => {
    const queryString = window.location.search;
    let q = queryString.split("?")
    let param = q[1].split("&&")
    let station = param[0].split("=")
    setStationName(station[1])

    let test = param[1].split("test=")
    let part_number = param[2].split("partNum=")
    setTestName(decodeURIComponent(test[1]))
    console.log(station)
    console.log(test)
    console.log(part_number)
    let prep = {
      station: station[1],
      test_name: decodeURIComponent(test[1]),
      part_number: part_number[1]
    };

    (async () => {
      setloadingBoxPlot(true)
      const d = await ApiManager.boxplot_postBoxPlotDetail(prep)
      const data = await handleApiError(d)
      setloadingBoxPlot(false)

      console.log("Data Recieved")
      setBox(data.serials)
      for (let i = 0; i < data.serials_data.length; i++) {
        data.serials_data[i].id = i + 1;
      }
      const rowIds = data.serials_data.map((row) => row.id);
      setSelectedRows(rowIds);
      setSerialData(data.serials_data)
      setFilteredSerials(data.serials_data)
      setStatData(data.stats)

      console.log("Data Processed")
      setLoading(false)

    })();


  }, [])
  return (
    <>
      {loadingBoxPlot && <LinearProgress />}
      <div style={{ position: 'flex' }}>
        <div style={{ fontSize: "35px", marginBlock: '15px' }}>
          {testname}
        </div>
        <Grid container spacing={4}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              {
                Object.keys(stats_data).map((key, index) => (
                  <div key={key}>
                    <Typography gutterBottom variant="h5" component="div">
                      Lower Limit : {stats_data[key].lowerlimit
                      }
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Upper Limit: {stats_data[key].upperlimit
                      }
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Min : {stats_data[key].min_measurement
                      }
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Max: {stats_data[key].max_measurement
                      }
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      CPK : {isFinite(stats_data[key].cpk) ? stats_data[key].cpk : "N/A"}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      CPL : {isFinite(stats_data[key].cpl) ? stats_data[key].cpl : "N/A"}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      CPU : {isFinite(stats_data[key].cpu) ? stats_data[key].cpu : "N/A"}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Eridan Cpk : {isFinite(stats_data[key].eridan_cpk) ? stats_data[key].eridan_cpk : "N/A"}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Eridan CPL : {isFinite(stats_data[key].eridan_cpl) ? stats_data[key].eridan_cpl : "N/A"}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      Eridan CPU : {isFinite(stats_data[key].eridan_cpu) ? stats_data[key].eridan_cpu : "N/A"}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="div">
                      Average Measurement: {stats_data[key].mean}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="div">
                      Standard Deviation : {stats_data[key].stddev
                      }
                    </Typography>

                  </div>
                ))
              }
            </Grid>

          </Grid>
          <Divider orientation="vertical" flexItem>

          </Divider>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>

              {
                !isLoading && <BoxPlot boxData={boxData} serialsData={filteredSerials} stats_data={stats_data} />

              }
              <Grid />
            </Grid>
          </Grid>


        </Grid>
        <br />
        <Divider orientation="horizontal" />

        <Accordion sx={{ margin: '20px 0px' }}>
          <AccordionSummary sx={{
            backgroundColor: '#2d5ca9', borderRadius: '10px', color: 'white', '& .MuiSvgIcon-root': {
              color: 'white'
            },
          }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"

          >
            <Typography>Data Studio</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#eff6ff ', borderRadius: '10px' }}>
            <MyForm setSerialData={setSerialData} test_name={testname} stationName={stationName} handleApiError={handleApiError} />
          </AccordionDetails>

        </Accordion>

        <Table
          checkboxSelection={true}
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
          sx={{
            height: 600
          }}
          fetchData={() => { return { result: serials_data, size: serials_data.length } }}
          columns={columns}
          paginationMode={"client"}
          id={"BoxPlotTable1"}
          doFetchIndex={serials_data}
          onCellClick={() => { }}
          location={"tableSettings_boxPlot"}
        />


      </div>
    </>
  );
}