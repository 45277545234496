import { FormControl, InputLabel, Select } from '@mui/material';
import React from 'react';

/**
 * A simple grouped drop down with state changes
 * 
 * @param {Array} options should be in this format: [{groupName: "Group 1", options: ["Option 1"]}]
 * @returns React Component
 */
const GroupedDropDown = ({ options, title, sx, selection, setSelection }) => {
    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120, ...sx }}>
            <InputLabel htmlFor={title}>{title}</InputLabel>
            <Select
                native
                value={selection}
                onChange={handleChange}
                id={title}
                label={title}
            >
                {options.map((group, index) => (
                    <optgroup key={index} label={group.groupName}>
                        {group.options.map((option, i) => (
                            <option key={i} value={option}>{option}</option>
                        ))}
                    </optgroup>
                ))}
            </Select>
        </FormControl>
    );
};

export default GroupedDropDown;
