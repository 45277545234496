import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
// import {BrowserRouter as Router} from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {Provider} from "react-redux"
import {store, persistor } from './store/store.js';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate

const myTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(myTheme, {
    overrides: {
        MUIRichTextEditor: {
        root: {
            width: "100%",
            height:'450px',
            maxHeight:"450px"
            },
        editor: {
            height:'400px',
            overflow:'auto',
            maxHeight:"400px",
            padding: '20px'
        },
        placeHolder: {
            padding: '20px',
           width: 'inherit',
           position: 'static',
           height: '400px',
           maxHeight:"400px",
           overflow:'auto'

        },
        anchorLink: {
           color: '#FFEB3B',
           textDecoration: 'underline',

        },
    }
}
})
ReactDOM.render(    
    <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

        <ThemeProvider theme={myTheme}>
            {/* <Router> */}
                <App/>
            {/* </Router> */}
        </ThemeProvider>
        </PersistGate>

    </Provider>,
    document.getElementById('root')
)
