import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginFailure, logout } from '../store/auth/authStateSlice';
import { setError, clearError } from '../store/error/errorSlice';

const ApiError = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getCurrentLocation = () => {
        return window.location.pathname;
    };

    const handleApiErrorCode = (error) => {
        console.log('handleApiErrorCode' , error)
        // console.log("Handling API Error Code:", error.status);
        // console.log(currentRoute)
        switch (error.status) {
            case 200:
                console.log(getCurrentLocation())
                return error.json()
            case 400:
                dispatch(setError(error))

                return error.json()
            case 404:
                dispatch(setError(error))

                return "404 Error";
            case 307:
                console.log("redirect", error )
                return error.json()
            case 422:
                dispatch(setError(error))

                console.log("422", error )
                return "Internal Error";
            case 500:
                console.log("erorr internal error" )
                // dispatch(loginFailure(error));
                dispatch(setError(error))

                return "Internal Error";
            case 401:
                dispatch(logout());
                dispatch(loginFailure(error));
                return "Unauthorized";
            default:
                // dispatch(loginFailure(error));
                dispatch(setError(error))

                return "Unknown Error";
        }
    };

    return handleApiErrorCode;
};

export default ApiError;
