import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useRef, useState } from 'react'
import SteppedLineChart from '../graphs/SteppedLineChart'
import { Button, CircularProgress, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import LensIcon from '@mui/icons-material/Lens';
import HoldButton from '../holdbutton/HoldButton';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import { Box, Modal } from '@material-ui/core';

import './LoadingElipses.css'

/**
 * The table containing the build parts' status over time
 * 
 * @param {Boolean} useFractions whether or not to display numbers as fractions or percentages
 * @param {String} selectedBuild a string containing the selected build
 * @param {Object} buildOptions an object containing the parts for each build option
 * @param {Function} setIsLoading a function to let the parent know that loading has been completed
 * @param {String} selectedTimeFrame a string containing the information about the selected time frame
 * @param {Array} displayOptions an array containig the lines to display in the chart
 * @param {*} fetchState used in a useEffect to force a refetch
 */
const PartNumberStatusTable = ({ useFractions, selectedBuild, buildOptions, setIsLoading, selectedTimeFrame, displayOptions, fetchState }) => {
    const handleApiError = ApiError()

    const [prevSelectedTimeFrame, setPrevSelectedTimeFrame] = useState(selectedTimeFrame)
    const timeoutID = useRef(undefined)

    const columns = [
        {
            field: 'partNumber', headerName: "Part Number", width: 200, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false,
            renderCell: (params) => (
                <HoldButton
                    params={{ value: `${params.row.partNumber}${params.row.partAlias && ` - ${params.row.partAlias}`}` }}
                    color={"#2d5ca9"}
                    url={`/part?partNum=${params.row.partNumber}`}
                >
                    {params.row.partNumber}
                    {params.row.partAlias && (
                        <>
                            <br />
                            {params.row.partAlias}
                        </>
                    )}
                </HoldButton>
            )
        },
        {
            field: 'progress', headerName: "Part Yield", flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false,
            renderCell: (params) => {

                const url = `/status?part=${params.row.partNumber}`

                const datasets = [
                    {
                        label: 'Completed',
                        data: params.row.progress ? params.row.progress.map(item => parseInt(item.pass_count)).map(item => item === 0 ? -1 : item) : [],
                        trueColor: 'rgba(0, 128, 0, 1)',
                        transparentColor: 'rgba(0, 128, 0, .125)',
                        stepped: true,
                    },
                    {
                        label: 'Incomplete',
                        data: params.row.progress ? params.row.progress.map(item => parseInt(item.incomplete_count)).map(item => item === 0 ? -1 : item) : [],
                        trueColor: 'rgba(128, 128, 128, 1)',
                        transparentColor: 'rgba(128, 128, 128, .125)',
                        stepped: true,
                    },
                    {
                        label: 'Fail',
                        data: params.row.progress ? params.row.progress.map(item => parseInt(item.fail_count)).map(item => item === 0 ? -1 : item) : [],
                        trueColor: 'rgba(255, 0, 0, 1)',
                        transparentColor: 'rgba(255, 0, 0, .125)',
                        stepped: true,
                    },
                    {
                        label: 'Stale',
                        data: params.row.progress ? params.row.progress.map(item => parseInt(item.stale_count)).map(item => item === 0 ? -1 : item) : [],
                        trueColor: 'rgba(235, 152, 42, 1)',
                        transparentColor: 'rgba(235, 152, 42, .125)',
                        stepped: true,
                    },
                ];

                const graph = (
                    <SteppedLineChart
                        labels={params.row.progress ? params.row.progress.map((day, index) => day.action_date) : []}
                        datasets={datasets.map(dataset => ({
                            ...dataset,
                            selected: hoveredLegendItem === dataset.label,
                            borderColor: ((hoveredLegendItem === dataset.label) || (!hoveredLegendItem)) ? dataset.trueColor : dataset.transparentColor,
                            pointBackgroundColor: ((hoveredLegendItem === dataset.label) || (!hoveredLegendItem)) ? dataset.trueColor : dataset.transparentColor,
                            backgroundColor: dataset.trueColor
                        })).reduce((acc, dataset) => {
                            dataset.selected ? acc.push(dataset) : acc.unshift(dataset);
                            return acc;
                        }, []).filter(dataset => displayOptions.some(option => option.toLowerCase() === dataset.label.toLowerCase()))}
                        useFractions={useFractions}
                        refreshData={async () => {
                            await refreshPartNumber(params.row.partNumber)
                        }}
                    />
                )

                const labels =
                    datasets.filter(dataset => displayOptions.some(option => option.toLowerCase() === dataset.label.toLowerCase())).map((dataset, index) => {
                        return (
                            <Link to={url + `&filter=${dataset.label.toLowerCase()}`} style={{ width: '100%' }}>
                                <Button style={{ width: '100%', color: dataset.trueColor, position: 'relative' }}
                                    onMouseEnter={() => {
                                        setHoveredLegendItem(dataset.label)
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredLegendItem(undefined)
                                    }}
                                >
                                    <LensIcon style={{ position: 'absolute', left: '8px' }} />
                                    <Typography sx={{ paddingLeft: '32px', textTransform: 'none', fontSize: '.75rem' }}>{dataset.label}</Typography>
                                </Button>
                            </Link>
                        )
                    })


                return (
                    <>
                        {params.row.finishedLoading ?
                            <>{
                                params.row.noProgress ? <Typography>No Data</Typography> : <>
                                    <Button sx={{ width: 'calc(100% - 150px)', height: '100%', color: '#2d5ca9' }} onClick={() => {
                                        setOpenedGraph(`Time_${params.row.partNumber}`)
                                    }}>
                                        {graph}
                                    </Button>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                                        {labels}
                                    </div>
                                </>
                            }</> : (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center', width: '100%', height: '100%'
                                }}>
                                    <CircularProgress />
                                    <Typography sx={{ textAlign: 'center' }} className='loading'>Loading</Typography>
                                </div>
                            )}

                        <Modal
                            open={openedGraph === `Time_${params.row.partNumber}`}
                            onClose={() => setOpenedGraph(undefined)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80%',
                                    height: '80%',
                                    bgcolor: 'background.paper',
                                    mx: 'auto',
                                    my: '5%',
                                    borderRadius: '8px',
                                    p: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
                                    {params.row.partNumber} - "{params.row.partAlias}"
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                                    <Box sx={{ width: '90%', height: '100%' }}>
                                        {graph}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10%', paddingLeft: 2 }}>
                                        {labels}
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </>
                )
            }
        },
        {
            field: 'completed', headerName: 'Completed', width: 150, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false,
            renderCell: (params) =>
            (
                <HoldButton
                    params={{
                        value: useFractions ? (
                            `${params.row.completed} / ${params.row.total}`
                        ) : (
                            (params.row.completed !== '-' && params.row.total !== '-') ? (
                                `${(params.row.completed / params.row.total * 100).toFixed(2)}%`
                            ) : (
                                '- %'
                            )
                        )
                    }}
                    url={`/status?part=${params.row.partNumber}`}
                    color={'#2d5ca9'}
                >
                    {useFractions ? (
                        `${params.row.completed} / ${params.row.total}`
                    ) : (
                        (params.row.completed !== '-' && params.row.total !== '-') ? (
                            `${(params.row.completed / params.row.total * 100).toFixed(2)}%`
                        ) : (
                            '- %'
                        )
                    )}
                </HoldButton>

            )
        }
    ]

    const [rows, setRows] = useState([]);
    const [hoveredLegendItem, setHoveredLegendItem] = useState(undefined);
    const [openedGraph, setOpenedGraph] = useState(undefined)

    /**
     * Gets the date adding X months
     * @param {Date} date the date
     * @param {Number} months the number of months
     * @returns the date + X months
     */
    const addMonths = (date, months) => {
        let result = new Date(date);
        result.setMonth(result.getMonth() - months);
        return result;
    }

    /**
     * Gets the date adding X years
     * @param {Date} date the date
     * @param {Number} years the number of years
     * @returns the date + X years
     */
    const addYears = (date, years) => {
        let result = new Date(date);
        result.setFullYear(result.getFullYear() - years);
        return result;
    }

    /**
     * Gets the difference between two dates
     * @param {Date} a a Date
     * @param {Date} b b Date
     * @returns the difference between a and b
     */
    const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    }

    /**
     * translates the time option to a number based on leap year and month days
     * @param {String} option the option
     * @param {Date} currentDate the current date (defaults to current date)
     * @returns the number of days back to look from the current date
     */
    const timeOptionsTranslator = (option, currentDate = new Date()) => {
        const translator = {
            "1 Week": 7,
            "2 Weeks": 14,
            "3 Weeks": 21,
            "1 Month": dateDiffInDays(currentDate, addMonths(currentDate, 1)),
            "2 Months": dateDiffInDays(currentDate, addMonths(currentDate, 2)),
            "3 Months": dateDiffInDays(currentDate, addMonths(currentDate, 3)),
            "6 Months": dateDiffInDays(currentDate, addMonths(currentDate, 6)),
            "1 Year": dateDiffInDays(currentDate, addYears(currentDate, 1)),
            "2 Years": dateDiffInDays(currentDate, addYears(currentDate, 2)),
            "All Time": "all"
        };
        return translator[option];
    };

    /**
     * Fetches for the part number's progress data
     * @param {String} partNumber the part number
     * @param {Number} timeFrame how far back to search
     * @returns 
     */
    const fetchProgressForPart = async (partNumber, timeFrame) => {
        return await ApiManager.part_history(partNumber, timeFrame).then(handleApiError)
    }

    /**
     * Appends and resizes the fetched data to match the selected time window
     * @param {Number} index the index the part is in the table
     * @param {Array[Object]} data the fetched data
     * @param {Number} timeOption the time duration
     */
    const refreshProgressForPart = async (index, data, timeOption) => {
        const newRows = [...rows]

        function wasYearChanged(daysAgo) {
            // Get the current date
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();

            // Create a date `X` days ago
            const pastDate = new Date();
            pastDate.setDate(currentDate.getDate() - daysAgo);
            const pastYear = pastDate.getFullYear();

            // Compare the years
            return currentYear !== pastYear;
        }

        const diffYears = wasYearChanged(timeOption)
        console.log(data)
        newRows[index].progress = Object.values(newRows[index].progress
            .concat(data.map((d_2) => ({ action_date: diffYears ? d_2.action_date : d_2.action_date.substring(5), fail_count: d_2.fail_count, pass_count: d_2.pass_count, incomplete_count: d_2.incomplete_count, stale_count: d_2.stale_count })))
            .reduce((acc, obj, index_1) => {
                const actionDate = obj.action_date;
                if (!acc[actionDate] || acc[actionDate].index < index_1) {
                    acc[actionDate] = { ...obj, index }; // Store the object along with its index
                }
                return acc;
            }, {}))
            .slice(-(timeOption + 1));

        newRows[index].completed = data[data.length - 1].pass_count;
        newRows[index].total = data[data.length - 1].serials_existed;

        if (!newRows[index].progress.some((date_1) => date_1.serials_existed !== 0)) {
            newRows[index].noProgress = true;
        }

        newRows[index].finishedLoading = true;

        setRows([...newRows])
    }

    /**
     * overrides the index's progress data with the fetched data
     * @param {Number} index the index the part is located in in the table
     * @param {Array[Object]} data the data from the fetch
     * @param {Array[Object]} newRows the rows
     */
    const overrideProgressForPart = async (index, data, newRows) => {
        newRows[index].progress = data[0].action_date.substring(0, 5) === data[data.length - 1].action_date.substring(0, 5) ? data.map((d_1) => ({ ...d_1, action_date: d_1.action_date.substring(5) })) : data;
        newRows[index].completed = data[data.length - 1].pass_count;
        newRows[index].total = data[data.length - 1].serials_existed;

        if (!newRows[index].progress.some((date) => date.serials_existed !== 0)) {
            newRows[index].noProgress = true;
        }

        newRows[index].finishedLoading = true;

        setRows([...newRows]);
    }

    /**
     * refreshse the data given a part number
     * @param {String} partNumber the part number
     */
    const refreshPartNumber = async (partNumber) => {
        const index = rows.findIndex((row) => row.partNumber === partNumber);
        await fetchProgressForPart(partNumber, 0)
            .then((data) => {
                (async () => {
                    if (data !== '404 Error') {
                        refreshProgressForPart(index, data, timeOptionsTranslator(selectedTimeFrame))
                    }
                })();
            });

    }

    /**
     * fetches the timelines for each part in the current build
     * @param {Array} newRows the new rows
     * @param {Number} timeFrame the time frame
     * @param {Boolean} refreshProgress whether to override the progress data or append it
     * @returns an array of promises
     */
    const fetchProgress = async (newRows, timeFrame, refreshProgress) => {
        if (!refreshProgress)
            setPrevSelectedTimeFrame(selectedTimeFrame);

        const timeOption = timeOptionsTranslator(selectedTimeFrame);

        if ((timeOption >= timeOptionsTranslator(prevSelectedTimeFrame)) || refreshProgress) {
            // Fetch new data when time frame grows
            return buildOptions[selectedBuild].parts.map(async (d, index) => {
                fetchProgressForPart(d.partNumber, timeFrame !== undefined ? timeFrame : timeOption)
                    .then((data) => {
                        (async () => {
                            if (data !== '404 Error') {
                                if (!refreshProgress) {
                                    overrideProgressForPart(index, data, newRows)
                                } else {
                                    refreshProgressForPart(index, data, timeOption)
                                }
                            }
                        })();
                    });

            })

        } else {
            // Slice data when time frame shrinks
            const updatedRows = newRows.map((row) => {
                const progress = row.progress.slice(row.progress.length - timeOption);

                return {
                    ...row,
                    progress: progress[0].action_date.substring(0, 5) === progress[progress.length - 1].action_date.substring(0, 5) ?
                        progress.map((d) => ({ ...d, action_date: d.action_date.substring(5) })) :
                        progress,
                }
            });
            setRows(updatedRows);
            return [];
        }
    };

    /**
     * Refetches the data every hour
     */
    useEffect(() => {
        const scheduleHourlyCheck = () => {
            const now = new Date();
            const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0, 0);
            const timeUntilNextHour = nextHour - now;

            timeoutID.current = setTimeout(() => {
                setIsLoading(true)
                fetchProgress(rows, 0, true).then(() => {
                    setIsLoading(false);
                });
                scheduleHourlyCheck(); // Schedule the next call for the following hour
            }, timeUntilNextHour);
        };
        if (timeoutID && timeoutID.current) {
            clearTimeout(timeoutID.current);
        }

        scheduleHourlyCheck();

        return () => clearTimeout(timeoutID.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuild, buildOptions, rows]);

    /**
     * refetches based on build changes
     */
    useEffect(() => {
        if (Object.keys(buildOptions || {}).includes(selectedBuild)) {
            const newRows = buildOptions[selectedBuild].parts.map((d, index) => ({
                id: index,
                partNumber: d.partNumber,
                partAlias: d.partAlias,
                completed: '-',
                total: '-'
            }));

            setRows(newRows);

            fetchProgress(newRows).then(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildOptions, selectedBuild, fetchState]);

    /**
     * refetches based on the time frame changing
     */
    useEffect(() => {
        if (Object.keys(buildOptions || {}).includes(selectedBuild)) {
            fetchProgress(rows).then(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeFrame]);

    return (
        <DataGrid
            sx={{
                height: 700,
            }}
            columns={columns}
            rows={rows}
            density='comfortable'
            rowHeight={125}
            headerHeight={25}
            hideFooter={true}
            hideSortIcons
            disableColumnMenu
        />
    )
}

export default PartNumberStatusTable