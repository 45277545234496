import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
};

const authStateSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            console.log("lofin suxcess", action.payload)
            state.user = action.payload;
            state.loading = false;
            state.error = null;
        },
        loginFailure: (state, action) => {
            state.error = [action.payload];
            state.loading = false;
            state.isAuthenticated = false;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.error = null;
        },
        getUserState: (state) => {
            return state.payload;
        }
    },
});

export const { loginStart, loginSuccess, loginFailure, logout, getUserState } = authStateSlice.actions;
export default authStateSlice.reducer;
