import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import { setComboOptionStation, resetPageStation } from "../../store/combobox/stationSlice";
import { setState } from '../../store/loadstate/loadStateSlice';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '../../components/table/Table';
import HoldButton from '../../components/holdbutton/HoldButton';

export default function Stations() {
  const [loadingStation, setLoadingStation] = React.useState(false);
  const [partList, setPartList] = React.useState([]);
  const [gridData, setGridData] = React.useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [secondGridData, setSecondGridData] = useState([]);
  const [rows2, setRows2] = React.useState([]);
  const [openTest, setOpenTest] = React.useState(false);
  const dispatch = useDispatch();
  const dataStation = useSelector(state => state.station.value)
  const isPopstate = useSelector(state => state.loadstate.value)
  const handleApiError = ApiError()

  const [columns, setColumns] = useState([]);
  const [fetchStations1, setFetchStations1] = useState(0)
  const [fetchStations2, setFetchStations2] = useState(0)
  const [fetchStations3, setFetchStations3] = useState(0)

  useEffect(() => {
    if (gridData.length > 0) {
      const newColumns = Object.keys(gridData[0])
        .filter(key => key !== 'id') // This will remove the 'id' key
        .map(key => ({
          field: key,
          headerName: key.replace(/_/g, ' '),
          flex: 1 / Object.keys(gridData[0]).length,
          renderCell: (params) => {
            if (key === 'Part_Number') {
              const url = `${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${params.value}`;
              return (
                <HoldButton url={url} params={params} fontSize={params.row.fontSize} />
              )
            } else if (key === 'Serial_Number') {
              const url = (() => {
                try {
                  return `${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.row.Part_Number}?sid=${params.id.split("|")[1]}`;
                } catch {
                  return `${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.row.Part_Number}?sid=${params.id}`;
                }
              })()
              return (
                <HoldButton url={url} params={params} fontSize={params.row.fontSize} />
              )
            }
          }
        }));

      setColumns(newColumns);
    }
  }, [gridData]); // Depend on gridData


  const [secondGridColumns, setSecondGridColumns] = useState([]);

  useEffect(() => {
    if (secondGridData.length > 0) {
      const newColumns = Object.keys(secondGridData[0])
        .filter(key => key !== 'id' && key !== 'sid' && key !== 'pid') // Remove 'id', 'sid', 'pid' keys
        .map(key => ({
          field: key,
          headerName: key.replace(/_/g, ' '), // Replace all occurrences of '_' with ' '
          flex: 1 / Object.keys(secondGridData[0]).length,
          renderCell: (params) => {
            if (key === 'Part_Number') {
              const url = `${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${params.value}`;
              return (
                <HoldButton url={url} params={params} fontSize={params.row.fontSize} />
              )
            } else if (key === 'Serial_Number') {
              const url = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.row.Part_Number}?sid=${params.row.sid}`;
              return (
                <HoldButton url={url} params={params} fontSize={params.row.fontSize} />
              )
            }
          }
        }));

      setSecondGridColumns(newColumns);
    }
  }, [secondGridData]); // Depend on secondGridData


  const columns2 = [

    {
      field: 'test_timestamp',
      headerName: 'Timestamp',
      flex: .125,

    },
    {
      field: 'test_name',
      headerName: 'Test',
      flex: .05,

    },
    {
      field: 'result',
      headerName: 'Result',
      flex: .075
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: .2,
    },
    {
      field: 'measurement',
      headerName: 'Measurement',
      flex: .1,
    },
    {
      field: 'units',
      headerName: 'Units',
      flex: .05,
    },
    {
      field: 'upperbound',
      headerName: 'Upper Limit',
      flex: .05,
    },
    {
      field: 'lowerbound',
      headerName: 'Lower Limit',
      flex: .05,
    },
    {
      field: 'eridan_upperbound',
      headerName: 'Eridan Upper Limit',
      flex: .075,
    },
    {
      field: 'eridan_lowerbound',
      headerName: 'Eridan Lower Limit',
      flex: .075,

    },
    {
      field: 'machine',
      headerName: 'Machine',
      flex: .075,
    },
    {
      field: 'software_version',
      headerName: 'Software Version',
      flex: .075,
    }
  ];



  const handleEvent = (e, event) => {
    event.stopPropagation();

    setOpenTest(true)

    const obj = {
      test_id: e.row.id,
      serial_number: e.row.Serial_Number
    };


    (async () => {
      try {
        setLoadingStation(true)
        const data = await ApiManager.station_postStationTest(obj)
        // const handleApiError = ApiError();
        // console.log("queueee before handle api" ,errorName)

        const dataResponse = await handleApiError(data);
        console.log("queue", dataResponse)
        // console.log('queue', data);
        setRows2(dataResponse.tests)
        setFetchStations3(fetchStations3 + 1)
        setLoadingStation(false)

        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

      } catch (error) {
        // dispatch(loginFailure(error));
        // dispatch(logout());
        console.log('hereee', error);
        setLoadingStation(false)


        // navigate('/login');
      }
    })();

    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/getstationtest`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({
    //     test_id: e.row.id,
    //     serial_number: e.row.Serial_Number
    //   })
    // })
    //   .then(response => response.json())
    //   .then(dataResponse => {

    //     setRows2(dataResponse.tests)
    //     setTestFiles(dataResponse.test_files)
    //     setLoading(false)

    //     window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //     setLoading(false)

    //   });

  }


  const handleRowSelected = async (row) => {
    const obj = {
      // pass whatever data the API needs from the selected row
      part_name: row.Part_Number,
      serial_name: row.Serial_Number,
      station_name: row.Station
    };

    try {
      const data = await ApiManager.station_postStationTestDetail(obj)
      // const handleApiError = ApiError();
      // console.log("queueee before handle api" ,errorName)

      const dataResponse = await handleApiError(data);
      console.log("queue", dataResponse)
      // console.log('queue', data);
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

      setSecondGridData(dataResponse);
      setFetchStations2(fetchStations2 + 1)
      console.log(fetchStations2 + 1, "fetching rows_2", dataResponse)
    } catch (error) {
      // dispatch(loginFailure(error));
      // dispatch(logout());
      console.log('hereee', error);
      // setLoading(false)

    }
    // const response = await fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/stations/stationtest/gettests`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },

    //     body: JSON.stringify({
    //         // pass whatever data the API needs from the selected row
    //         part_name: row.Part_Number,
    //         serial_name: row.Serial_Number,
    //         station_name: row.Station
    //     })
    // });
    // const data1 = await response.json();
    // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

    // setSecondGridData(data1);
  }


  const handleSelectionChange = async (event, selectedOption) => {
    if (event.target.dataset.testid === "CloseIcon") {
      dispatch(setComboOptionStation(''))
      setSelectedRowData(null)
      setGridData([]);
      setSecondGridData([]);
      setSelectedRowData(null);
      setOpenTest(false);
      return;
    }

    if (!selectedOption) {
      setGridData([]);
      setSecondGridData([]);
      setSelectedRowData(null);
      setOpenTest(false);
      return;
    }
    console.log("relaod the station page", selectedOption)
    if (typeof selectedOption === 'undefined') {
      // Handle the case when selectedOption is undefined
      console.log('Selected option is undefined');
      return;
    }

    dispatch(setComboOptionStation(selectedOption))
    setOpenTest(false)
    setSelectedRowData(null)

    if (selectedOption !== "") {
      const obj = {
        station_name: selectedOption.Name

      };
      try {
        const data = await ApiManager.station_postStationTests(obj)

        const dataResponse = await handleApiError(data);
        console.log("queue", dataResponse)
        // console.log('queue', data);
        setGridData(dataResponse);
        setFetchStations1(fetchStations1 + 1)
      } catch (error) {

        console.log('hereee', error);

      }
      // const response = await fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/stations/gettests`, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //         station_name: selectedOption.Name
      //     })
      // });
      // const data1 = await response.json();
      // setGridData(data1);
    }
  };

  useEffect(() => {
    // Define an async function inside the useEffect
    const obj =
    {
      station_name: dataStation.option.Name

    };
    console.log(dataStation.option.Name, dataStation)
    if (typeof dataStation.option.Name === 'undefined') {
      console.log('DataStation option is undefined');
      return;
    }
    (async () => {
      try {
        const data = await ApiManager.station_postStationTests(obj);
        //fix here
        // const handleApiError = ApiError();

        const temp = await handleApiError(data);
        console.log('station test ', temp)
        setGridData(temp);

      } catch (error) {

        console.log('hereee', error);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleInputChange = async (event, inputValue) => {
    if (inputValue.length > 2) {
      const obj = {
        station_name: inputValue
      };
      (async () => {
        try {
          setLoadingStation(true)

          const data = await ApiManager.station_postStationList(obj);
          // const handleApiError = ApiError();
          // console.log("queueee before handle api" ,errorName)

          const temp = await handleApiError(data);
          setLoadingStation(false)

          setPartList(temp);
        } catch (error) {
          setLoadingStation(false)

          console.log('hereee', error);
        }
      })();
      // const response = await fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/stations/stationslist`, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //         station_name: inputValue
      //     })
      // });
      // const data = await response.json();

      // setPartList(data);


    } else {
      setPartList([]);
    }
  };

  useEffect(() => {
    const handlePopstate = () => {
      dispatch(setState(true));
    };

    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isPopstate) {
        // dispatch(setComboOption(""));
        dispatch(resetPageStation())
      }

      dispatch(setState(false));
      // event.preventDefault();
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div>
      {loadingStation && <LinearProgress />}
      <div>
        <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px', fontSize: '50px' }}>Stations</h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Autocomplete
          id="combo-box-demo"
          options={partList}
          getOptionLabel={(option) => {
            if (!option) {
              return ""
            }
            return option.Name
          }}
          style={{ width: 300 }}
          onInputChange={handleInputChange}
          value={dataStation.option}
          onChange={handleSelectionChange}  // new handler for selection changes
          renderInput={(params) => <TextField {...params} label="Station" />}
        />

      </div>
      <Table
        sx={{
          height: 500
        }}
        onRowClick={(params, event) => {
          event.stopPropagation();  // Add this line
          setSelectedRowData(params.row);
          handleRowSelected(params.row);
        }}
        fetchData={() => { return { result: gridData, size: gridData.length } }}
        getRowClassName={(params) => {
          console.log("GetRowName", params.row)
          switch (params.row.Last_Result) {
            case "FIRSTTIMEPASS":
              return 'TableRow-Pass'
            case "RETESTPASS":
              return 'TableRow-Pass'
            case "FAIL":
              return 'TableRow-Fail'
            case "RequestClosed":
              return 'TableRow-RequestClosed'
            case "ERIDANPASS":
              return 'TableRow-EridanPass'
            case "Note":
              return 'TableRow-Note'
            default:
              return undefined
          }
        }}
        columns={columns}
        paginationMode={"client"}
        id={"Stations1"}
        key={columns}
        doFetchIndex={fetchStations1}
        onCellClick={() => { }}
      />
      {selectedRowData && (
        <Table
          sx={{
            height: 500
          }}
          onRowClick={
            handleEvent
          }
          fetchData={() => { return { result: secondGridData, size: secondGridData.length } }}
          columns={secondGridColumns}
          paginationMode={"client"}
          id={"Stations2"}
          doFetchIndex={fetchStations2}
          onCellClick={() => { }}
          key={secondGridColumns}
          getRowClassName={(params) => {
            switch (params.row.Result) {
              case "FIRSTTIMEPASS":
                return 'TableRow-Pass'
              case "RETESTPASS":
                return 'TableRow-Pass'
              case "FAIL":
                return 'TableRow-Fail'
              case "RequestClosed":
                return 'TableRow-RequestClosed'
              case "ERIDANPASS":
                return 'TableRow-EridanPass'
              case "Note":
                return 'TableRow-Note'
              default:
                return undefined
            }
          }}
        />
      )}


      {openTest && (
        <Table
          sx={{
            height: 500
          }}
          fetchData={() => { return { result: rows2, size: rows2.length } }}
          columns={columns2}
          paginationMode={"client"}
          id={"Stations3"}
          doFetchIndex={fetchStations3}
          onCellClick={() => { }}
          getRowClassName={(params) => {
            if (params.row.result.includes("PASS")) {
              return 'TableRow-Pass'
            } else if (params.row.result.includes("FAIL")) {
              return 'TableRow-Fail'
            }
          }}
          location={"tableSettings_stations_tests"}
          
        />
      )}
    </div>

  );
}
