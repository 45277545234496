import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    value : {
        payload : ['None'],
        option : "" ,

          }
}
const stationSlice = createSlice({
    name: 'combobox',
    initialState,
    reducers: {
      initializeComboBoxDataStation: (state, action) => {
        state.value.payload = action.payload; // Directly modify the existing state
      },
      setComboOptionStation: (state, action) => {
        state.value.option = action.payload; // Directly modify the existing state
      },
      resetPageStation: (state, action) => {
        state.value.option = ""

      },
    }
  });
  

export const { initializeComboBoxDataStation , setComboOptionStation ,  resetPageStation } = stationSlice.actions;
export default stationSlice.reducer;
