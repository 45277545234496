// Base schema for the tables
const schema = {
	columns: [{ field: "string", flex: "number" }],
	visibility: {},
	filters: {
		items: [
			{
				columnField: "string",
				operatorValue: "string",
				id: "number",
			},
		],
	},
	sort: [
		{
			field: "string",
			sort: "string",
		},
	],
	density: "string",
	pageSize: "number",
	pageNumber: "number",
};

// Base schema for settings that apply to all tables
const generalPreferencesSchema = {
	showCopyIcon: "boolean",
	fontSize: "number",
};

/**
 *	Creates the object given the columns, filters, sort, density and visibility
 *	If it is not provided, the object will still have the field, but the value will be null
 * @param {Array[Object]} columns - the columns
 * @param {Object} filters - the filters settings
 * @param {Array[Object]} sort - the sorting settings
 * @param {String} density - a String that is one of "compact", "standard", "comfortable"
 * @param {Object} visibility - the visibility model
 * @param {Number} pageSize - the page size
 * @param {pageNumber} pageNumber - the page number
 * @return {Object} the verified object
 */
const createObject = ({
	columns,
	filters,
	sort,
	density,
	visibility,
	pageSize,
	pageNumber,
}) => {
	const obj = {};

	// Validate and set columns
	obj.columns = getColumnDataObject({ columns });
	if (obj.columns === null) {
		return null;
	}

	// Validate and set filters
	obj.filters = getFiltersDataObject({ filters, columns });
	if (obj.filters === null) {
		return null;
	}

	// Validate and set sort
	obj.sort = getSortDataObject({ sort, columns });
	if (obj.sort === null) {
		return null;
	}

	// Validate and set visibility
	obj.visibility = getVisibiltyObject({ visibility });
	if (obj.visibility === null) {
		return null;
	}

	// Validate and set density
	obj.density =
		density &&
		typeof density === "string" &&
		["standard", "compact", "comfortable"].includes(density.toLowerCase())
			? density.toLowerCase()
			: "standard";

	// set page size
	obj.pageSize = getPageSize({ pageSize });

	// set page number
	obj.pageNumber = getPageNumber({ pageNumber });

	return obj;
};

/**
 * verifies page number is a number. returns 0 or the page number
 * @param {Number} pageNumber
 * @returns {Number} the page number or 0
 */
const getPageNumber = ({ pageNumber }) => {
	return !pageNumber || typeof pageNumber !== "number" ? 0 : pageNumber;
};

/**
 * verifies the page size is a number. returns 0 or the page number
 * @param {Number} pageSize
 * @returns {Number} the page size or 0
 */
const getPageSize = ({ pageSize }) => {
	return !pageSize || typeof pageSize !== "number" ? 0 : pageSize;
};

/**
 *	Gets the column data given the columns
 *		- fetches the flex and field and appends it to an array
 * @param {Array[Object]} columns - the columns
 * @return {Array[Object]} the verified columns
 */
const getColumnDataObject = ({ columns }) => {
	let obj = [];

	if (verifyObject(columns, schema.columns)) {
		obj = columns.map((col) => {
			return { field: col.field, flex: col.flex };
		});
	}

	return obj;
};

/**
 *	verifies the filters object and returns it if valid
 * @param {Object} filters - the filters
 * @return {Object} the verified filters
 */
const getFiltersDataObject = ({ filters }) => {
	const obj = { items: [] };

	if (verifyObject(filters, schema.filters)) {
		obj.items = filters.items.map((filter) => {
			return {
				columnField: filter.columnField,
				operatorValue: filter.operatorValue,
				id: filter.id,
				...(filter.value ? { value: filter.value } : {}),
			};
		});
	}

	return obj;
};

/**
 *	verifies the sorting object and returns it if valid
 * @param {Array[Object]} sort - the sort settings
 * @return {Array[Object]} the verified sort settings
 */
const getSortDataObject = ({ sort }) => {
	let arr = [];

	if (verifyObject(sort, schema.sort)) {
		arr = sort.map((s) => {
			return {
				field: s.field,
				sort: s.sort,
			};
		});
	}

	return arr;
};

/**
 *	Verifies the visibility object and returns if if valid
 * @param {Object} visibility - the visibility model
 * @return {Object} the verified visibility model
 */
const getVisibiltyObject = ({ visibility }) => {
	let obj = null;

	if (verifyObject(visibility, schema.visibility)) {
		obj = visibility;
	}

	return obj;
};

/**
 *	Verifies an object given a schema
 * @param {Object} obj - the object to be verified
 * @param {Object} schema - the schema to verify against
 * @return {Boolean} whether it's valid
 */
const verifyObject = (obj, schema) => {
	if (!isProperObject(obj)) {
		return false;
	}

	return Object.keys(schema).every((key) => {
		if (!(key in obj)) {
			return false;
		}
		return isValidType(obj[key], schema[key], key);
	});
};

/**
 * Checks if data is an object and not null
 * @param {*} data
 * @returns if data is a non null object
 */
const isProperObject = (data) => {
	return typeof data === "object" && data !== null;
};

/**
 * Checks if the value matches the expected type and the expected value is valid
 * @param {*} actualValue
 * @param {*} expectedType
 * @param {String} key
 * @returns if the valid value matches the expected type
 */
const isValidType = (actualValue, expectedType, key) => {
	if (Array.isArray(expectedType)) {
		return checkArray(actualValue, expectedType, key);
	} else if (typeof expectedType === "object") {
		return verifyObject(actualValue, expectedType);
	} else if (checkType(actualValue, expectedType, key)) {
		return true;
	} else {
		return false;
	}
};

/**
 * checks if the value matches the expected type
 * @param {*} value
 * @param {*} expectedType
 * @param {String} key
 * @returns whether the value is the expected type
 */
const checkType = (value, expectedType, key) => {
	if (typeof value !== expectedType) {
		return false;
	}
	return true;
};

/**
 * checks if the value is an array and the values in the array are correct
 * @param {*} value
 * @param {*} expectedType
 * @param {String} key
 * @returns if the value matches the expected type array
 */
const checkArray = (value, expectedType, key) => {
	if (!Array.isArray(value)) {
		return false;
	}

	if (value.length > 0) {
		const arraySchema = expectedType[0];
		return value.every((item, index) => {
			if (!isProperObject(item)) {
				return false;
			}
			return Object.keys(arraySchema).every((itemKey) =>
				isValidType(
					item[itemKey],
					arraySchema[itemKey],
					`${key}[${index}].${itemKey}`
				)
			);
		});
	}
	return true;
};

/**
 *	Gets and verifies the data at a location
 * @param {String} location - the location to fetch the data
 * @return {Object} the fetch result
 */
const getData = ({ location }) => {
	const base = { result: false, data: null };

	if (typeof location !== "string") {
		return base;
	}

	const dataString = localStorage.getItem(location);
	if (!dataString) {
		return base;
	}

	try {
		const parsedData = JSON.parse(dataString);
		if (verifyObject(parsedData, schema)) {
			return { result: true, data: parsedData };
		} else {
			return base;
		}
	} catch {
		return base;
	}
};

/**
 *	Writes new information into the location making updates where data is provided.
 *	Location is the only required field, all others are optional. When they are provided, Local Storage will be updated
 * @param {String} location - the location
 * @param {Array[Object]} columns - the columns
 * @param {Object} filters - the filter settings
 * @param {Array[Object]} sort - the sort settings
 * @param {String} density - a String that is one of "compact", "standard", "comfortable"
 * @param {Object} visibility - the visibility model
 * @param {Number} pageSize - the page size
 * @param {Number} pageNumber - the page number
 * @return {Object} the updated local storage data
 */
const writeData = ({
	location,
	columns,
	filters,
	sort,
	density,
	visibility,
	pageSize,
	pageNumber,
}) => {
	const dataFetchResult = getData({ location });

	//If there is valid data in the location
	if (dataFetchResult.result) {
		// if columns are provided, looking to replace columns data
		if (columns) {
			const columnData = getColumnDataObject({ columns });
			if (columnData) {
				//columns have been verified, updating LS.columns
				dataFetchResult.data.columns = columnData;
			}
		}

		//if filters are provided, looking to replace filter data
		if (filters) {
			const filterData = getFiltersDataObject({ filters, columns });
			if (filterData) {
				//filters have been verified, updating LS.filters
				dataFetchResult.data.filters = filterData;
			}
		}

		//if sort is provided, looking to replace sort data
		if (sort) {
			const sortData = getSortDataObject({ sort, columns });
			if (sortData) {
				//sort has been verified, updating LS.sort
				dataFetchResult.data.sort = sortData;
			}
		}

		//if density is provided, looking to replace density data
		if (density) {
			if (
				typeof density === "string" &&
				["standard", "compact", "comfortable"].includes(density.toLowerCase())
			) {
				//density has been verified, updating LS.density
				dataFetchResult.data.density = density.toLowerCase();
			}
		}

		//if visibility is provided, looking to replace filter data
		if (visibility) {
			const visibilityData = getVisibiltyObject({ visibility });
			if (visibility) {
				//visibility has been verified, updating LS.visibility
				dataFetchResult.data.visibility = visibilityData;
			}
		}

		//if pageSize is provided, looking to replace pageSize data
		if (pageSize) {
			const pageSizeData = getPageSize({ pageSize });
			if (pageSizeData) {
				//pageSize has been verified, updating LS.pageSize
				dataFetchResult.data.pageSize = pageSizeData;
			}
		}

		//if pageNumber is provided, looking to replace pageNumber data
		if (pageNumber || pageNumber === 0) {
			const pageNumberData = getPageNumber({ pageNumber });
			dataFetchResult.data.pageNumber = pageNumberData;
		}
	} else {
		//If there is no data in the location, create a new object
		dataFetchResult.data = createObject({
			columns,
			filters,
			sort,
			density,
			visibility,
		});

		//Verifies that the data was added correctly
		if (!dataFetchResult.data) {
			return null;
		}
	}

	//Sets the local storage to the updated data
	localStorage.setItem(location, JSON.stringify(dataFetchResult.data));
	return dataFetchResult.data;
};

/**
 *	Function to get general preferences from localStorage
 * @return {Object} the general preferences
 */
const getGeneralPreferences = () => {
	const value = localStorage.getItem("generalPreferences");

	if (!value) {
		setGeneralPreferences({ fontSize: 14, showCopyIcon: true });
	}

	try {
		const parsedValue = JSON.parse(value);
		if (verifyObject(parsedValue, generalPreferencesSchema)) {
			return parsedValue;
		} else {
			return null;
		}
	} catch (e) {
		return null;
	}
};

/**
 *	Function to set general preferences in localStorage
 * @param {Object} preferences - the preferences
 */
const setGeneralPreferences = (preferences) => {
	if (verifyObject(preferences, generalPreferencesSchema)) {
		localStorage.setItem("generalPreferences", JSON.stringify(preferences));
	}
};

/**
 *	Function to check if showCopyIcon is enabled
 * @return {Boolean} if the showCopyIcon is enabled
 */
const isShowCopyIconEnabled = () => {
	const preferences = getGeneralPreferences();
	if (preferences && typeof preferences.showCopyIcon === "boolean") {
		return preferences.showCopyIcon;
	}
	setShowCopyButtonEnabled(true);
	return true;
};

/**
 *	Function to enable or disable showCopy button
 * @param {Boolean} value - the new value

 */
const setShowCopyButtonEnabled = (value) => {
	// verifies value is a boolean
	if (value === undefined || typeof value !== "boolean") {
		return;
	}

	//updates the general preferences
	const preferences = getGeneralPreferences() || {};
	preferences.showCopyIcon = value;
	setGeneralPreferences(preferences);
};

/**
 *	Function to get font size from general preferences
 * @return {Number} the font size from local storage or the default value of 14
 */
const getFontSize = () => {
	const preferences = getGeneralPreferences();
	if (preferences && typeof preferences.fontSize === "number") {
		return preferences.fontSize;
	}
	return 14;
};

/**
 *	Function to set font size in general preferences
 * @param {Number} value - the new font size
 */
const setFontSize = (value) => {
	//verify value is a number
	if (value && typeof value !== "number") {
		return;
	}

	//update the font size in local storage
	const preferences = getGeneralPreferences();
	preferences.fontSize = value;
	setGeneralPreferences(preferences);
};

// Exporting functions for external use
module.exports = {
	createObject,
	verifyTableData: (data) => verifyObject(data, schema),
	writeData,
	getData,
	tableDataSchema: schema,
	isShowCopyIconEnabled,
	setShowCopyButtonEnabled,
	getFontSize,
	setFontSize,
	verifyObject,
};
