import React, { useState, useEffect, useRef } from 'react';

import { QueueForm } from '../../components'

import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';
export default function QueueBoard() {

	const [openForm, setOpenForm] = useState(false)
	const [clickedRow, setClickRow] = useState({})
	const [rows, setRows] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [currentFile, setCurrentFile] = useState({})
	const [comments, setComments] = useState({})
	const [applyFrontEndFilter, setApplyFrontEndFilter] = useState(false)
	const [fetchIndex, setFetchIndex] = useState(0)
	const sock = useRef()
	const handleApiError = ApiError();

	const frontEndFilter = (rows) => {
		console.log("here")
		if (rows)
			return rows.filter((row) => row.id !== clickedRow.id)
	}

	//  console.log(jwtToken)
	const fetchData = async (querySettings) => {
		try {
			setIsLoading(true)
			const data = await ApiManager.queue_getReworkQueue(querySettings);
			const dataAPI = await handleApiError(data);

			setRows(dataAPI)
			console.log(dataAPI)

		} catch (error) {
			console.log('hereee queue', error);
		} finally {
			setIsLoading(false)
		}
	};
	useEffect(() => {


		console.log(`wss://${process.env.REACT_APP_SERVER_WS_ROUTE}`)
		const socket = new WebSocket(`wss://${process.env.REACT_APP_SERVER_WS_ROUTE}`);
		sock.current = socket
		sock.current.addEventListener('open', function (event) {
			console.log("WS open")

		});

		sock.current.addEventListener('message', function (event) {
			fetchData()
			console.log("Happened")

		});

		return () => {
			socket.close();
			setOpenForm(false)
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const columns = [
		{ field: 'requester', headerName: 'Requestor', minWidth: 0, flex: .1 },
		{
			field: 'request_timestamp', headerName: 'Timestamp', minWidth: 0, flex: .15,
			type: 'dateTime',
			valueGetter: ({ value }) => value && new Date(value)

		},
		{ field: 'part_name', headerName: 'Part', minWidth: 0, flex: .15 },

		{ field: 'serial_name', headerName: 'Serial', minWidth: 0, flex: .1 },
		{ field: 'subject', headerName: 'Subject', minWidth: 0, flex: .5 }

	];

	const handleEvent = (e) => {
		if (e.row === clickedRow) {
			return
		}
		setOpenForm(true)
		setClickRow(e.row)
		const old = document.body.scrollHeight
		setTimeout(() => {
			window.scrollTo({ left: 0, top: document.body.scrollHeight - old * 2, behavior: "smooth" });

		}, 100);
		const obj = {
			request_id: e.row.id,
			serial_number: e.row.serial_name
		};

		console.log('e', e);
		(async () => {
			try {
				const data = await ApiManager.queue_getRequestFiles(obj);

				const errorName = await handleApiError(data);
				console.log("queue Files", errorName);

				setCurrentFile(errorName);
			} catch (error) {
				console.log('hereee 1', error);
			};
		})();

		const obj2 = {
			request_id: e.row.id,
			serial_number: e.row.serial_name

		};
		console.log('e after', obj2);



		(async () => {
			try {
				const data = await ApiManager.queue_getRequestComment(obj2);
				const errorName = await handleApiError(data);
				console.log("queueee", errorName)
				setComments(errorName)
			} catch (error) {

				console.log('hereee', error);
			}
		})();


	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<>
			<h1 style={{ textAlign: 'center', margin: "16px 0" }}>Rework Queue</h1>
			<Table
				key={columns}
				columns={columns}
				fetchData={() => {
					return { result: rows, size: rows.length }
				}}
				location={"tableSettings_queue"}
				onRowClick={handleEvent}
				sx={{
					"& .MuiDataGrid-root": {
						backgroundColor: "#43A6C6",
						color: "white",
						border: 'solid 2px black',
					},
					height: 550
				}}
				doFetchIndex={rows}
				id={1}
				applyFrontEndFilter={applyFrontEndFilter}
				frontEndFilter={frontEndFilter}
				setApplyFrontEndFilter={frontEndFilter}
				paginationMode={"client"}
				loading={isLoading}
			/>
			{(openForm && rows.some((row) => row.id === clickedRow.id)) && <QueueForm data={clickedRow} applyFilter={() => setTimeout(() => setApplyFrontEndFilter(true), 0)} setOpenForm={setOpenForm} currentFile={currentFile} comments={comments} setComments={setComments} />}

		</>
	)
}