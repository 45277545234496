import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
    return (
        <div>
            <h1>404 Page</h1>
            <p>This page doesn't exist</p>
            <Link to="/">Go back home</Link>
        </div>
    );
}


