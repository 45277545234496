import React from 'react';
import { BrowserRouter as Router, Routes, Route , Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dashboard, Login, Error } from './containers';
import { Sidebar, Navbar } from './components';
import './App.css';
import { getJwtToken } from './auth';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const App = () => {
  const jwtToken = getJwtToken();
  console.log(jwtToken);
  const authState = useSelector((state) => state.auth);
  console.log('authState' , authState)
  const { isAuthenticated } = authState;

  return (
    <div className="App">
      <Router>
        <Routes>
          {!isAuthenticated ? (
            <>
            <Route path="/" element={<Login />} />
            <Route index element={<Login />} />
            <Route path="*" element={<Login/>} />
            <Route/>
            </>

          ) : (
            <>
              {/* Protected Routes */}
              <Route path="/" element={<ProtectedLayout />}>
                <Route index element={<Dashboard currentPage="landing" />} />
                <Route path="equipment" element={<Dashboard currentPage="equipment" />} />
                <Route path="eco" element={<Dashboard currentPage="ecoboard" />} />
                <Route path="configurations" element={<Dashboard currentPage="configurations" />} />
                <Route path="manage-serial-revs" element={<Dashboard currentPage="manage-serial-revs" />} />
                <Route path='mac-addresses' element={<Dashboard currentPage={"mac-addresses"} />} />
                <Route path="status" element={<Dashboard currentPage="status" />} />
                <Route path="status/manage" element={<Dashboard currentPage="status/manage" />} />
                <Route path="part" element={<Dashboard currentPage="partsBoard" />} />
                {/* <Route path="station" element={<Dashboard currentPage="stationBoard" />} /> */}
                <Route path="queue" element={<Dashboard currentPage="queueBoard" />} />
                <Route path="stations" element={<Dashboard currentPage="stations" />} />
                <Route path="yield" element={<Dashboard currentPage="yieldBoard" />} />
                <Route path="yield/boxplot" element={<Dashboard currentPage="boxplotBoard" />} />
                <Route path="dashboard" element={<Dashboard currentPage="partsBoard" />} />
                <Route path="chart/:id" element={<Dashboard currentPage="partChart" />} />
                <Route path="log/:id" element={<Dashboard currentPage="logBoard" />} />
                <Route path="logtest/:id" element={<Dashboard currentPage="logTest" />} />
                <Route path="intake" element={<Dashboard currentPage="intake" />} />
                {/* <Route path="*" element={<Dashboard currentPage="partsBoard" />} /> */}
                <Route path="*" element={<Dashboard currentPage="error" />} />
                <Route path="error" element={<Dashboard currentPage="error" />} />

              </Route>
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
};

// A layout component that includes the Navbar and Sidebar for the protected routes
const ProtectedLayout = () => {
  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <Sidebar />
        {/* Outlet component to render child routes */}
        <Outlet />
      </div>
    </div>
  );
};

export default App;
