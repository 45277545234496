
import React , {useState, useEffect} from 'react'
import './dashboard.css'
import { MainBoard} from '../.'
import { useNavigate } from "react-router-dom";
import { loginSuccess, loginFailure ,logout} from '../../store/auth/authStateSlice';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import useIdle from "../../hooks/useIdleTimer.js";
const Dashboard = ({currentPage}) => {
    const [param, setBoard] = useState(currentPage)
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const dispatch = useDispatch()

    const handleIdle = () => {
    console.log("showing model")
      setShowModal(true); //show modal
      setRemainingTime(15); //set 15 seconds as time remaining
    };
  
    const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 3600});
  
    useEffect(() => {
      let interval;
  
      if (isIdle && showModal) {
        console.log("is idle and showmodel")
        interval = setInterval(() => {
          setRemainingTime(
            (prevRemainingTime) =>
              prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
          );
        }, 1000);
      }
  
      return () => {
        clearInterval(interval);
      };
    }, [isIdle, showModal]);
  
    useEffect(() => {
      console.log(remainingTime)
      if (remainingTime === 0 && showModal) {
        // alert("Time out!");
        console.log("remain time = 0")
        setShowModal(false);
        dispatch(logout())
        // navigate('/login')
          }
    }, [remainingTime]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything
  
    const handleLogOut = () => {
      setShowModal(false);

      dispatch(logout())
    //   navigate('/login')
        };
  
    const handleStayLoggedIn = () => {
      setShowModal(false);
  
    };
  
    function millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }
    return (

            <>
        {/* {showModal && (
        <Modal open={showModal} onClose={handleStayLoggedIn} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="modal-content">
            <h2>Idle Timeout Warning</h2>
            <p>You are about to be logged out due to inactivity.</p>
            <br />
            Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
            <br />
            <div className="row">
              <Button variant="contained" color="secondary" onClick={handleLogOut}>
                Logout
              </Button>
              <Button variant="contained" color="primary" onClick={handleStayLoggedIn}>
                Stay Logged In
              </Button>
            </div>
          </div>
        </Modal>
      )} */}
            <MainBoard param={currentPage} setBoard={setBoard}/>
            </>
            // </div>
        // </div>

    )
}

export default Dashboard
