
import React from 'react'
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import MUIRichTextEditor from 'mui-rte'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApiError from '../../api_utils/ApiError';
import ApiManager from '../../api_utils/ApiManager';
import { FileUploadForm } from '../.'
import TextField from '@mui/material/TextField';
import { convertToRaw } from 'draft-js'
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';

export default function AddNoteForm({ serial_number, handleClose }) {
  const [error, setError] = React.useState('')
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [richText, setRichText] = React.useState('')
  const handleApiError = ApiError()

  const authData = useSelector((state) => state.auth);
  const { user } = authData;
  const requestTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(requestTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          height: '100%',
          maxHeight: "450px",
          border: '1px solid black'
        },
        editor: {
          height: '100%',
          overflowY: 'auto',
          maxHeight: '350px',
          padding: '20px'
        },
        placeHolder: {
          padding: '20px',
          width: 'inherit',
          position: 'static',
          height: '100%',
          maxHeight: '100%',
          overflowY: 'auto'

        },
        anchorLink: {
          color: '#FFEB3B',
          textDecoration: 'underline',

        },
      }
    }
  })

  const handleFilesSelected = (selectedFiles) => {
    setSelectedFiles(selectedFiles)
  };

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (e.target.subject.value.length === 0) {
      setError('Input cannot be empty')
      return
    }
    setError('')
    const queryString = window.location.search;
    let q = queryString.split("?")
    let result = q[1].split("=")


    let formData = new FormData();
    formData.append('author', user);
    formData.append('details', richText);
    formData.append('subject', e.target.subject.value);
    formData.append('serial_id', result[1]);
    formData.append('serial_number', serial_number);
    if (selectedFiles.length === 0) {
      formData.append('upload_files', new File([], 'placeholder'));
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('upload_files', selectedFiles[i]);
    }
    const d = await ApiManager.postaddNote(formData)
    const response = await handleApiError(d)
    handleClose(e)
    // fetch("https://" + process.env.REACT_APP_SERVER_ROUTE + "/addnote", {
    //   method: "POST",
    //   body: formData
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     handleClose(e)

    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //   });


  }

  return (
    <div>
      <form onSubmit={handleAddNote} style={{ position: 'relative' }}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}><ClearIcon /></IconButton>
        <Typography style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h6" component="h2">
          Add Note
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}>
          <Typography variant="h5" component="h5">
            {error}

          </Typography>
        </div>
        <Stack spacing={3}>
          <Typography variant='h5'>Author: {user}</Typography>

          <TextField
            label="Subject"
            defaultValue=""
            name="subject"
          />

          <div style={{}}>
            <ThemeProvider theme={requestTheme}>
              <MUIRichTextEditor label="Start typing comments"
                onChange={(e) => {
                  const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
                  rteContent && setRichText(JSON.stringify(rteContent))
                }}
              ></MUIRichTextEditor></ThemeProvider>
          </div>
          <FileUploadForm
            id="outlined-multiline-static"
            label="uploads"
            name="uploads"
            onFilesSelected={handleFilesSelected}
          />
          <Button variant='contained' type='submit'> Add</Button>

        </Stack>

      </form>

    </div>
  )

}