import ENDPOINTS from "./EndPoints";

import ApiMethods from "./ApiMethods";
const BASE_URL = `https://${process.env.REACT_APP_SERVER_ROUTE}`;

class ApiManager {
	static auth_logInUser = (googleid) => {
		const url = BASE_URL + ENDPOINTS.AUTH_LOGIN();
		const params = { google_id_token: googleid };

		return ApiMethods.post(url, params);
	};

	static auth_logOutUser = () => {
		const url = BASE_URL + ENDPOINTS.AUTH_LOGOUT();
		const params = {};
		return ApiMethods.post(url, params);
	};

	static queue_getReworkQueue = (body) => {
		const url = BASE_URL + ENDPOINTS.QUEUE_GETLIST();
		// const params = JSON.stringify({})
        console.log(url, body)
		return ApiMethods.get(url);
	};

	static queue_getRequestFiles = (body) => {
		const url = BASE_URL + ENDPOINTS.QUEUE_GETREQUESTFILES();
		return ApiMethods.post(url, body);
	};

	static queue_getRequestComment = (body) => {
		const url = BASE_URL + ENDPOINTS.QUEUE_GETREQUESTCOMMENT();
		return ApiMethods.post(url, body);
	};

	static queue_postRequestComment = (body) => {
		const url = BASE_URL + ENDPOINTS.QUEUE_ADDCOMMENT();
		return ApiMethods.post(url, body);
	};

	// NEED TO FIX
	static queue_postRequestFixer = (body) => {
		const url = BASE_URL + ENDPOINTS.QUEUE_ADDFIXER();
		return ApiMethods.post(url, body);
	};

	static station_getStationTest = () => {
		const url = BASE_URL + ENDPOINTS.STATION_GETLIST();
		return ApiMethods.get(url);
	};

	static station_postStationTest = (body) => {
		const url = BASE_URL + ENDPOINTS.STATION_GETLIST();
		return ApiMethods.post(url, body);
	};

	static station_getStationTestDetail = () => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONTESTSDETAILS();
		return ApiMethods.get(url);
	};

	static station_postStationTestDetail = (body) => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONTESTSDETAILS();
		return ApiMethods.post(url, body);
	};
	static station_getStationTests = () => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONTESTS();
		return ApiMethods.get(url);
	};
	static station_postStationTests = (body) => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONTESTS();
		return ApiMethods.post(url, body);
	};

	static station_getStationList = () => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONLIST();
		return ApiMethods.get(url);
	};

	static station_postStationList = (body) => {
		const url = BASE_URL + ENDPOINTS.STATION_GETSTATIONLIST();
		return ApiMethods.post(url, body);
	};

	static yield_getPieChartDetail = () => {
		const url = BASE_URL + ENDPOINTS.YIELD_GETPIECHARTDETAIL();
		return ApiMethods.get(url);
	};

	static yield_postPieChartDetail = (body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_GETPIECHARTDETAIL();
		return ApiMethods.post(url, body);
	};

	// static yield_getTestDetail = () => {
	//     const url = BASE_URL + ENDPOINTS.YIELD_GETTESTDETAIL();
	//     return ApiMethods.get(url)

	// }

	static yield_postTestDetail = (body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_GETTESTDETAIL();
		return ApiMethods.post(url, body);
	};
	// static yield_postTestInfo = (body) =>{
	//     const url = BASE_URL + ENDPOINTS.YIELD_GETTESTDETAIL();
	//     return
	// }

	static yield_partPieChart = (serial, body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_GETPIECHART(serial)
		return ApiMethods.post(url, body)
	}

	static yield_postTestDetails = (partNumber, station, body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_GETTESTDETAILS(partNumber, station)
		return ApiMethods.post(url, body)
	}

	static yield_postSummaryList = (param, body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_POSTSUMMARYLIST(param)
		console.log(url)
		return ApiMethods.post(url, body)
	}

	static yield_postSerialList = (param, body) => {
		const url = BASE_URL + ENDPOINTS.YIELD_POSTSERIALS(param);
		return ApiMethods.post(url, body)
	}

	static mac_addresses_assigned = () => {
		const url = BASE_URL + ENDPOINTS.MACADDRESS_ASSIGNED();
		return ApiMethods.get(url);
	}

	static mac_addresses_utilization = () => {
		const url = BASE_URL + ENDPOINTS.MACADDRESS_UTILIZATION();
		return ApiMethods.get(url);
	}

	static mac_fromPart = (partNumber, serialNumber) => {
		const url = BASE_URL + ENDPOINTS.MACADDRESS_FROM_SERIAL(partNumber, serialNumber);
		return ApiMethods.get(url)
	}

	static part_getPartList = () => {
		console.log("get");
		const url = BASE_URL + ENDPOINTS.PART_GETPARTLIST();
		return ApiMethods.get(url);
	};

	static part_postPartList = (body) => {
		console.log("post");
		const url = BASE_URL + ENDPOINTS.PART_GETPARTLIST();
		console.log("post", url, body);
		console.log(body);
		return ApiMethods.post(url, body);
	};

	static part_PartListWithID = () => {
		const url = BASE_URL + ENDPOINTS.PARTS() + "/ids";
		return ApiMethods.get(url);
	};

	static part_history = (part_number, days) => {
		const url = BASE_URL + ENDPOINTS.PART_HISTORY(part_number, days)
		return ApiMethods.get(url)
	}

	static builds = () => {
		const url = BASE_URL + ENDPOINTS.BUILDS()
		return ApiMethods.get(url)
	}

	static status_getStatusDetail = (param) => {
		const url = BASE_URL + ENDPOINTS.STATUS_GETDETAILS() + "/" + param;
		return ApiMethods.get(url);
	};

	static status_synthesized = (partNumber) => {
		const url = BASE_URL + ENDPOINTS.STATUS_GETSYNTHESIZEDSTATUS() + "/" + partNumber
		return ApiMethods.get(url)
	}

	static equipment_putFormDetail = (param, body) => {
		const url = BASE_URL + ENDPOINTS.EQUIPMENT_PUTFORMVALUE() + "/" + param;
		return ApiMethods.put(url, body);
	};
	static equipment_getEquipmentDetail = (param) => {
		const url = BASE_URL + ENDPOINTS.EQUIPMENT_GETEQUIPMENTDETAIL() + param;
		return ApiMethods.get(url);
	};
	static equipment_getEquipmentLogDetail = (param) => {
		const url =
			BASE_URL + ENDPOINTS.EQUIPMENT_GETEQUIPMENTLOGDETAIL() + "/" + param;
		return ApiMethods.get(url);
	};
	static equipment_getEquipmentTypeList = () => {
		const url = BASE_URL + ENDPOINTS.EQUIPMENT_GETTYPELIST();
		return ApiMethods.get(url);
	};

	static log_getLogRevByLogPartNumber = (params) => {
		let partNum = params.partNum;
		let serialRev = params.serialRev;
		let latestRev = params.latestRev;
		const url =
			BASE_URL +
			ENDPOINTS.LOG_GETLOGREV() +
			`/${partNum}/uprev/${serialRev}/${latestRev}`;
		return ApiMethods.get(url);
	};

	static log_getRevDataDetail = (params) => {
		let partNum = params.partNum;
		let id = params.id;
		const url = BASE_URL + ENDPOINTS.LOG_GETLOGREV() + `/${partNum}/${id}`;
		console.log("here");
		console.log(partNum);
		console.log(url);
		return ApiMethods.get(url);
	};

	static log_upRevData = () => {
		const url = BASE_URL + ENDPOINTS.LOG_UPREVDATA();
		return ApiMethods.get(url);
	};

	static log_getLogActivity = () => {
		const url = BASE_URL + ENDPOINTS.LOG_GETLOGACTIVITY();
		return ApiMethods.get(url);
	};
	static log_postLogActivity = (body) => {
		const url = BASE_URL + ENDPOINTS.LOG_GETLOGACTIVITY();
		return ApiMethods.post(url, body);
	};
	static log_postSerialSummary = (body) => {
		const url = BASE_URL + ENDPOINTS.LOG_GETSERIALSUMMARY();
		return ApiMethods.post(url, body);
	};

	static log_getRevData = () => {
		const url = BASE_URL + ENDPOINTS.LOG_GETREVDATA();
		return ApiMethods.get(url);
	};

	static log_postLogTestResult = (body) => {
		const url = BASE_URL + ENDPOINTS.LOGTEMPLATE_GETTESTRESULT();
		return ApiMethods.post(url, body);
	};

	static log_postStationTest = (body) => {
		const url = BASE_URL + ENDPOINTS.STATION_GETLIST();
		return ApiMethods.post(url, body);
	};

	static boxplot_postBoxPlotDetail = (body) => {
		const url = BASE_URL + ENDPOINTS.BOXPLOT_GETBOXPLOTDETAIL();
		return ApiMethods.post(url, body);
	};

	static boxplot_postDataStudio = (body) => {
		const url = BASE_URL + ENDPOINTS.BOXPLOT_DATASTUDIO();
		return ApiMethods.post(url, body);
	};
	static boxplot_postTestByName = (body) => {
		const url = BASE_URL + ENDPOINTS.BOXPLOT_GETTEST();
		return ApiMethods.post(url, body);
	};

	static intake_postAddPartNumber = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_ADDPARTNUMBER();
		return ApiMethods.post(url, body);
	};

	static intake_postPartSearchList = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_PARTSEARCHLIST();
		return ApiMethods.post(url, body);
	};
	static intake_postSerialNumber = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_ADDSERIALNUMBER();
		return ApiMethods.post(url, body);
	};

	static intake_generateSerials = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_ADDSERIALNUMBERGENERATE();
		return ApiMethods.post(url, body);
	};

	static intake_postAddComponent = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_ADDCOMPONENTS();
		return ApiMethods.post(url, body);
	};

	static intake_postGetComponent = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_GETCOMPONENTS();
		return ApiMethods.post(url, body);
	};
	static intake_postSerialSearchList = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_SERIALSEARCHLIST();
		return ApiMethods.post(url, body);
	};
	static intake_postComponentSearchList = (body) => {
		const url = BASE_URL + ENDPOINTS.INTAKE_COMPONENTSEARCHLIST();
		return ApiMethods.post(url, body);
	};

	static eco_getEcoDetailByPartNum = (params) => {
		const url = BASE_URL + ENDPOINTS.ECO_GETECOBYPARTNUM() + `/${params}`;
		return ApiMethods.get(url);
	};

	static eco_getPartList = () => {
		const url = BASE_URL + ENDPOINTS.part_getPartList();
		return ApiMethods.get(url);
	};

	static eco_getEcoDetail = () => {
		const url = BASE_URL + ENDPOINTS.ECO_GETDETAIL();
		return ApiMethods.get(url);
	};

	static postgetNote = (body) => {
		const url = BASE_URL + ENDPOINTS.GETNOTE();
		return ApiMethods.post(url, body);
	};

	static postaddNote = (body) => {
		const url = BASE_URL + ENDPOINTS.ADDNOTE();
		return ApiMethods.post(url, body);
	};

	static postaddRequest = (body) => {
		const url = BASE_URL + ENDPOINTS.ADDREQUEST();
		return ApiMethods.post(url, body);
	};

	static postgetRequest = (body) => {
		const url = BASE_URL + ENDPOINTS.GETREQUEST();
		return ApiMethods.post(url, body);
	};

	static postaddFix = (body) => {
		const url = BASE_URL + ENDPOINTS.ADDFIX();
		return ApiMethods.post(url, body);
	};

	static postaddComment = (body) => {
		const url = BASE_URL + ENDPOINTS.ADDCOMMENT();
		return ApiMethods.post(url, body);
	};

	static addConfiguration = (body, pid) => {
		const url = BASE_URL + ENDPOINTS.PARTS() + `/${pid}` + "/config/add";
		return ApiMethods.post(url, body);
	};

	static part_GetCONFIGS = (pid) => {
		const url = BASE_URL + ENDPOINTS.PARTS() + `/${pid}` + "/config";
		return ApiMethods.post(url);
	};

	static GETSERIALCONFIGS = (pid) => {
		const url = BASE_URL + ENDPOINTS.PARTS() + `/${pid}` + "/serial-configs";
		return ApiMethods.get(url);
	};

	static SERIAL_CONFIG_LOGS = (sid) => {
		const url = BASE_URL + ENDPOINTS.SERIALS_CONFIGS_LOGS(sid);
		return ApiMethods.get(url);
	};

	static SERIALCONFIGS_UPDATE(updateData) {
		const url = BASE_URL + ENDPOINTS.SERIALS_CONFIGS() + `/update`;
		return ApiMethods.post(url, updateData);
	}

	static SERIALCONFIGS_GETONE(body) {
		const url = BASE_URL + ENDPOINTS.SERIALS_CONFIGS();
		return ApiMethods.post(url, body);
	}

	static SERIALS_STATUS_PRODUCTION(pid) {
		const url = BASE_URL + ENDPOINTS.SERIALS_STATUS_PRODUCTION(pid);
		return ApiMethods.get(url);
	}

	static SERIALS_PRODUCTION_CHANGESTATUS(sid, body) {
		const url = BASE_URL + ENDPOINTS.SERIALS_STATUS_PRODUCTION_CHANGE(sid);
		return ApiMethods.post(url, body);
	}

	static SERIALS_PRODUCTION_LOGS(sid) {
		const url = BASE_URL + ENDPOINTS.SERIALS_STATUS_PRODUCTION_LOGS(sid);
		return ApiMethods.get(url);
	}

	static getContractManufacturers(){
		const url = BASE_URL + ENDPOINTS.PARTS_CONTRACT_MANUFACTURERS();
		return ApiMethods.get(url);
	}
}

export default ApiManager;
