// ProgressBar.js
import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';

/**
 * The overall styling
 */
const ProgressContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

/**
 * The label styling
 */
const ProgressLabel = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '150%',
    transform: 'translateX(-50%)',
    backgroundColor: '#2d5ca9',
    padding: '4px 8px',
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    whiteSpace: 'nowrap',
    color: 'white'
}));

/**
 * A Progress bar and a label. add 48px of top margin to the item below 
 * @param {String} label the label for the progress bar
 * @param {Number} percentage a value (0 -> 100) 
 * @returns the progress bar
 */
const ProgressBar = ({ label, percentage }) => {

    return (
        <ProgressContainer>
            <ProgressLabel sx={{ left: `${percentage}%` }} variant="caption">
                <div style={{ zIndex: 20, textAlign: 'center' }}>
                    {label}
                    <br />
                    {`${percentage}%`}
                </div>
                <div style={{ zIndex: 19, position: 'absolute', width: '5%', aspectRatio: '1', top: '-4%', left: '47.5%', transform: 'rotate(45deg)', background: '#2d5ca9' }} />
            </ProgressLabel>

            <LinearProgress
                variant="determinate"
                value={percentage}
                sx={{
                    width: '100%', height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': {
                        backgroundColor: '#2d5ca9',
                    },
                }}
            />
        </ProgressContainer>
    );
};

export default ProgressBar;
