import React from 'react';
import { Step, StepLabel, styled } from '@material-ui/core';
import { OverrideModal} from '../../components'

const statusColors = {
    PASS: 'green',
    FAIL: 'red',
    INCOMPLETE: 'grey',
    ERIDANPASS : '#d2d904',

};

const CustomStepIconContainer = styled('div')(({ status }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
}));

const CustomStepIcon = styled('div')(({ status }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: statusColors[status],
    marginBottom: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    border: '2px solid rgba(255, 255, 255, 0.8)',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export function StepContainer({ label, status, ref_id , process_id, sid,handleClick,page , partNum , logInfo, handleStatusUpdate }) {
    const [menuVisible, setMenuVisible] = React.useState(false);
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const [clickedStep, setClickedStep] = React.useState(null);
    const [hoverIndex, setHoverIndex] = React.useState(null); // To handle hover
    const [open, setOpen] = React.useState(false);
    // const [station_name, setStationName] = React.useState(null);
    const stepRef = React.useRef(null); 
    const handleClose = () => setOpen(false);
    function handleRightClick(e, step) {
        e.preventDefault();
        setPosition({
            x: e.clientX,
            y: e.clientY,
        });
        setClickedStep(step);
        setMenuVisible(true);
    }

    function closeMenu() {
        
        setMenuVisible(false);
    }

    // function handleStationName(value){
    //     setStationName(value);
    // }

    React.useEffect(() => {
      // This function will close the menu when a right-click happens anywhere on the document
      const handleGlobalRightClick = (e) => {
        console.log(stepRef, menuVisible, e.target.innerHTML, clickedStep)
          if  (menuVisible && clickedStep && clickedStep['label'] !== e.target.innerHTML)  {
              closeMenu();
          }
      };
  
      // This function will close the menu when a left-click happens anywhere on the document
      const handleGlobalClick = (e) => {
          if (menuVisible ) {
              closeMenu();
          }
      };

    //   const constHandleOverride = (e) => {
    //     fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/status/override/${sid}}`, {
    //         method: "POST",
    //         headers: {
    //         "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //           //send sid here
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
        

    //     })
    //     .catch(error => {
    //         console.error("Error:", error);

    //     });
    //   }
  // Only add the listeners if the modal is not open
        document.addEventListener('contextmenu', handleGlobalRightClick);
        document.addEventListener('click', handleGlobalClick);
    
      // Remove the event listeners when the component unmounts
      return () => {
          document.removeEventListener('contextmenu', handleGlobalRightClick);
          document.removeEventListener('click', handleGlobalClick);
      };
  }, [menuVisible,open]);

    return (
      <>
      
          <Step>
              <StepLabel StepIconComponent={props => 
                  <CustomStepIconContainer 
                      status={status} 
                      onContextMenu={(e) => handleRightClick(e, { label, status })}
                  >
                      <CustomStepIcon status={status}>
                          <span style={{ fontSize: '24px', color: 'white' }}>{label}</span>
                      </CustomStepIcon>
                      <div style={{ color: statusColors[status], fontWeight: 'bold' }}>
                          {status}
                      </div>
                  </CustomStepIconContainer>
              }/>
          </Step>
          {menuVisible && (
              <div
                  style={{
                      position: 'absolute',
                      top: `${position.y}px`,
                      left: `${position.x}px`,
                      background: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                      zIndex: 1000,
                      minWidth: '100px',
                  }}
              >
                  <MenuItem 
                      onClick={(e) => {e.preventDefault()
                                        closeMenu()
                                        setOpen(true) } } 
                      onMouseEnter={() => setHoverIndex(0)}
                      onMouseLeave={() => setHoverIndex(null)}
                      isHovered={hoverIndex === 0}
                  >
                      Override
                  </MenuItem>
                  {clickedStep["label"] !== "Kitting" && ref_id !== null &&
                  <MenuItem 
                      onClick={() => {
                        if (page === "log") {
                            handleClick(ref_id)
                        }
                        else {
                            
                            window.location.href = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${partNum}?sid=${sid}&refid=${ref_id}`;

                        }
                        }} 
                      onMouseEnter={() => setHoverIndex(1)}
                      onMouseLeave={() => setHoverIndex(null)}
                      isHovered={hoverIndex === 1}
                  >
                      Test Data
                  </MenuItem>
                }
              </div>
            // <StatusMenu clickedStep={clickedStep} ref_id={ref_id} handleClick={handleClick} closeMenu={closeMenu} setOpen={setOpen}></StatusMenu>
          )}
        <OverrideModal open={open} handleClose={handleClose} serial_number={logInfo.serial} sid={sid} station_name = {label} process_id={process_id} handleStatusUpdate={handleStatusUpdate}/>

      </>
  );
}

const MenuItem = ({ children, onClick }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <>
      <div 
          onClick={onClick} 
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
              padding: '10px 15px',
              borderBottom: '1px solid #eee',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#333',
              background: isHovered ? '#f5f5f5' : 'white'
          }}
      >
          {children}
      </div>

    </>

  );
}
