import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import LinearProgress from '@mui/material/LinearProgress';
import { Template } from '../.'
import { AddNoteForm, RequestFormModal } from '../../components'
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import ApiError from '../../api_utils/ApiError';
import ApiManager from '../../api_utils/ApiManager';
import { Edit, ErrorOutline, FileCopy } from '@material-ui/icons';
import HoldButton from '../../components/holdbutton/HoldButton';
import { DataGrid } from '@mui/x-data-grid';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import StationProgressBar from '../../components/stationprogressbar/StationProgressBar';
import ObjectExporter from '../../components/obectexporter/ObjectExporter';

import Stale from '../../utils/Stale';

/**
 * The Log Page
 * 
 * @returns The Log Page
 */
const Log = () => {

	const [configurations, setConfigurations] = React.useState([]);
	const [logInfo, setLog] = React.useState({ partNum: '', serial: '', lastSeen: '', station: '', timeWIP: '', components: [] })
	const [activity, setActivity] = React.useState([])
	const [isLoading, setLoading] = React.useState(false)
	const location = useLocation();
	const [sid, setSid] = React.useState('')
	const [statuses, setStatus] = React.useState([])
	const [open, setOpen] = React.useState(false);
	const [openRequest, setOpenRequest] = React.useState(false);
	const [stepClick, handleClick] = React.useState('')
	const [statusUpdated, handleStatusUpdate] = React.useState(false)
	const [latestRev, setLatestRev] = React.useState(null)
	const [serialRev, setSerialRev] = React.useState(null)
	const [isECO, setIsECO] = React.useState(false)
	const [checkedRev, setCheckedRev] = React.useState(false)
	const [prefill, setPreFill] = React.useState("");
	const [summaryFill, setSummaryFill] = React.useState("");
	const [openedModal, setOpenedModal] = React.useState(undefined)
	const handleApiError = ApiError()
	const handleOpenRequest = () => {
		setPreFill("");
		setSummaryFill("");
		setIsECO(false)
		setOpenRequest(true);
	}
	const [macAddresses, setMacAddresses] = useState([])

	const useStyles = makeStyles((theme) => ({
		title: {
			fontSize: theme.typography.pxToRem(18),
			fontWeight: 'bold',
			color: '#333',
			marginBottom: theme.spacing(1),
			textTransform: 'uppercase',
			letterSpacing: '0.5px',
			textAlign: 'center',
			paddingBottom: theme.spacing(1),
			borderBottom: '2px solid #ddd',
		},
		body: {
			fontSize: theme.typography.pxToRem(16),
			color: '#666',
			marginBottom: theme.spacing(0.5),
			textAlign: 'center',
		},
		icon: {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(0.5),
		},
	}));

	const classes = useStyles();

	/**
	 * A function to get a number from a string. Used in 'XX days'
	 * @param {String} input the input string
	 * @returns the number from the string
	 */
	const getDaysNumber = (input) => {
		try {
			const match = input.match(/[\d.]+/);
			const days = match ? parseFloat(match[0]) : null;
			return days
		} catch {
			return 0
		}
	}

	const numDaysStale = Stale.staleDays(getDaysNumber(logInfo.lastSeen || logInfo.timeWIP))

	/**
	 * Formats the data into html
	 * @param {Array} data the data to format
	 * @returns the formatted html
	 */
	function formatData(data) {
		return data.map(item => {
			const version = item?.version || '';
			const description = item?.description || '';
			const link = item?.link || '';
			const linkHTML = `<a href="${link}">Rev Link</a>`;
			return `${version} | ${description} | ${linkHTML}`;
		}).join('<br>');
	}

	/**
	 * Fetches the Rev Data. Handles load effects and parsing
	 */
	const fetchUpRevData = async () => {
		setLoading(true)

		const params = {
			partNum: logInfo.partNum,
			serialRev: serialRev,
			latestRev: latestRev
		}

		const d = await ApiManager.log_getLogRevByLogPartNumber(params)
		const data = await handleApiError(d);

		//Fetches the data
		(async () => {
			if (Array.isArray(data)) {
				const formattedData = formatData(data);
				const htmlContent = `<div>${formattedData}</div>`;
				setPreFill(htmlContent);
				setSummaryFill(`[ECO] REV ${latestRev}`);
				setLoading(false)
				setIsECO(true)
			} else {
				console.error("Unexpected data format:", data);
				setLoading(false)

				return Promise.reject("Unexpected data format");
			}
			return Promise.resolve();
		})();
		setOpenRequest(true)
		setLoading(false)
	};

	/**
	 * Handles the up revision
	 */
	const handeUpRev = () => {
		fetchUpRevData()
	};

	/**
	 * Opens the modal
	 */
	const handleOpen = () => {
		setOpen(true)
	};

	/**
	 * Closes the modal, refetching data
	 * @param {Event} e the event
	 */
	const handleClose = async (e) => {
		setOpen(false);
		setOpenRequest(false)

		if (e.target && e.target.id === "cancel") {
			return
		}

		setLoading(true)
		try {
			const obj = { serial_id: sid };

			const data = await ApiManager.log_postLogActivity(obj);
			const d = await handleApiError(data);

			setActivity(d.map((ele, index) => ({ ...ele, id: index }))) //Gives each element a unique id property
			setLoading(false)

		} catch (error) {
			setLoading(false)
			console.log('Error', error);
		}
	}

	/**
	 * Gets the Revision Data. Handles load effect and parses data
	 */
	const getRevData = async () => {
		try {
			setLoading(true)
			const d = await ApiManager.log_getRevDataDetail({ partNum: logInfo.partNum, id: sid });
			const data = await handleApiError(d);

			if (data['latest_rev'] !== data['serial_rev']) {
				setCheckedRev(true)
			}
			setLatestRev(data['latest_rev'])
			setSerialRev(data['serial_rev'])
			setLoading(false)
		} catch (error) {
			setLoading(false)
		}
	}

	/**
	 * handles the fetch requests for serial summary and log activity
	 * @param {Array} d
	 * @param {String} route the route
	 */
	const handleRequest = async (d, route) => {
		let sid = d[0].split("=")
		setSid(sid[1])
		if (d.length > 1) {
			let ref_id = d[1].split("=")
			handleClick(ref_id[1])
		}

		try {
			const obj = { serial_id: sid[1] };
			let response = ""
			setLoading(true)


			if (route === "getserialsummary") { 									//fetch serial summary
				response = await ApiManager.log_postSerialSummary(obj)
			} else if (route === "getactivity") { 									//fetch log activity
				response = await ApiManager.log_postLogActivity(obj)
			}

			//handle error checking
			const data = await handleApiError(response)
			if (route === 'getserialsummary') {										//parse serial summary
				data.id = sid[1];
				console.log(data)
				setLog(data);

				ApiManager.mac_fromPart(data.partNum, data.serial)
					.then(handleApiError)
					.then((d) => {
						setMacAddresses(d !== null ? (Array.isArray(d) ? d : [d]).map((obj) => obj.mac_address) : [])
					})
				setStatus(data['serial_status'])
				setConfigurations(data["config"])
				console.log(data["config"])
			}
			if (route === 'getactivity') {
				setActivity(data.map((item, index) => ({ ...item, id: index })))	//parse log activity
			}
			setLoading(false)
		}
		catch (error) {
			console.error("Error:", error);
			setLoading(false)
		}
	}

	useEffect(() => {
		const queryString = window.location.search;
		let q = queryString.split("?")
		let result = q[1].split("&")
		handleRequest(result, "getserialsummary")
		handleRequest(result, "getactivity")
		handleStatusUpdate(false)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusUpdated, location])

	useEffect(() => {
		if (logInfo.partNum && sid) {
			getRevData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logInfo.partNum, location]);

	return (
		<div>
			{(logInfo.production_status !== 'Shipped' && numDaysStale > 0) && (
				<AppBar position="static">
					<Toolbar style={{ justifyContent: "center", background: '#CF3C38', color: 'white' }}>
						<ErrorOutline fontSize='large' style={{ scale: '150%' }} />
						<Typography variant="h3" sx={{ margin: '0px 16px', fontWeight: 800 }}>
							STALE FOR {numDaysStale} DAYS
						</Typography>
						<ErrorOutline fontSize='large' style={{ scale: '150%' }} />
					</Toolbar>
				</AppBar>
			)}

			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 700,
					maxHeight: '90%',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 2,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}>
					<AddNoteForm serial_number={logInfo.serial} handleClose={handleClose} />
				</Box>
			</Modal>

			<RequestFormModal open={openRequest} handleClose={handleClose} data={logInfo} pref={prefill} summaryFill={summaryFill} isECO={isECO} handleApiError={handleApiError} />

			<div style={{ width: '100%', margin: '16px 0', filter: 'drop-shadow(0px 2px 2px #E0E0E0)' }}>
				<Typography variant="h5" sx={{ textAlign: 'center', color: 'white', padding: '8px', border: '#2d5ca9 2px solid', background: "#2d5ca9", borderTopRightRadius: '8px', borderTopLeftRadius: '8px', position: 'relative' }}>
					{logInfo.description}
					<Button
						startIcon={<FileDownloadIcon />}
						sx={{ color: 'white', justifySelf: 'end', marginLeft: '16px' }}
						onClick={() => setOpenedModal("export")}
					>
						Export
					</Button>

					<Modal open={openedModal === 'export'} onClose={() => setOpenedModal(undefined)} >
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '50%',
								height: '50%',
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
								borderRadius: '8px'
							}}
						>
							<ObjectExporter enableJSON={true} enableCSV={true} object={logInfo} title={logInfo.serial} keys={{ partNum: 'Part Number', description: 'Description', config: 'Configuration', lastSeen: 'Last Seen', macAddresses: 'MAC Addresses', parents: 'Parent Component', processStartDate: 'Process Start Date', timeWIP: "Time in WIP", serial: 'Serial', serial_status: 'Status', station: 'Station', testDate: 'Test Date', lasttest: 'Last Test', components: 'Components', production_status: "Production Status" }} />

						</Box>
					</Modal>
				</Typography>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', background: 'white', border: '#E0E0E0 2px solid', borderRadius: '8px', borderTop: 'none', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', boxSizing: 'border-box', "-moz-box-sizing": "border-box", "-webkit-box-sizing": "border-box", position: 'relative', minHeight: '40px' }}>
					<div style={{ flex: 1, marginRight: '8px' }}>
						{logInfo.partNum && <HoldButton url={`${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${logInfo.partNum}`} params={{ value: logInfo.partNum }} color={"#2d5ca9"}>
							<Typography variant="subtitle1" sx={{ textAlign: 'center', padding: '8px' }}>
								{logInfo.partNum} Yield
							</Typography>
						</HoldButton>}
					</div>

					<div style={{ position: 'absolute', width: '2px', top: '16px', height: 'calc(100% - 32px)', background: "#E0E0E0", left: 'calc(50% - 1px)' }} />

					<div style={{ flex: 1, marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
						<Typography variant="subtitle1" sx={{ textAlign: 'center', width: '100%', padding: '8px' }}>
							{logInfo.serial}
						</Typography>
					</div>

				</div>
			</div>

			<div style={{ filter: 'drop-shadow(0px 2px 2px #E0E0E0)', background: 'white', borderRadius: '5px' }}>
				<DataGrid
					columns={[
						{ field: 'location', headerName: "Location", flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false, },
						{ field: 'lastSeen', headerName: "Last Seen", flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false, },
						{ field: 'timeInWIP', headerName: 'Time In WIP', flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false, },
						{ field: 'status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false, },
						{ field: 'revision', headerName: 'Revision', flex: 1, align: 'center', headerAlign: 'center', sortable: false, filterable: false, hideable: false, }
					]}
					rows={[{
						location: logInfo.station || '-',
						lastSeen: logInfo.lastSeen || '-',
						timeInWIP: logInfo.timeWIP || '-',
						status: logInfo.production_status || '-',
						revision: serialRev || '-',
						id: 0
					}]}
					autoHeight
					hideFooter={true}
					hideSortIcons
					disableColumnMenu
					headerHeight={40}
					rowHeight={30}
				/>
			</div>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '150px' }}>
					<div style={{ width: '100%', flex: 1 }}>
						<HoldButton url={`${process.env.REACT_APP_LOCAL_ROUTE}/part?partNum=${logInfo.partNum}`} params={{ value: logInfo.partNum }} color={"#2d5ca9"}>
							Other Serials of Part Number
						</HoldButton>
					</div>
					{logInfo.parents &&
						<div style={{ width: '100%', flex: 1 }}>
							<HoldButton url={`${process.env.REACT_APP_LOCAL_ROUTE}/log/${logInfo.parents.PartName}?sid=${logInfo.parents.parent_sid}`} params={{ value: logInfo?.parents?.ParentSerialName }} color={"#2d5ca9"}>
								Parent Component<br />{logInfo?.parents?.ParentSerialName}
							</HoldButton>
						</div>
					}
				</div>
				<Button
					style={{ flex: 1, textDecoration: (logInfo ? logInfo?.components || [] : [1, 2]).length === 0 ? "line-through" : 'none' }} endIcon={<CopyAllIcon />}
					disabled={(logInfo ? logInfo?.components || [] : [1, 2]).length === 0}
					onClick={() => setOpenedModal("components")}
				>
					Components
				</Button>
				<Modal open={openedModal === 'components'} onClose={() => setOpenedModal(undefined)} >
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '50%',
							height: '50%',
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
							borderRadius: '8px'
						}}
					>
						<Typography className={classes.title}>Components:</Typography>
						<div style={{ display: 'flex', width: '100%', height: 'calc(100% - 12px)', flexDirection: 'column', overflowY: 'auto' }}>
							{logInfo.components.map((component, index) => (
								<div style={{ flex: 1, minHeight: '50px' }}>
									<HoldButton key={index} url={`/log/${component.name}?sid=${component.sid}`} params={{ value: component.name }} color={component.status === 0 ? 'red' : 'green'} onSuccess={() => setOpenedModal(undefined)}>
										{component.status === 0 ? <CloseIcon className={classes.icon} /> : <CheckIcon className={classes.icon} />}
										<Typography variant='subtitle1' sx={{ marginLeft: '8px' }}>
											{component.name}
										</Typography>
									</HoldButton>
								</div>
							))}
						</div>
					</Box>
				</Modal>
				<Button
					style={{ flex: 1, textDecoration: macAddresses.length === 0 ? "line-through" : 'none' }} endIcon={<CopyAllIcon />}
					disabled={macAddresses.length === 0}
					onClick={() => setOpenedModal("mac addresses")}
				>
					Mac Addresses
				</Button>
				<Modal open={openedModal === 'mac addresses'} onClose={() => setOpenedModal(undefined)} >
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '50%',
							height: '50%',
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
							borderRadius: '8px'
						}}
					>
						<Typography className={classes.title}>Mac Addresses:</Typography>
						<div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
							{macAddresses && macAddresses.length > 0 ? (
								macAddresses.map((mac, index) => (
									<Button key={index} onClick={() => {
										navigator.clipboard.writeText(mac);
									}}
										sx={{ flex: 1 }}
										endIcon={<FileCopy />}
									>
										{mac}
									</Button>
								))
							) : (
								<Typography className={classes.body}>None</Typography>
							)}
						</div>
					</Box>
				</Modal>
				<Button
					style={{
						flex: 1, textDecoration: (isLoading || ((configurations || []).some((config) => config.name == null))) ? "line-through" : 'none'
					}}
					endIcon={<CopyAllIcon />}
					disabled={isLoading || (configurations.length > 0 && ((configurations || []).some((config) => {
						console.log(config);
						return config.name === null
					})))}
					onClick={() => setOpenedModal("configurations")}
				>
					Configurations
				</Button>
				<Modal open={openedModal === 'configurations'} onClose={() => setOpenedModal(undefined)} >
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '50%',
							height: '50%',
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
							borderRadius: '8px'
						}}
					>
						<Typography className={classes.title}>Configurations:</Typography>
						<DataGrid
							columns={[
								{ field: 'name', headerName: "Name", flex: 1 },
								{ field: 'value', headerName: "Value", flex: 1 }
							]}
							rows={configurations.map((row, index) => ({ ...row, id: index }))}
							sx={{ height: 'calc(100% - 80px)', margin: '16px 0' }}
							hideFooter={true}
						/>
						<div style={{ width: '100%', display: 'flex' }}>
							<Link to={`/configurations?partNumber=${logInfo.partNum}`} style={{ margin: 'auto' }}>
								<Button startIcon={<Edit />}>
									Edit Configurations
								</Button>
							</Link>
						</div>
					</Box>
				</Modal>
			</div>

			<StationProgressBar stationTests={statuses} isLoading={isLoading} />

			<Stack direction="row" spacing={2} sx={{ marginTop: '16px' }}>

				<Button onClick={handleOpen} variant="contained"> Add Note</Button>
				<Button onClick={handleOpenRequest} variant="contained"> Add Rework</Button>
				{checkedRev && <Button color='error' onClick={handeUpRev} variant="contained"> Upgrade {serialRev} ➟ {latestRev} </Button>}

			</Stack >

			{
				isLoading &&
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			}
			<div>
				<Template serial_name={logInfo.serial} activity={activity} setLoading={setLoading} stepClick={stepClick} />
			</div>
		</div >
	)
}

export default Log
