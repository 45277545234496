import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    value : {
        payload : ['ER981-001040-001', 'ER981-001032-001'],
        option : "" ,

          }
}
const comboBoxStatusSlice = createSlice({
    name: 'comboboxstatus',
    initialState,
    reducers: {
      initializeComboBoxDataStatus: (state, action) => {
        state.value.payload = action.payload; // Directly modify the existing state
      },
      setComboOptionStatus: (state, action) => {
        state.value.option = action.payload; // Directly modify the existing state
      },
      resetPageStatus: (state, action) => {
        state.value.option = ""
      },
      
    }
  });
  

export const { initializeComboBoxDataStatus , setComboOptionStatus, resetPageStatus  } = comboBoxStatusSlice.actions;
export default comboBoxStatusSlice.reducer;
