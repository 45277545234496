import React, { useState, useEffect } from 'react'

import { PieChart, ComboBox, DateForm } from '../../components'

import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { initializeComboBoxData, resetPage } from "../../store/combobox/comboBoxSlice";
import { setState } from '../../store/loadstate/loadStateSlice';
import ApiError from '../../api_utils/ApiError';
import ApiManager from '../../api_utils/ApiManager';
import Table from '../../components/table/Table';
import HoldButton from '../../components/holdbutton/HoldButton';

export default function YieldBoard() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.combobox.value)
  const [table1_fetchIndex, setTable1_fetchIndex] = useState(0)
  const [summaryList, setSummaryList] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [table2_fetchIndex, setTable2_fetchIndex] = useState(0)
  const [serialsList, setSerialsList] = useState([])
  const [loadingSerials, setLoadingSerials] = useState(false)
  const [table3_fetchIndex, setTable3_fetchIndex] = useState(0)
  const [testDetails, setTestDetails] = useState([])
  const [loadingTestDetails, setLoadingTestDetails] = useState(false)
  const [loadingYield, setLoadingYield] = useState(false)
  const isPopstate = useSelector(state => state.loadstate.value)

  const [open, handleOpen] = React.useState(false);
  const [yield_per, setYield] = React.useState('');
  const [summary1, setSummary1] = React.useState([0, 0, 0]);

  const [station, setStation] = React.useState('');
  const [timeQuerySettings, setTimeQuerySettings] = useState({ start_date: 0, end_date: new Date().getTime() })
  const handleApiError = ApiError();

  const columns = [
    { field: 'name', headerName: 'Station', minWidth: 0, flex: 1 / 6 },
    { field: 'pass_count', headerName: 'First Pass Count', minWidth: 0, flex: 1 / 6 },
    { field: 'fail_count', headerName: 'Fail Count', minWidth: 0, flex: 1 / 6 },
    { field: 'retest_count', headerName: 'Retest Pass Count', minWidth: 0, flex: 1 / 6 },
    { field: 'yield_percentage', headerName: 'Yield Percentage', minWidth: 0, flex: 1 / 6, valueFormatter: (params) => `${params.value}%` },
    {
      field: 'average_test_time', headerName: 'Avg Test Time (s)', minWidth: 0, flex: 1 / 6, type: 'number', headerAlign: 'left', align: 'left',

      valueGetter: ({ value }) => {
        if (value !== null) {
          return parseFloat(value)
        }
        return 0
      },
      renderCell: ({ row }) => {
        if (row.average_test_time !== 0) {
          return parseFloat(row.average_test_time).toFixed(2); // Format to 2 decimal places
        }
        return '-';
      },
    },
  ];

  const columns1 = [
    {
      field: 'serial', headerName: 'Serial name', minWidth: 0, flex: 0.3,
      renderCell: (params) => {
        const url = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${data.option}?sid=${params.id}`;
        return (
          <HoldButton params={params} url={url} fontSize={params.row.fontSize} />
        )
      }
    },
    {
      field: 'station', headerName: 'Last Station', minWidth: 0, flex: 0.3,
      renderCell: ({ row }) => {
        if (row && row.station !== null) {

          return `${row.station}`
        }
        return `-`
      }
    },
    {
      field: 'lastTest', headerName: 'Last Test', minWidth: 0, flex: 0.3,

      renderCell: ({ row }) => {
        if (row && row.lastTest !== null) {

          return `${row.lastTest}`
        }
        return `-`
      }
    },
    {
      field: 'lastSeen', headerName: 'Last Seen', minWidth: 0, flex: 0.3, type: 'number', headerAlign: 'left', align: 'left',

      valueGetter: ({ value }) => {
        if (value !== null) {
          try {
            let v = value.replace(" days", "")
            return parseFloat(v)
          } catch {
            return value;
          }
        }
        return 0

      },
      renderCell: ({ row }) => {
        if (row && row.lastSeen !== null) {

          return `${row.lastSeen}`
        }
        return `-`
      }
    },
  ];


  const columns2 = [
    {
      field: 'test_name', headerName: 'Test Name', minWidth: 0, flex: 0.6,
      renderCell: (params) => {
        const encodedParamsValue = encodeURIComponent(params.value);
        const url = `${process.env.REACT_APP_LOCAL_ROUTE}/yield/boxplot?station=${station}&&test=${encodedParamsValue}&&partNum=${data.option}`;
        return (
          <HoldButton params={params} url={url} color={"#2d5ca9"} fontSize={params.row.fontSize} />
        )
      }
    },
    { field: 'pass_count', headerName: 'First Pass Count', minWidth: 0, flex: 0.1 },
    { field: 'fail_count', headerName: 'Fail Count', minWidth: 0, flex: 0.1 },
    { field: 'retest_count', headerName: 'Retest Pass Count', minWidth: 0, flex: 0.1 },
    { field: 'yield_percentage', headerName: 'Yield Percentage', minWidth: 0, flex: 0.1, valueFormatter: (params) => `${params.value || 0}%` },
  ];

  const fetchTable1 = () => {
    setTable1_fetchIndex(table1_fetchIndex + 1);
  }

  const fetchTable2 = () => {
    setTable2_fetchIndex(table2_fetchIndex + 1);
  }

  const fetchTable3 = () => {
    setTable3_fetchIndex(table3_fetchIndex + 1);
  }

  const getListOfPartSerialNames = async () => {
    try {
      setLoadingYield(true)
      const data = await ApiManager.part_getPartList();

      const d = await handleApiError(data);
      console.log(d)
      setLoadingYield(false)

      if ('error' in d) { return }

      if (d.option !== d) {
        dispatch(initializeComboBoxData(d))
      }
    } catch (error) {
      setLoadingYield(false)

      console.log('hereee', error);
    }
  }

  const getPieChartData = async () => {
    console.log("Getting Pie Chart Data")
    try {
      setLoadingYield(true)

      const d = await ApiManager.yield_partPieChart(data.option, timeQuerySettings);

      const res = await handleApiError(d);
      console.log("Pie Chart Data", res)
      setSummary1([res["pass_count"], res["retest_count"], res["fail_count"], res['description']])
      setYield(res["yield_percentage"])
      setLoadingYield(false)

    } catch (error) {
      setLoadingYield(false)

      console.log('hereee', error);
    }
  }

  useEffect(() => {
    getListOfPartSerialNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
      dispatch(setState(true));
    };

    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isPopstate) {
        dispatch(resetPage())
      }

      dispatch(setState(false));
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  const getSummaryList = async (queries) => {
    if (data.option === "") return;
    setLoadingSummary(true)
    const d = await ApiManager.yield_postSummaryList(data.option, { ...queries, ...timeQuerySettings });
    const res = await handleApiError(d);
    res.map((res, index) => { res.id = index; return res })
    console.log("sumaryList", res)
    setSummaryList(res);
    setLoadingSummary(false)
    fetchTable2();
  }

  const getSerialsList = async (queries) => {
    if (data.option === "") return;
    setLoadingSerials(true)
    const d = await ApiManager.yield_postSerialList(data.option, { ...queries, ...timeQuerySettings });
    const res = await handleApiError(d);
    console.log("serialList", res)
    setSerialsList(res)
    setLoadingSerials(false)
    fetchTable1()
  }

  const getTestDetails = async (queries) => {
    if (data.option === "") return;
    setLoadingTestDetails(true)
    const d = await ApiManager.yield_postTestDetails(data.option, station, queries)
    const res = await handleApiError(d)
    console.log("RESPONSE123 = ", res)
    res.map((res, index) => { res.id = index; return res })
    setTestDetails(res)
    setLoadingTestDetails(false)
    fetchTable3();
  }

  useEffect(() => {
    getSummaryList()
    getSerialsList()
    fetchTable1()


    if (data.option)
      getPieChartData();
    else
      setSummary1("")
    handleOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.option])

  useEffect(() => {
    if (station) {
      getTestDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station])

  const handleEvent = (e) => {
    handleOpen(false)
    if (e.row.name === station) {
      return
    }
    handleOpen(true)

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })
    setTimeout(() => {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }, 100);

    setStation(e.row.name)
  }
  return (
    <div style={{ marginTop: 0 }}>
      {/* <TestComponent/> */}
      <Typography variant="h4" component="div" style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px', marginTop: '4px', height: '40px' }}>{summary1[3]}</Typography>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ComboBox label={"Part"} data={data} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '16px' }}>
        <DateForm updateQuerySettings={(data) => {
          setTimeQuerySettings({ ...timeQuerySettings, ...data })

          fetchTable1()
          fetchTable2();
          fetchTable3();
        }} />
      </div>


      <div style={{ position: "relative", margin: "auto", width: "300px" }}>

        {loadingYield ?
          <CircularProgress
            style={{ width: 300, height: 300 }}
          />
          :
          <PieChart summary={summary1} />
        }

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Pass: {summary1[0]}</div>
          <div>Retest Pass Count: {summary1[1]}</div>
          <div>Fail: {summary1[2]}</div>
        </div>
        <br></br>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Yield: {(yield_per * 100).toFixed(1)}%
        </div>
        <br></br>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Summary Report
        </div>
      </div>


      {data.option && <Table
        sx={{ height: 500 }}
        columns={columns1}
        location={"tableSettings_yield_serials"}
        doFetchIndex={serialsList}
        fetchData={() => {
          return { result: serialsList, size: serialsList.length }
        }}
        onRowClick={(e) => {
          setStation(e.row.name)
          console.log(e.row.name)
        }}
        onCellClick={() => { }}
        id={"Table1"}
        getRowClassName={(params) => {
          switch (params.row.lastTest) {
            case "FIRSTTIMEPASS":
              return 'TableRow-Pass'
            case "RETESTPASS":
              return 'TableRow-Pass'
            case "FAIL":
              return 'TableRow-Fail'
            case "RequestClosed":
              return 'TableRow-RequestClosed'
            case "ERIDANPASS":
              return 'TableRow-EridanPass'
            case "Note":
              return 'TableRow-Note'
            default:
              return undefined
          }
        }}
        loading={loadingSerials}
        paginationMode={"client"}
      />
      }
      {data.option &&
        <Table
          sx={{ height: 500 }}
          columns={columns}
          location={"tableSettings_yield_stations"}
          doFetchIndex={summaryList}
          fetchData={() => {
            return { result: summaryList, size: summaryList.length }
          }}
          onRowClick={handleEvent}
          onCellClick={() => { }}
          id={"Table2"}
          loading={loadingSummary}
          paginationMode={"client"}
        />
      }

      {open &&
        <div style={{ position: 'relative' }}>
          <Table
            columns={columns2}
            fetchData={() => {
              return { result: testDetails, size: testDetails.length }
            }}
            location={"tableSettings_yield_testDetails"}
            doFetchIndex={testDetails}
            onCellClick={() => { }}
            id={"Table3"}
            sx={{ height: 500 }}
            closeButton={() => {
              setStation(undefined)
              handleOpen(false)
              setTestDetails([])
            }}
            loading={loadingTestDetails}
            paginationMode={"client"}
          />
        </div>
      }
    </div>
  )
}