import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * A display for the aggregated data in status for a part number
 * @param {Object} statuses an object containing the information for statuses
 * @param {Object} stales an object containing the information for stales
 * @param {Boolean} isLoading whether the chart is loading 
 * @returns a display for the aggregated data
 */
const CombinedChart = ({ statuses, stales, isLoading }) => {
	// Data for the combined chart
	const combinedData = {
		labels: Object.keys(statuses),
		datasets: [
			{
				// Doughnut chart dataset (Stales)
				data: Object.keys(stales).map((key) => stales[key].value),
				backgroundColor: Object.keys(stales).map((key) => stales[key].color),
				borderColor: '#ffffff',
				hoverBackgroundColor: Object.keys(stales).map((key) => stales[key].color),
				hoverBorderColor: '#ffffff',
				borderWidth: 4,
				cutout: '65%',
				type: 'doughnut',
			},
			{
				// Pie chart dataset, which will be in the center of the doughnut (Statuses)
				data: Object.keys(statuses).map((key) => statuses[key].value),
				backgroundColor: Object.keys(statuses).map((key) => statuses[key].color),
				borderColor: '#ffffff',
				hoverBackgroundColor: Object.keys(statuses).map((key) => statuses[key].color),
				hoverBorderColor: '#ffffff',
				borderWidth: 4,
				cutout: '0%',
				radius: '150%',
				type: 'pie',
			},
		],
	};

	// Options for the chart
	const options = {
		plugins: {
			legend: {
				display: false, 
			},
			tooltip: {
				enabled: false,
			},
		},
		responsive: true,
		maintainAspectRatio: false,
	};

	return (
		<div style={{ position: 'relative', width: '250px', height: '250px' }}>
			{isLoading ? (
				<CircularProgress size={'250px'} />
			) : (
				<Doughnut
					data={combinedData}
					options={options}
				/>
			)}
		</div>
	);
};

export default CombinedChart;
