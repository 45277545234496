import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MuiAlert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import { FileUploadForm } from '../.'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw,EditorState, convertFromRaw } from 'draft-js'
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure, logout } from '../../store/auth/authStateSlice';

import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function QueueForm({ data, applyFilter, setOpenForm, currentFile, comments, setComments }) {

    const authData = useSelector((state) => state.auth);
    const { user } = authData;
    const [fixer, setFixer] = useState(user)
    const [richText, setRichText] = React.useState('')
    const [error, setError] = useState('')
    const [errorPU, setErrorPU] = useState('')
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);

    const [reason, setReason] = useState('');  // To manage the dropdown selection
    const [otherDetails, setOtherDetails] = useState('');  // To manage the details for "Other" option
  
    
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [defaultRichText, setDefaultRichText] = React.useState('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')

    const [isLoadingComments, setisLoadingComments] = React.useState(false);
    const [isSent, setisSet] = React.useState(false)
    const [resetFile,setResetFile] = React.useState(false)
    const [emptyRichText, setemptyRichText] = React.useState(true);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleApiError = ApiError()
    const handleFilesSelected = (selectedFiles) => {
        setSelectedFiles(selectedFiles)
    };



    function formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthIndex = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours ? hours : 12;
        const formattedDate = `${monthNames[monthIndex]} ${day}, ${year} ${hours}:${minutes.toString().padStart(2, '0')}${ampm}`;
        return formattedDate;
    }

    const requestTheme = createTheme({
        // Set up your custom MUI theme here
        MUIRichTextEditor: {

        }

    });

    const handleCloseRequest = () => {
        setOpenConfirm(false)
        setOpenCancel(false)
      }
      

    Object.assign(requestTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    width: "100%",
                    height: '100%',
                    maxHeight: '100%',
                },
                anchorLink: {
                    color: '#FFEB3B',
                    textDecoration: 'underline',

                },
            }
        }
    })

    const handleFixer = (e) => {
        setFixer(e.target.value)
    }

    useEffect(() => {
        return () => {
            // setFixer("");
            setError("");
            setSelectedFiles([]);
            setLoading(false);
            setRichText("");
        };
    }, []);

    const handleComments = (e) => {
        e.preventDefault();
        
        setResetFile(false)

        setisLoadingComments(true)
        const formData = new FormData();
        formData.append('author', fixer);
        formData.append('content', richText);
        formData.append('request_id', data.id);
        formData.append('serial_number', data.serial_name);
    
        if (selectedFiles.length === 0) {
          formData.append('upload_files', new File([], 'placeholder'));
        }
    
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('upload_files', selectedFiles[i]);
        }

        (async () => {
          try {
            const d = await ApiManager.queue_postRequestComment(formData);
            const dataResponse = await handleApiError(d)
            console.log(dataResponse['message'])
            console.log(dataResponse['message'].trim() === 'Successfully Created Comment' )
            
           if (dataResponse['message'] === 'Successfully Created Comment') {
                  const obj2 = {
                      request_id: data.id,
                      serial_number: data.serial_name
                  };
                  // Since we're already inside an async function, you can directly await
                  // another async call without needing to wrap it in another IIFE.
                  const commentData = await ApiManager.queue_getRequestComment(obj2);
                  const commentDataResponse = await handleApiError(commentData)
                  console.log('comment response' , commentDataResponse)
                  setComments(commentDataResponse);
                //   setFixer("");
                  setisSet(true);
                  setDefaultRichText(richText);
                  setDefaultRichText('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}');
                  setResetFile(true);
                  setisLoadingComments(false);
                  setTimeout(() => {
                      setisSet(false);
                  }, 2000);
                }
          }
          catch (error){
            console.log("Test async IIFE error");


          }
      })();

    
        // fetch("https://" + process.env.REACT_APP_SERVER_ROUTE + "/addcomment", {
        //   method: "POST",
        //   body: formData
        // })
        //   .then(response => response.json())
        //   .then(respons_data => {
        //     if (respons_data['message'] === 'Sucessfully Created Comment') {
        //       const obj2 = {
        //         request_id : data.id,
        //         serial_number: data.serial_name
        //       };
    
        //       const myJSON2 = JSON.stringify(obj2);
        //       fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/getcomments`, {
        //         method: 'POST',
        //         headers: {
        //           'Accept': 'application/json',
        //           "Access-Control-Allow-Origin": "*",
        //           "Content-Type": "application/json"
        //         },
        //         body: myJSON2
        //       }) 
        //       .then(response => response.json())
        //       .then(response_data => {
        //         setComments(response_data);
        //         setFixer("")
                
        //         setisSet(true);
        //         setDefaultRichText(richText)
        //         setDefaultRichText('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')
        //         setResetFile(true)
        //         setisLoadingComments(false)
        //         setTimeout(() => {
        //           setisSet(false);
        //         }, 2000);

        //       })
        //       .catch(error => {
        //         console.error("Error:", error);
        //       });
        //     }

        //   })
        //   .catch(error => {
        //     console.error("Error:", error);
        //   });
      };
    

    const handleConfirm = () => {
        setOpenConfirm(false);
        handleSubmit2();
      }

    const handleSubmit = (e) =>

    {
        e.preventDefault();
        const command = e.nativeEvent.submitter.name
        if (fixer.length !== 0){
            if (command === "cancel"){
                setOpenCancel(true)
                return
            }
        }
        if (fixer.length === 0) {
            setError('Must put a fixer')
            return
        }
        if (emptyRichText){
            setError('Text cannot be empty')
            return
        }
        console.log(command)
        if (command === "comment"){
            handleComments(e)
        }
        
        else{
            setOpenConfirm(true)
        }
            
        
    }

    const handleCancelStep2 = () => {
        setOpenCancel(true)
    }

    const handleCancel = () => {

        const canceledBlock = {
            key: 'cancelled',
            text: 'CANCELED',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          };
          const contentState = {
            entityMap: {},
            blocks: [canceledBlock],
          };
      
          const editorState = EditorState.createWithContent(convertFromRaw(contentState));

          if (fixer.length === 0) {
            setError('Input cannot be empty')
            return
          }
          else if (reason === "Select a Reason" || reason === ""){
            setErrorPU('Provide a reason')
            console.log("Here")
            return
          }
            else if (reason === "Other" && !otherDetails.trim()) {
                setErrorPU('Please provide details for the "Other" reason');
                console.log("Details required for 'Other'");
                return;   
          }else {           
            setError('')
            console.log(reason,otherDetails)
            let formData = new FormData();
            formData.append('fixer', fixer);
            formData.append('comments', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
            formData.append('request_id', data.id);
            formData.append('serial_id', data.sid);
            formData.append('serial_number', data.serial_name);
            formData.append('isCancel', true)
            
            if (reason === 'Other') {
                formData.append('reason', otherDetails);
            } else {
                formData.append('reason', reason);
            }

            if (selectedFiles.length === 0) {
                formData.append('upload_files', new File([], 'placeholder'));
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('upload_files', selectedFiles[i]);
            }

            setLoading(true);
            setisLoadingComments(true);
            
//FIXING STH HERE
            (async () => {
              try {
                const data = await ApiManager.queue_postRequestFixer(formData);
                const d = await handleApiError(data)
                    setisLoadingComments(false)
                    setisSet(true);
                    setTimeout(() => {
                    setisSet(false);
                    }, 1000);

                    setLoading(false)

                    setTimeout(() => {
                        setOpenForm(false);
                        }, 1000);             
            } catch (error) {
                dispatch(loginFailure(error));
                // dispatch(logout());
                console.log('hereee', error);
                // navigate('/login');
                setLoading(false)

            }
            })();
            
            applyFilter()
        }

    }

    const handleSubmit2 = async () => {

        if (fixer.length === 0 || emptyRichText) {
            setError('Input cannot be empty')
        }
        else {           
            setError('')
 
            let formData = new FormData();
            formData.append('fixer', fixer);
            formData.append('comments', richText);
            formData.append('request_id', data.id);
            formData.append('serial_id', data.sid);
            formData.append('serial_number', data.serial_name);
            formData.append('isCancel', false)
            formData.append("reason", "None")
            if (selectedFiles.length === 0) {
                formData.append('upload_files', new File([], 'placeholder'));
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('upload_files', selectedFiles[i]);
            }

            setLoading(true)
            setisLoadingComments(true);

            const d = await ApiManager.postaddFix(formData);
            const response = await handleApiError(d)
            setisLoadingComments(false)
            setisSet(true);
            setTimeout(() => {
            setisSet(false);
            }, 1000);

            setLoading(false)

            setTimeout(() => {
            setOpenForm(false);
            }, 1000);


            // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/addfix`, {
            //     method: 'POST',
            //     body: formData
            // })
            //     .then(response => response.json())
            //     .then(data => {

                    
            //         setisLoadingComments(false)
            //         setisSet(true);
            //         setTimeout(() => {
            //         setisSet(false);
            //         }, 1000);

            //         setLoading(false)

            //         setTimeout(() => {
            //             setOpenForm(false);
            //             }, 1000);
                    

            //     })
            //     .catch(error => {
            //         console.error("Error:", error);
            //         setLoading(false)

            //     });


            applyFilter();


        }


    }

    const style = {
        position: 'absolute' ,
        borderRadius: '25px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: "180px",
        bgcolor: 'white', /* For browsers that do not support gradients */
        overflowY:'auto',
        '&::-webkit-scrollbar': { width: 0, }
        
        
      };

      const style_cancel = {
        position: 'absolute' ,
        borderRadius: '25px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: "350px",
        bgcolor: 'white', /* For browsers that do not support gradients */
        overflowY:'auto',
        paddingLeft: "10px",
        '&::-webkit-scrollbar': { width: 0, }
        
        
      };

    return (<div>
        <>
        <Snackbar open={isLoadingComments} autoHideDuration={2000} >
        <MuiAlert  severity="info" elevation={6} variant="filled" sx={{ width: '350px', fontSize: '25px' }}>
          Loading...
        </MuiAlert>
      </Snackbar>

      <Snackbar open={isSent} autoHideDuration={2000} >
        <MuiAlert onClose={() => setisSet(false)} severity="success" elevation={6} variant="filled" sx={{ width: '350px', fontSize: '25px' }}>
          Sent!
        </MuiAlert>
      </Snackbar>
    {openConfirm && (
      <Modal
        open={openConfirm}
        onClose={handleCloseRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#2D5CA9", borderTopRightRadius: '25px' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                Confirmation
              </Typography>
            </div>
            <div style={{ fontSize: '22px', marginLeft: '8px' }}>Do you wish to mark this ticket as resolved?</div>
            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" style={{ margin: '10px', color: 'white', backgroundColor: '#0275d8' }} onClick={handleConfirm}>Confirm</Button>
              <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
            </div>
          </div>
        </Box>
      </Modal>
    )}

{openCancel && (
      <Modal
        open={openCancel}
        onClose={handleCloseRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style_cancel}>
          <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#2D5CA9", borderTopRightRadius: '25px' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                Canceling
              </Typography>
            </div>
            {errorPU && <div style={{"color":"red", fontSize:"24px", marginLeft:"20px"}}>Error: {errorPU}</div> }
            <div style={{ fontSize: '22px', marginLeft: '20px' }}>Please Provide a Reason</div>

            {/* Dropdown for Reason */}
            <select value={reason} onChange={e => setReason(e.target.value)} style={{ margin: '20px', padding: '10px' }}>
              <option value="">Select a Reason</option>
              <option value="Unit Unavailable">Unit Unavailable</option>
              <option value="Matierl Unavailable">Matierl Unavailable</option>
              <option value="Request No Longer Necessary">Request No Longer Necessary</option>
              <option value="Accidental Entry">Accidental Entry</option>
              <option value="Other">Other</option>
            </select>

            {/* Conditional Text Field for "Other" reason */}
            {reason === 'Other' && (
              <textarea
                value={otherDetails}
                onChange={e => setOtherDetails(e.target.value)}
                placeholder="Provide details..."
                style={{ width: '90%', padding: '10px', marginTop: '10px', marginLeft:"20px" }}
              />
            )}

            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" style={{ margin: '10px', color: 'white', backgroundColor: '#0275d8' }} onClick={handleCancel}>Confirm</Button>
              <Button onClick={() => setOpenCancel(false)}>Cancel</Button>
            </div>
          </div>
        </Box>
      </Modal>
    )}

  </>
        <Alert severity="info"><Typography variant="h5" component="h5">Comments for part {data.part_name} and  serial {data.serial_name}</Typography></Alert>
        {isLoading &&

            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        }
        <br />
        <div style={{ width: "100%", border: 'solid 1px black', backgroundColor: '#e1f6fc' }}>
            

            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <Typography style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", backgroundColor: "#2D5CA9" }}  >Subject: {data.subject}</Typography>
                    <div style={{ display: 'inline-table', padding: '10px', maxWidth: '100%' }}>
                        <Typography style={{ fontSize: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h1" component="h4">Tasks</Typography>
                        <Typography variant="h5" component="h2">

                            <MUIRichTextEditor
                                controls={["my-style"]}
                                customControls={[
                                    ''
                                ]}

                                defaultValue={data.task}
                                readOnly={true}
                                inheritFontSize={true}

                            />
                            <div style={{ borderTop: '1px dashed black', width: '100%', fontSize: '30px', borderBottom: '1px dashed black' }}>
                                <p > Files :</p>
                                {
                                    Object.keys(currentFile).map((key, index) => (
                                        <p key={key}>
                                            <a style={{ textDecoration: 'none', fontSize: '40px', marginLeft: '8px' }} href={currentFile[key]['url2']} target="_blank" rel="noopener noreferrer" > <CloudDownloadIcon style={{ fontSize: '40px' }} />  </a>
                                            <a style={{ textDecoration: 'none', fontSize: '40px' }} href={currentFile[key]['url1']} target="_blank" rel="noopener noreferrer" > <VisibilityIcon style={{ fontSize: '40px' }} />  </a>
                                            {key}


                                        </p>
                                    ))
                                }
                            </div>

                            <div>
                                {comments && Object.keys(comments).length > 0 &&
                                    <div style={{ textAlign: 'center', marginBottom: '15px', fontSize:'43px' }}>Comments</div>
                                }
                                {comments &&

                                    Object.keys(comments).map((key, index) => (
                                        <div
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            <Avatar sx={{ bgcolor: 'blue', margin: '8px' }}>
                                                {comments[key].author && comments[key].author.length > 0 && (
                                                    comments[key].author.length === 1 ? comments[key].author.toUpperCase() : comments[key].author.split(' ').map(n => n[0]).join('').toUpperCase()
                                                )}
                                            </Avatar>

                                            <div style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#48aef7', width: "93%" }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginLeft: '10px', marginRight: '8px', fontWeight: 'bold', fontSize: "30px", }}>{comments[key].author}</div>
                                                    <div style={{ marginLeft: '10px', marginRight: '8px', fontSize: "25px", marginTop:'5px' }}>{formatDate(comments[key].comment_timestamp)}</div>
                                                </div>
                                                <div className="comment-container"
                                                    style={{
                                                        padding: '8px',
                                                        borderRadius: '8px',
                                                        Width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        fontSize: "30px"
                                                    }}
                                                >
                                                    <ThemeProvider theme={requestTheme}>
                                                        <MUIRichTextEditor
                                                            controls={["my-style"]}
                                                            customControls={['']}
                                                            defaultValue={comments[key].content}
                                                            readOnly={true}
                                                            inheritFontSize={true}
                                                            style={{ fontSize: '40px' }} // Add this to increase the text size
                                                        />
                                                    </ThemeProvider>
                                                    <pre
                                                        style={{
                                                            margin: 0,
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                    </pre>

                                                    <div className="files"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-end',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {
                                                            Object.keys(comments[key].comment_files).map((k, index) => (
                                                                <div key={k}>
                                                                    <a style={{ textDecoration: 'none', marginRight: '8px' }} href={comments[key].comment_files[k]['url2']} target="_blank" rel="noopener noreferrer" >
                                                                        <CloudDownloadIcon style={{ fontSize: '40px',marginRight: '10px' }} />
                                                                    </a>
                                                                    <a style={{ textDecoration: 'none', }} href={comments[key].comment_files[k]['url1']} target="_blank" rel="noopener noreferrer" >
                                                                        <VisibilityIcon style={{ fontSize: '40px' ,marginRight: '15px' }} />
                                                                    </a>
                                                                    {k}
                                                                    {/* {comments[key].comment_files[index]['name']} */}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                }

                            </div>

                        </Typography>

                    </div>


                    <Typography style={{ color: 'red' }} variant="h4" component="h4" >        {error}</Typography>

                    <Typography style={{ color: 'black' }} variant="h4" component="h4" > Fixer :{fixer}</Typography>

                    {/* <TextField style={{ marginLeft: '20px' }}
      
                        label="Fixer"
                        id="outlined-read-only-input"
                        sx={{ width: '50ch', backgroundColor: 'white' }}
                        value={fixer}
                        onChange={handleFixer}
                    /> */}


                    <div style={{ border: "1px solid black", backgroundColor: 'white' }}>
                        <MUIRichTextEditor label="Start typing comments"
                        defaultValue={defaultRichText}
                            onChange={(e) => {
                                const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
                                rteContent && setRichText(JSON.stringify(rteContent))                                
                                const contentState = e.getCurrentContent()
                                const hasContent = contentState.hasText()

                                if (!hasContent) {
                                  setemptyRichText(true)
                                } else {

                                  setemptyRichText(false)
                                }
                            }}


                        />

                    </div>
                    <br />
                    <div style={{ backgroundColor: 'white' }}>

                        <FileUploadForm
                            id="outlined-multiline-static"
                            label="uploads"
                            name="uploads"
                            onFilesSelected={handleFilesSelected}
                            resetFile={resetFile}
                        />

                    </div>

                </Stack>
                <br />
                <Button type='submit' name="submit" variant="outlined" style={{ margin: '10px', color: 'white', backgroundColor: '#0275d8' }} > Submit</Button>
                <Button type='submit' name="comment" variant="outlined" style={{ margin: '10px', color: 'white', backgroundColor: 'green' }} > Comment</Button>
                <Button type='submit' name="cancel" variant="outlined" style={{ margin: '10px', color: 'white', backgroundColor: 'red' }} > Cancel</Button>
            </form>

        </div>
    </div>
    )


}