import React, { useRef, useEffect } from "react";

/**
 * Custom hook that alerts if you click outside of the passed ref
 * @param {Object} ref - React ref object that points to the DOM element to monitor
 * @param {Function} onClickOut - Function to call when a click outside is detected
 */
function useOutsideAlerter(ref, onClickOut) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     * @param {MouseEvent} event - The mouse event triggered by the click
     */
    function handleClickOutside(event) {
      // Check if the click is outside the ref's current element
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOut();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOut]);
}

/**
 * Component that triggers an onClickOut function when clicking outside of its children
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child elements of the component
 * @param {Function} props.onClickOut - Function to call when a click outside is detected
 */
export default function ClickOutListener({ children, onClickOut }) {
  // Create a ref to attach to the wrapper div
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOut);

  return <div ref={wrapperRef}>{children}</div>;
}
