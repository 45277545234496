export function getJwtToken() {
    return getCookie("access_token")
}

export function setJwtToken(token) {
    setCookie("access_token", token, { path: '/' }) // Expires in 1 minute
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return getCookie("refresh_token")
}

export function setRefreshToken(token) {
    setCookie("refresh_token", token, { path: '/' }) // Expires in 1 minute
}

function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        // add other defaults here if necessary
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}


// export function fetchWith401Check(url, options) {
//     return fetch(url, options)
//       .then(response => {
//         // Check for 401 Unauthorized response
//         if (response.status === 401) {
//           // Redirect to 401 page
//           window.location.href = '/401.html'; // Change this URL to your 401 page
//         }
//         return response; // Return the original response for successful requests or other errors
//       });
//   }
  
//   // Example usage of the wrapper function
//   fetchWith401Check('https://api.example.com/data', { method: 'GET' })
//     .then(response => {
//       if (!response.ok) {
//         // Handle other errors or non-OK responses
//         console.error('An error occurred:', response.statusText);
//       } else {
//         return response.json(); // Process the successful response
//       }
//     })
//     .then(data => {
//       console.log(data); // Handle the data from the response
//     })
//     .catch(error => {
//       console.error('Fetch error:', error); // Handle network errors
//     });