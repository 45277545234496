import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { initializePart } from "../../store/combobox/partSlice";
import { useSelector, useDispatch } from 'react-redux';
import { setState } from '../../store/loadstate/loadStateSlice';

export default function DateForm({ updateQuerySettings }) {
  const dispatch = useDispatch();
  const isPopstate = useSelector(state => state.loadstate.value);
  const [error, setError] = React.useState('');

  const now = dayjs(); // Get current date and time
  const oneYearAgo = now.subtract(1, 'year'); // Calculate 1 year ago

  const [valueDateStart, setValueStart] = React.useState(
    oneYearAgo.valueOf(), // Start date 1 year ago
  );
  const [valueDateEnd, setValueEnd] = React.useState(
    now.valueOf(), // Current date and time
  );

  React.useEffect(() => {
    updateQuerySettings({
      start_time: oneYearAgo.valueOf(),
      end_time: now.valueOf(),
    })
  }, [])


  const handleChangeStart = (newValue) => {
    if (newValue === null) {
      return;
    }
    setValueStart(newValue.valueOf());
  };

  const handleChangeEnd = (newValue) => {
    if (newValue === null) {
      return;
    }
    setValueEnd(newValue.valueOf());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (valueDateStart > valueDateEnd) {
      setError('Start Date cannot be after End Date');
      return;
    }
    setError('');

    updateQuerySettings({
      start_time: valueDateStart,
      end_time: valueDateEnd,
    });
  };

  useEffect(() => {
    const handlePopstate = () => {
      console.log("handle pop start");
      dispatch(setState(true));
    };

    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("loading");
      if (!isPopstate) {
        dispatch(initializePart());
      }

      dispatch(setState(false));
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [dispatch]);

  return (
    <div>
      {error && <div>{error}</div>}
      <form onSubmit={handleSubmit} sx={{ '& button': { m: 1 } }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3} direction="row">
            <DateTimePicker
              label="Start Date&Time"
              value={valueDateStart}
              onChange={handleChangeStart}
              renderInput={(params) => <TextField {...params} />}
            />
            <DateTimePicker
              label="End Date&Time picker"
              value={valueDateEnd}
              onChange={handleChangeEnd}
              renderInput={(params) => <TextField {...params} />}
            />
            <Button variant="contained" size="small" type='submit' endIcon={<SendIcon />}>
              Submit
            </Button>
          </Stack>
        </LocalizationProvider>
      </form>
    </div>
  );
}
