import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    value : false
}

const loadStateSlice = createSlice({
    name: 'loadstate',
    initialState,
    reducers: {

      setState: (state, action) => {
        console.log("set state");
        state.value = action.payload // Directly modify the existing state
      }
    }
  });
  

export const { setState  } = loadStateSlice.actions;
export default loadStateSlice.reducer;
