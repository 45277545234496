import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Tooltip, Typography, Zoom, styled, tooltipClasses } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import './HoldButton.css';

/**
 * A button that can be held to copy its contents to the clipboard.
 */

const HoldButton = ({ url, params, textAlign, color, children, onSuccess, fontSize }) => {
	const [isLongPress, setIsLongPress] = useState(false);
	const [timer, setTimer] = useState(null);
	const [updateLink, setUpdateLink] = useState(0);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
	const [tooltipTimeoutRef, setToolTipTimeoutRef] = React.useState(null);
	const [showTooltip, setShowTooltip] = React.useState(false);

	/**
	 * Handles the mouse down event and sets a timer for long press detection.
	 */
	const handleMouseDown = (e) => {
		const newTimer = setTimeout(() => {
			setIsLongPress(true);
			console.log("Copying");
			handleCellClick(e);
			setUpdateLink(updateLink + 1);
		}, 550);
		setTimer(newTimer);
	};

	/**
	 * Handles the mouse up event and clears the timer.
	 */
	const handleMouseUp = (e) => {
		clearTimeout(timer);
		handleClick(e);
	};

	/**
	 * Handles the click event and prevents default action if it was a long press.
	 */
	const handleClick = (e) => {
		if (isLongPress) {
			e.preventDefault();
			setIsLongPress(false);
		} else {
			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess()
				window.location = url
			}
		}
	};

	/**
	 * Handles the cell click event, copies the cell content to clipboard, and shows a tooltip.
	 */
	const handleCellClick = (event) => {
		navigator.clipboard.writeText(params.value);

		try {
			// Sanitize params.id to ensure it's a valid CSS class name
			const sanitizedId = params.id.replace(/[^\w-]/g, ''); // Replace any non-alphanumeric, non-hyphen characters

			const cellElement = document.querySelector(`.cell-${sanitizedId}`);
			if (cellElement) {
				const cellRect = cellElement.getBoundingClientRect();
				const x = cellRect.x;
				const y = 24;

				setTooltipPosition({ x, y });

				if (tooltipTimeoutRef == null) {
					clearTimeout(tooltipTimeoutRef);
				}
				setShowTooltip(true);
				setToolTipTimeoutRef(setTimeout(() => {
					setShowTooltip(false);
				}, 1500));
			}
		} catch {
			// Handle error
		}
	};

	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	}, [timer]);

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#0a9dd9',
			color: '#fff',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #FFF',
		},
	}));

	return (
		<div className="hold-button-container">
			<HtmlTooltip
				TransitionComponent={Zoom}
				TransitionProps={{ timeout: 200 }}
				open={showTooltip}
				onClose={() => setShowTooltip(false)}
				title={
					<React.Fragment>
						<Typography color={color || "inherit"}>Copied to Clipboard</Typography>
					</React.Fragment>
				}
				placement="top"
				style={{
					position: 'absolute',
					left: tooltipPosition.x,
					top: tooltipPosition.y,
				}}
			/>
			<Link
				key={updateLink}
				to={url}
				className="hold-button-link"
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
			>
				<Button
					className="hold-button"
					style={{ color: color || 'inherit' }}
					endIcon={<ArrowOutwardIcon style={{ fontSize: '1em' }} />}
				>
					{children ? (
						children
					) : (
						<p className="hold-button-text" style={{ textAlign: textAlign || 'left', fontSize: fontSize || '14px' }}>
							{params.value}
						</p>
					)}
				</Button>
			</Link>
		</div>
	);
};

export default HoldButton;
