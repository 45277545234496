import React from 'react'
import { TestList, StatusBoard, StationBoard, QueueBoard, PartChart, YieldBoard, Log, BoxPlotBoard, Home, Intake, Stations, Equipment, EcoBoard, ManageSerialRevs, Error, Configurations, StatusChangeBoard } from '../.'
import Landing from '../landing/Landing'
import MacAddresses from '../macaddresses/MacAddresses'

export default function MainBoard({ param, setBoard }) {

  return (
    <div className="dashboard-main">

      {
        {
          'landing': <Landing />,
          'partsBoard': <TestList handleBoard={setBoard} />,
          'partChart': <PartChart />,
          'home': <PartChart />,
          'equipment': <Equipment />,
          'status': <StatusBoard />,
          'status/manage': <StatusChangeBoard />,
          'intake': <Intake />,
          //   'stations' : <Log/>,
          'logBoard': <Log />,
          'logTest': <logTest />,
          'boxplotBoard': <BoxPlotBoard />,
          'configurations': <Configurations />,
          'ecoboard': <EcoBoard />,
          'stations': <Stations />,
          'stationBoard': <StationBoard />,
          'queueBoard': <QueueBoard />,
          'yieldBoard': <YieldBoard />,
          'mac-addresses': <MacAddresses />,
          'manage-serial-revs': <ManageSerialRevs />,
          'error': <Error />
        }[param]
      }
    </div>
  )
}