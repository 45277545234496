const ENDPOINTS = {
	AUTH_LOGIN: () => `/auth/google`,
	AUTH_LOGOUT: () => `/auth/logout`,

	BUILDS: () => `/build-parts`,

	QUEUE_GETLIST: () => `/getrequests`,
	QUEUE_GETREQUESTFILES: () => `/getrequestfiles`,
	QUEUE_GETREQUESTCOMMENT: () => `/getcomments`,
	QUEUE_ADDCOMMENT: () => `/addcomment`,
	QUEUE_ADDFIXER: () => `/addfix`,

	PARTS: () => `/parts`,
	PART_GETPARTLIST: () => `/partslist`,
	PART_HISTORY: (part_number, days) => `/parts/${part_number}/history/${days}`,
	PARTS_CONTRACT_MANUFACTURERS:() => `/contract_manufacturers/`,

	SERIALS_CONFIGS: () => `/serials/config`,
	SERIALS_CONFIGS_LOGS: (sid) => `/serials/${sid}/config/logs`,
	SERIALS_STATUS_PRODUCTION: (pid) => `/parts/${pid}/serials/production/`,
	SERIALS_STATUS_PRODUCTION_CHANGE: (sid) =>
		`/serials/${sid}/production/change-status/`,
	SERIALS_STATUS_PRODUCTION_LOGS: (sid) => `/serials/${sid}/production/logs/`,

	STATION_GETLIST: () => `/getstationtest`,
	STATION_GETSTATIONTESTSDETAILS: () => `/stations/stationtest/gettests`,
	STATION_GETSTATIONTESTS: () => `/stations/gettests`,
	STATION_GETSTATIONLIST: () => `/stations/stationslist`,

	YIELD_GETPIECHARTDETAIL: () => `/getpiechart`,
	YIELD_GETPIECHART: (partNumber) => `/part/${partNumber}/pie`,
	YIELD_GETTESTDETAIL: () => `/gettestinfo`,
	YIELD_GETTESTDETAILS: (partNumber, station) => `/parts/${partNumber}/station/${station}/yield/`,
    YIELD_POSTSUMMARYLIST: (partNumber) => `/parts/${partNumber}/yield`,
    YIELD_POSTSERIALS: (partNumber) => `/parts/${partNumber}/serials`,

	LOG_GETLOGACTIVITY: () => `/getactivity`,
	LOG_GETLOGREV: () => `/revs`,
	LOG_GETSERIALSUMMARY: () => `/getserialsummary`,
	LOGTEMPLATE_GETTESTRESULT: () => `/gettestresult`,

	STATUS_GETDETAILS: () => `/status`,
	STATUS_GETSYNTHESIZEDSTATUS: () => `/status/synthesized`,
	EQUIPMENT_GETTYPELIST: () => `/equipment/typeslist`,
	EQUIPMENT_PUTFORMVALUE: () => `/equipment`,
	EQUIPMENT_GETEQUIPMENTDETAIL: () => `/equipment`,
	EQUIPMENT_GETEQUIPMENTLOGDETAIL: () => `/equipment/log`,

	DEVIATION_ADDDEVITION: () => `/deviation/add`,
	DEVIATION_BASE: () => `/deviation`,

	BOXPLOT_GETBOXPLOTDETAIL: () => `/boxplot`,
	BOXPLOT_DATASTUDIO: () => `/getbpdatastudio`,
	BOXPLOT_GETTEST: () => `/gettestbytestname`,

	INTAKE_ADDPARTNUMBER: () => `/addpartnumber`,
	INTAKE_PARTSEARCHLIST: () => `/partsearchlist`,
	INTAKE_ADDSERIALNUMBER: () => `/addserialnumber`,
	INTAKE_ADDCOMPONENTS: () => `/addcomponents`,
	INTAKE_GETCOMPONENTS: () => `/getComponents`,
	INTAKE_SERIALSEARCHLIST: () => `/serialsearchlist`,
	INTAKE_COMPONENTSEARCHLIST: () => `/componentsearchlist`,
	INTAKE_ADDSERIALNUMBERGENERATE : () => `/serials/generate`,

	ECO_GETDETAIL: () => `/eco`,
	ECO_GETECOBYPARTNUM: () => `/eco/part_number`,

	ADDNOTE: () => `/addnote`,
	ADDREQUEST: () => `/addrequest`,

	GETNOTE: () => `/getnote`,
	GETREQUEST: () => `/getrequest`,

	ADDFIX: () => `/addfix`,
	ADDCOMMENT: () => `/addcomment`,

	MACADDRESS_ASSIGNED: () => `/mac/assigned`,
	MACADDRESS_UTILIZATION: () => `/mac/utilization`,
	MACADDRESS_FROM_SERIAL: (part_number, serial_number) => `/parts/${part_number}/serials/${serial_number}/mac`


};

export default ENDPOINTS;
